import { ELECTRON_MODE } from "@constants";
// eslint-disable-next-line
import log from "electron-log";

import React from "react";
import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { App } from "./App";
import "./i18n";

if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
    console.log = __electronLog.log;
}

render(
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
        <ToastContainer />
    </React.StrictMode>,
    document.getElementById("root")
);

import { CATALOGUE_MODE } from "@constants";
import { Alert } from "@material-ui/lab";
import { Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import { t } from "i18next";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import BasketActionsButtons from "@components/OrderTaking/common/BasketActionButtons";
import { CommentKeyboard } from "@components/OrderTaking/common/CommentKeyboard";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";

import "./index.css";

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={700} ref={ref} {...props} />;
});

export default function PrmDialogCart({
    openDialogCart,
    setOpenDialogCart,
    isSummaryOrderOpened,
    setIsSummaryOrderOpened,
    setIsLocalKeyBoardOpened,
}) {
    const { orderItems, itemsCount } = useSelector(
        (state) => state.orderSlice.order
    );

    const { isCommentKeyboardActive } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    if (openDialogCart && orderItems.length === 0) {
        setOpenDialogCart(false);
    }
    return (
        <Dialog
            TransitionComponent={TransitionSlide}
            open={openDialogCart}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "1080px",
                    height: "540px",
                    maxHeight: "unset!important",
                    maxWidth: "unset!important",
                    position: "fixed",
                    bottom: -32,
                    overflowY: "visible",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="prm-cart-close-button"
                onClick={() => setOpenDialogCart(false)}
            >
                <span className="label-close-cart">{t("Reduce")}</span>
            </div>
            <div className="prm-header-popup">
                <div className="prm-title-popup-cart">{t("Your order")}</div>
                <div className="prm-number-product-in-cart">
                    {itemsCount} {t("Articles in your cart")}
                </div>
                <hr className="separation-line" />
            </div>
            <section className="prm-cart-products-list">
                {!orderItems.length ? (
                    <Alert severity="error">
                        {t("No product available in cart")}
                    </Alert>
                ) : (
                    orderItems.map((product) => (
                        <ComposedProductCartPopup
                            key={product.iuudOrder}
                            product={product}
                            isPrmDialogCart={true}
                        />
                    ))
                )}
            </section>
            {isCommentKeyboardActive && !isSummaryOrderOpened ? (
                <CommentKeyboard
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            ) : (
                <BasketActionsButtons
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                    isSummaryOrderOpened={isSummaryOrderOpened}
                    setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
        </Dialog>
    );
}

PrmDialogCart.propTypes = {
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
    ProductInfoFromState: PropTypes.object,
    settings: PropTypes.object,
    isSummaryOrderOpened: PropTypes.bool,
    setIsSummaryOrderOpened: PropTypes.func,
    setIsLocalKeyBoardOpened: PropTypes.func,
};

import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";

import React from "react";

import {
    setFranchiseSchema,
    setSelectedEntityChild,
    setSelectedEntityChildren,
} from "@components/Synchronization/store";

import { setKeyboardInputsValues } from "@pages/Kiosk/store";

import EntitySelectionView from "./EntitySelectionView";
import "./index.css";

type entitySelectionType = {
    setData: React.Dispatch<React.SetStateAction<EntityType[]>>;
    setIsEntityChildrenSelectionModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    data: EntityType[];
};

export function EntitySelection({
    setData,
    data,
    setIsEntityChildrenSelectionModalOpened,
}: entitySelectionType): JSX.Element {
    function handleActionButtonOnClick(selectedRow: EntityType) {
        if (selectedRow.children?.length === 0) {
            setSelectedEntityChild(selectedRow);
            setIsEntityChildrenSelectionModalOpened(true);
        } else {
            setData(selectedRow?.children as EntityType[]);
        }

        setKeyboardInputsValues({});
    }

    const columns: TableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("CRM ID"),
                accessor: ({ crm_id }: { crm_id: string }) =>
                    crm_id !== "" ? crm_id : "-",
            },
            {
                Header: t("Name"),
                accessor: ({ name }: { name: string }) =>
                    name !== "" ? name : "-",
            },
            {
                Header: t("Nature"),
                accessor: ({ nature }: { nature: string }) =>
                    nature !== "" ? nature : "-",
            },
            {
                Header: t("Address"),
                accessor: ({ address }: { address: string }) =>
                    address !== "" ? address : "-",
            },
            {
                Header: t("Action"),
                disableGlobalFilter: true,
                accessor: (row: EntityType) => (
                    <StyledButton
                        rounded={true}
                        variant="primary"
                        style={{
                            boxShadow: "0px 5px 10px #00000042",
                        }}
                        onClick={() => handleActionButtonOnClick(row)}
                    >
                        {t("Select")}
                    </StyledButton>
                ),
            },
        ],
        [t]
    );

    function selectEntity(selectedEntity: EntityType): void {
        setData(selectedEntity.children as EntityType[]);
        setFranchiseSchema(selectedEntity.franchise);
        setSelectedEntityChildren(selectedEntity.children as EntityType[]);
    }

    return (
        <EntitySelectionView
            selectEntity={selectEntity}
            columns={columns}
            data={data}
        />
    );
}

import { BASIC_COMPOSITION_TYPE } from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import MessageIcon from "@components/OrderTaking/Components/Icons/MessageIcon";
import { ModifyIcon } from "@components/OrderTaking/Components/Icons/ModifyIcon";

import { kioskStore } from "@pages/Kiosk/store";

import { CommentOrderSummury } from "./CommentOrderSummury";

export default function ProductCardPopup({
    Item,
    Editproduct,
    isSalesModeChange,
    isCommentProductActive,
}) {
    const { isClickAndCollect } = useSnapshot(kioskStore);

    let productComposition = [];
    let productBasicComposition = [];

    if (Item?.compositions?.[BASIC_COMPOSITION_TYPE]) {
        Object.keys(
            Item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions
        ).forEach((key) => {
            const itemBasicComposition = `${Item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions[key].displayTitle} `;

            productBasicComposition.push(itemBasicComposition);
        });
    }

    if (Item?.compositions) {
        Object.keys(Item?.compositions).forEach((key) => {
            if (key !== BASIC_COMPOSITION_TYPE) {
                let quantity = Item?.compositions[key].quantity;
                const itemComposition = `${quantity}x ${Item?.compositions[key].displayTitle} `;

                productComposition.push(itemComposition);
            }
        });
    }

    return (
        <React.Fragment>
            <div className="composed-product-element my-2 px-3">
                <div className="composed-product-info h-100">
                    <div className="product-info__clz">
                        <div
                            className="d-flex justify-content-center align-items-center h-100"
                            style={{
                                gap: "20px",
                            }}
                        >
                            {Item.quantity > 1 ? (
                                <span
                                    className="number-composed-product"
                                    style={{
                                        color: "var(--global-color)",
                                    }}
                                >
                                    {Item.quantity + "X"}
                                </span>
                            ) : null}

                            <img
                                className="image-composed-product"
                                src={Item.urlImage}
                                alt={Item.urlImage}
                            />
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                gap: "10px",
                                height: "100%",
                            }}
                        >
                            <div className="d-flex flex-column ">
                                <span className="product-title two-lines-preview-text">{`${Item.displayTitle}`}</span>
                                <span className="summary-options-product">
                                    {` ${
                                        Item.selectedOptions
                                            ? Item.selectedOptions?.join(",")
                                            : ""
                                    }`}
                                </span>

                                <div className="d-flex flex-column">
                                    {productBasicComposition.length > 0 && (
                                        <span className="basic-composition my-2">
                                            {productBasicComposition.join(" ,")}
                                        </span>
                                    )}
                                    {productComposition.length > 0 && (
                                        <span className="composed-product-characteristic">
                                            {productComposition.join(" ,")}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isSalesModeChange ? (
                    <div
                        className="d-flex justify-content-start align-items-start"
                        style={{ gap: "10px" }}
                    >
                        {isCommentProductActive === true &&
                        Item.haveComment === true &&
                        !isSalesModeChange ? (
                            <div
                                className="d-flex justify-content-center align-content-center mt-2 p-1"
                                style={{
                                    width: "55px",
                                    height: "55px",

                                    borderRadius: " 10px",
                                }}
                            >
                                <MessageIcon
                                    width="45"
                                    height="45"
                                    color={
                                        Item.comment !== ""
                                            ? "var(--global-color)"
                                            : "gray"
                                    }
                                />
                            </div>
                        ) : null}
                        {!isClickAndCollect ? (
                            <div
                                className="d-flex flex-column"
                                onClick={() => {
                                    Editproduct(Item);
                                }}
                            >
                                <ModifyIcon></ModifyIcon>
                                <span className="pl-2">{t("Modify")}</span>
                            </div>
                        ) : null}
                    </div>
                ) : isSalesModeChange && Item.isDeleted === true ? (
                    <img
                        style={{ width: "50px", height: "50px" }}
                        src="./images/crossIcon.png"
                    />
                ) : null}
            </div>
            {Item.haveComment === true &&
                Item.comment !== "" &&
                !isSalesModeChange && (
                    <CommentOrderSummury comment={Item.comment} />
                )}
        </React.Fragment>
    );
}

ProductCardPopup.propTypes = {
    Item: PropTypes.object,
    Editproduct: PropTypes.func,
    isSalesModeChange: PropTypes.bool,
    isPrmDialogCart: PropTypes.bool,
    isCommentProductActive: PropTypes.bool,
};

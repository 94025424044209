import { TCPOS } from "@constants";
import { snapshot, useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { store } from "@store";

import { configCashMachine } from "@helpers/general";

import { useLocalWays } from "@hooks/useLocalWays";
import { useProject } from "@hooks/useProject";

import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { setSetting } from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    setActiveConfigOptions,
    setActiveLanguages,
    setCustomerLanguage,
    setInitialStepTimeout,
    setIsCashMachineConfigured,
    setIsConfigOptionsActive,
    setIsLanguagesActive,
    setIsPromoCodeActive,
    setIsTicketNumberActive,
    setKioskWays,
    setLogo,
    setProject,
    setProjectTimeout,
    setSelectedSaleModeOrderTaking,
} from "@pages/Kiosk/store";

export function useSteps(): void {
    const dispatch = useDispatch();
    const { posEditor } = snapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);
    const { project } = useProject();
    const localKioskWays = useLocalWays(project);

    setKioskWays(localKioskWays);

    React.useEffect(() => {
        const changeMachinePaymentMode = (
            project.template.content.meansOfPayment as ProjectContentItemType
        ).items.filter((item) => item.name === "Change machine");
        if (
            selectedShopApp.cash_managements !== undefined &&
            Object.values(selectedShopApp.cash_managements as ObjectType)
                .length > 0 &&
            changeMachinePaymentMode.length > 0 &&
            changeMachinePaymentMode[0].active === true &&
            !(TCPOS === posEditor)
        ) {
            const ipCashMachine = (
                Object.values(
                    selectedShopApp.cash_managements as ObjectType
                )[0] as ObjectType
            ).ip as string;

            configCashMachine(ipCashMachine);
            setIsCashMachineConfigured(true);
        }
    }, []);

    if (
        (project.template.pages.ways["orderTaking"] as OrderTakingPageType)
            .summary.promoCodeActive === undefined
    ) {
        setIsPromoCodeActive(false);
    } else {
        setIsPromoCodeActive(
            (project.template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary.promoCodeActive
        );
    }
    setProjectTimeout(project.template.timeout);

    setIsConfigOptionsActive(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).active
    );

    setActiveConfigOptions(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).items.filter((option) => option.active === true)
    );

    setIsLanguagesActive(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).active &&
            (
                (project.template.content as ProjectContentType)
                    .languages as LanguagesType
            )?.items.filter((option) => option.active === true).length > 1
    );

    setActiveLanguages(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).items.filter((language) => language.active === true)
    );

    setCustomerLanguage(
        (project.template.content.languages as LanguagesType).items.filter(
            (language) => language.isDefault === true
        )[0]
    );

    setInitialStepTimeout(project.template.initialStepTimeout as number);

    setLogo((project.template.content.generalDesign as GeneralDesignType).logo);
    setIsTicketNumberActive(
        (
            (project.template.pages.ways.finalMessage as PageType)
                .footer as FooterType
        ).isTicketNumberActive
    );

    dispatch(
        setSetting(
            setOrderTakingSetting(
                project.template.pages.ways.orderTaking as PageType,
                (project.template.content.generalDesign as GeneralDesignType)
                    .colors[0].content,
                (project.template.content.generalDesign as GeneralDesignType)
                    .colors[1].content
            )
        )
    );
    if (
        ((project.template.pages.ways["salesMethods"] as PageType)
            .skipped as boolean) === true
    ) {
        const activeSalesMode = (
            project.template.content.salesMethods as ProjectContentItemType
        ).items.filter((item) => item.active === true);
        if (activeSalesMode.length === 1) {
            setSelectedSaleModeOrderTaking(
                activeSalesMode[0].settingKey as string
            );
        }
    }
    setProject(project);
}

import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";

import { kioskStore } from "@pages/Kiosk/store";

type prmModeType = {
    setIsQrCodeShown: React.Dispatch<React.SetStateAction<boolean>>;
    isQrCodeShown: boolean;
    handleOnChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    isScanningQrCode: boolean;
};

export function PrmMode({
    isQrCodeShown,
    setIsQrCodeShown,
    handleOnChangeEvent,
    inputRef,
    isScanningQrCode,
}: prmModeType): JSX.Element {
    const {
        isConfigOptionsActive,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        isSubStepInformationMessageActive,
    } = useSnapshot(kioskStore);
    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );
    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper selectedMode={"clickAndCollect"}>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateRows: "15fr 20fr 7fr 5fr",
                            height: "100%",
                        }}
                    >
                        {isSubStepInformationMessageActive ? (
                            <SubStepsInformationMessage />
                        ) : null}

                        <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{ gap: "50px" }}
                        >
                            <img
                                src="./images/qr-code.png"
                                alt="qrCode"
                                style={{ height: "200px", width: "200px" }}
                            />
                            <div
                                style={{
                                    borderBottom: "1px solid #00000029",
                                }}
                            >
                                <input
                                    className="form-input text-center qr-code__clz"
                                    type={isQrCodeShown ? "text" : "password"}
                                    autoFocus
                                    ref={inputRef}
                                    placeholder={t("QR CODE")}
                                    onChange={handleOnChangeEvent}
                                />
                                {isQrCodeShown === false ? (
                                    <EyeIcon
                                        className="mb-3 d-none"
                                        height={30}
                                        width={30}
                                        onClick={() => {
                                            setIsQrCodeShown(!isQrCodeShown);
                                        }}
                                    />
                                ) : (
                                    <EyeCrossedIcon
                                        className="mb-3 d-none"
                                        height={30}
                                        width={30}
                                        onClick={() => {
                                            setIsQrCodeShown(!isQrCodeShown);
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        {isSubStepActionButtonsActive ? (
                            <PrmActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        ) : null}
                        <div className="d-flex align-items-center justify-content-end">
                            {!isScanningQrCode ? <PrmLanguagesWrapper /> : null}

                            {isConfigOptionsActive && !isScanningQrCode ? (
                                <ConfigOptionsWrapper />
                            ) : null}
                        </div>
                    </div>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}

import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Button from "react-bootstrap-button-loader";
import Modal from "react-modal";

import { store } from "@store";

import {
    handleLedControl,
    handleLoginLocalSettingsButtonOnClickEvent,
    handleLoginSynchronizationButtonOnClickEvent,
} from "@helpers/general";

import {
    setIsSyncLocalModalOpened,
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

import { kioskStore, setIsAuthenticationModalOpened } from "@pages/Kiosk/store";

import { version } from "../../../../package.json";
import { ErrorLogInModalWrapper } from "../ErrorLogInModalWrapper";
import { LocalSettingsHeader } from "../LocalSettingsHeader";
import { UsualKeyboardWrapper } from "../UsualKeyboardWrapper";

export function AuthenticationModalWrapper(): JSX.Element {
    const { isAuthenticationModalOpened, connectedLedPort, isLedActive } =
        useSnapshot(kioskStore);
    const { loginKeyboardInputsValues } = useSnapshot(syncStore);
    const { isAppSynchronized } = useSnapshot(store);
    const [isErrorLogInModalWrapperOpened, setIsErrorLogInModalWrapperOpened] =
        React.useState<boolean>(false);
    const [loginButton, setLoginButton] = React.useState<string>("Login");

    const encodedEmail = encodeURIComponent(loginKeyboardInputsValues["email"]);
    const encodedPassword = encodeURIComponent(
        loginKeyboardInputsValues["password"]
    );
    const disableConnectButton =
        Object.values(loginKeyboardInputsValues).filter((x: string) => x !== "")
            .length === 0;
    function handleOnClickEvent() {
        if (loginButton === "Login") {
            if (isAppSynchronized) {
                handleLoginLocalSettingsButtonOnClickEvent(
                    encodedEmail,
                    encodedPassword,
                    setIsErrorLogInModalWrapperOpened,
                    setLoginButton
                );
            } else {
                handleLoginSynchronizationButtonOnClickEvent(
                    encodedEmail,
                    encodedPassword,
                    setIsErrorLogInModalWrapperOpened,
                    setLoginButton
                );
            }

            setLoginButton("Connecting");
        } else {
            return;
        }
    }
    React.useEffect(() => {
        if (isLedActive && isAuthenticationModalOpened) {
            handleLedControl(connectedLedPort, "Orange");
        }
    }, [isAuthenticationModalOpened]);

    return (
        <>
            <ErrorLogInModalWrapper
                setIsErrorLogInModalWrapperOpened={
                    setIsErrorLogInModalWrapperOpened
                }
                isErrorLogInModalWrapperOpened={isErrorLogInModalWrapperOpened}
            />
            <Modal
                isOpen={isAuthenticationModalOpened}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        zIndex: 2,
                    },
                    content: {
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px",
                        color: "black",
                        height: "1366px",
                        width: "914px",
                        zIndex: 2,
                        backgroundColor: "#FFFFFF",
                        filter: isErrorLogInModalWrapperOpened
                            ? "blur(5px)"
                            : "none",
                        borderLeft: "1px solid #707070",
                        borderRight: "1px solid #707070",
                        borderBottom: "1px solid #707070",
                        borderTop: "15px",
                        bottom: "unset",
                        top: "unset",
                        right: "unset",
                        left: "unset",
                        zoom: `${
                            process.env.REACT_APP_MODE === CATALOGUE_MODE
                                ? "50%"
                                : "100%"
                        }`,
                        overflowX: "hidden",
                    },
                }}
            >
                <LocalSettingsHeader />
                <div
                    style={{
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "2fr 1fr 5fr 2fr",
                        height: "1188px",
                        gap: "40px",
                        display: "grid",
                    }}
                >
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            height: 206,
                            position: "relative",
                        }}
                    >
                        <video
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            style={{
                                position: "fixed",
                                height: "206px",
                            }}
                        >
                            <source
                                src="./images/profile.webm"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <span
                            style={{
                                font: "normal normal bold 35px / 47px Segoe UI",
                            }}
                        >
                            {t("Connection")}
                        </span>
                    </div>
                    <UsualKeyboardWrapper
                        setIsErrorLogInModalWrapperOpened={
                            setIsErrorLogInModalWrapperOpened
                        }
                        setLoginButton={setLoginButton}
                    />
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            backgroundColor: "#E9E9E9",
                            height: "144px",
                            borderTop: "1px solid #9d9a9a",
                            marginTop: "17px",
                        }}
                    >
                        <StyledButton
                            rounded={true}
                            className="m-2 text-uppercase"
                            style={{
                                width: "255px",
                                height: "61px",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "black",
                                letterSpacing: "0px",
                                font: "normal normal 600 18px/22px Segoe UI",
                            }}
                            onClick={() => {
                                if (!isAppSynchronized) {
                                    setIsSyncLocalModalOpened(true);
                                }
                                setIsAuthenticationModalOpened(false);
                                setLoginKeyboardInputsValues({
                                    email: "",
                                    password: "",
                                });
                            }}
                        >
                            {t("Back")}
                        </StyledButton>
                        <Button
                            rounded={true}
                            className="m-2 text-uppercase"
                            disabled={disableConnectButton}
                            loading={loginButton === "Connecting"}
                            style={{
                                width: "386px",
                                height: "61px",
                                background: "#545454",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #FFFFFF",
                                borderRadius: "12px",
                                letterSpacing: "0px",
                                font: "normal normal 600 18px/22px Segoe UI",
                            }}
                            onClick={handleOnClickEvent}
                        >
                            {t(loginButton)}
                        </Button>
                    </div>
                    <p
                        style={{
                            color: "rgba(0,0,0,.7)",
                            textShadow: " 0 1px rgba(255, 255, 255, 0.1)",
                            textAlign: "center",
                        }}
                    >{`Version ${version}`}</p>
                </div>
            </Modal>
        </>
    );
}

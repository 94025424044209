import { useDispatch } from "react-redux";

import { addWorkflowStepChoice } from "@components/OrderTaking/Store/feature";

function useAddNbrOfChoiceStep(
    listworkflow,
    product,
    AddWorkFlowItemShopCartData,
    workflowData
) {
    const dispatch = useDispatch();

    return () => {
        if (listworkflow.length === 0) return null;
        const workflowStep = listworkflow.workflow[listworkflow.index];
        let NbrOfChoices = workflowStep.NbrOfChoices + 1;
        let withSpecialPrice = false;
        let withStepPrice = false;
        let {
            nbrOfChoicesWithspecialPrice,
            selectedChoicesWithspecialPrice,
            nbrOfChoicesWithStepPrice,
            selectedChoicesWithStepPrice,
        } = workflowStep;

        // ! in progress
        if (nbrOfChoicesWithspecialPrice > 0) {
            if (
                selectedChoicesWithspecialPrice < nbrOfChoicesWithspecialPrice
            ) {
                selectedChoicesWithspecialPrice += 1;
                withSpecialPrice = true;
            } else if (nbrOfChoicesWithStepPrice > 0) {
                if (selectedChoicesWithStepPrice < nbrOfChoicesWithStepPrice) {
                    selectedChoicesWithStepPrice += 1;
                    withStepPrice = true;
                }
            }
        } else if (nbrOfChoicesWithStepPrice > 0) {
            if (selectedChoicesWithStepPrice < nbrOfChoicesWithStepPrice) {
                selectedChoicesWithStepPrice += 1;
                withStepPrice = true;
            }
        }

        const haveWorkflow =
            product.haveWorkflow ||
            product.haveBasicComposition ||
            product.haveAdditionalSale;

        //!  ici on modifie nombre de choix avec test si on passe a l'etape suivante si produit n'a pas workflow
        dispatch(
            addWorkflowStepChoice({
                NbrOfChoices,
                selectedChoicesWithStepPrice,
                selectedChoicesWithspecialPrice,
                haveWorkflow,
            })
        );
        // dispatch(setIsInternalModification(false));
        // ! ajouter produit au mini panier de menu

        AddWorkFlowItemShopCartData(
            workflowData,
            withSpecialPrice,
            withStepPrice
        );
    };
}

export default useAddNbrOfChoiceStep;

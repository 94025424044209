import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    ISVALINA_WITH_MULTIPLE_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import {
    getGiveUpActionButtonImageSrc,
    getImageContentById,
    getPreviousSkippedStepNumber,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import {
    kioskStore,
    setAmountDeposited,
    setCreditCardPaymentChoice,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsCashTransactionValidated,
    setIsChangeMachineRenderModalOpened,
    setIsPaymentRefused,
    setIsPaymentTechnicalProblemDetected,
    setIsRegulationModeModalGiveUpButtonClicked,
    setIsRegulationModesModalButtonClicked,
    setIsTransactionCanceled,
    setIsWithdrawalProblemModalOpened,
    setLeftToPay,
    setLeftToPayAfterCashMachineValidation,
    setNavigationIndex,
    setPaymentModes,
    setRenderAmount,
    setTotalAmountDeposited,
    setVoucherAmount,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";
import { PrmMainContentWrapper } from "./PrmMainContentWrapper";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function RegulationModesModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        isRegulationModesModalOpened,
        creditCardPaymentChoice,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isPaymentRefused,
        selectedRegulationModeValue,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
        isRegulationModeModalButtonClicked,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        orderTotalPrice,
        isRegulationModeModalGiveUpButtonClicked,
        moneticConfig,
    } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);

    const logoSrc = getImageContentById(
        (generalDesign as GeneralDesignType).logo.id as string
    );

    const backButton = (
        actionsButtons as ActionsButtonsDesignType
    ).items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);

    async function handleRefundTotalDepositedAmount() {
        const timestamp = new Date().getTime();
        await fetch(
            `${middlewareApiUrl}/RetraitMontant?unitaire=${-totalAmountDeposited}?timestamp=${timestamp}`
        )
            .then((response) => response.json())
            .then(async (response) => {
                const localRenderAmount = Number(
                    response.response
                        .split(`:`)[2]
                        .split(`,`)[0]
                        .substr(
                            1,
                            response.response.split(`:`)[2].split(`,`)[0]
                                .length - 2
                        )
                );

                if (localRenderAmount > 0) {
                    setIsChangeMachineRenderModalOpened(false);
                    setTotalAmountDeposited(0);
                    if (isRegulationModeModalGiveUpButtonClicked) {
                        setNavigationIndex(0);
                    } else {
                        setNavigationIndex(
                            navigationIndex -
                                Number(
                                    await getPreviousSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue
                                    )
                                )
                        );
                    }
                } else {
                    setVoucherAmount(totalAmountDeposited);
                    setIsWithdrawalProblemModalOpened(true);
                }
            })
            .catch((error) => {
                console.log(
                    `Error when withdrawing the deposited amount, with the message: ${error.message}`
                );
            });
    }

    async function handleBackButtonOnClickEvent() {
        if (creditCardPaymentChoice === "") {
            setIsBackWithoutCreditCardPaymentChoice(true);
        }
        setIsPaymentRefused(false);
        setIsBackButtonDisabled(false);
        setIsAnotherPaymentModeButtonDisabled(true);
        setIsPaymentTechnicalProblemDetected(false);
        setIsCashTransactionValidated(false);
        setLeftToPayAfterCashMachineValidation(0);
        setAmountDeposited(0);
        setIsPaymentRefused(false);
        setIsTransactionCanceled(false);
        setPaymentModes([]);

        if (totalAmountDeposited === 0) {
            if (isRegulationModeModalGiveUpButtonClicked) {
                setNavigationIndex(0);
            } else {
                if (
                    moneticConfig.type === "Valina" &&
                    moneticConfig.mode === ISVALINA_WITH_MULTIPLE_MODE
                ) {
                    setCreditCardPaymentChoice("");
                    setIsBackWithoutCreditCardPaymentChoice(true);
                }
                setNavigationIndex(
                    navigationIndex -
                        Number(
                            await getPreviousSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue
                            )
                        )
                );
            }
        } else {
            setIsChangeMachineRenderModalOpened(true);
            setRenderAmount(totalAmountDeposited);
            handleRefundTotalDepositedAmount();
        }
        setLeftToPay(
            isOrderPartialPaidWithLoyaltyAccountBalance
                ? totalOrderAfterPaidWithLoyaltyAccountBalance
                : orderTotalPrice
        );
    }

    React.useEffect(() => {
        if (isRegulationModeModalButtonClicked) {
            handleBackButtonOnClickEvent();
        }
    }, [isRegulationModeModalButtonClicked]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isRegulationModesModalOpened}
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: isPrm ? "end" : "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1742px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div className="d-flex justify-content-center align-items-center w-100">
                <img
                    src={logoSrc}
                    alt="logo"
                    style={{ height: "230px", width: "230px" }}
                />
            </div>
            <div
                className="d-flex justify-content-center align-items-center px-5 text-center"
                style={{ height: "370px" }}
            >
                {isPaymentRefused ? (
                    <div>
                        {selectedRegulationModeValue === "changeMachine" ? (
                            <span
                                style={{
                                    font: "normal normal 600 50px/80px Segoe UI",
                                }}
                            >
                                {t(
                                    "The payment with the change machine is refused."
                                )}
                            </span>
                        ) : (
                            <span
                                style={{
                                    font: "normal normal 600 50px/80px Segoe UI",
                                }}
                            >
                                {t(
                                    "The payment with the credit card is refused."
                                )}
                            </span>
                        )}{" "}
                        <span
                            style={{
                                font: "normal normal 600 50px/80px Segoe UI",
                            }}
                        >
                            {t("Please choose another payment method.")}
                        </span>
                    </div>
                ) : (
                    <span
                        style={{ font: "normal normal 600 55px/80px Segoe UI" }}
                    >
                        {t("Choose another payment method.")}
                    </span>
                )}
            </div>

            <div
                className="d-flex justify-content-center w-100"
                style={{
                    height: "900px",
                    alignItems: isPrm ? "end" : "center",
                }}
            >
                {isPrm ? <PrmMainContentWrapper /> : <MainContentWrapper />}
            </div>

            <div
                className="d-flex justify-content-center align-items-end w-100"
                style={{ height: "200px", gap: "10px" }}
            >
                {VALIDATED_EXTENSION_FILE.includes(backImageSrc.substr(-4)) ? (
                    <img
                        src={backImageSrc}
                        alt="backImageSrc"
                        style={{
                            height: "87px",
                            width: "350px",
                            pointerEvents: isRegulationModeModalButtonClicked
                                ? "none"
                                : "auto",
                            opacity: isRegulationModeModalButtonClicked
                                ? "0.5"
                                : 1,
                        }}
                        onClick={() =>
                            setIsRegulationModesModalButtonClicked(true)
                        }
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={backImageSrc}
                        width={"350px"}
                        height={"87px"}
                        pointerEvents={
                            isRegulationModeModalButtonClicked ? "none" : "auto"
                        }
                        opacity={
                            isRegulationModeModalButtonClicked ? "0.5" : "1"
                        }
                        onClick={() =>
                            setIsRegulationModesModalButtonClicked(true)
                        }
                    />
                )}

                {VALIDATED_EXTENSION_FILE.includes(
                    getGiveUpActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType
                    ).substr(-4)
                ) ? (
                    <img
                        src={getGiveUpActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                        style={{
                            height: "87px",
                            width: "350px",
                            pointerEvents: isRegulationModeModalButtonClicked
                                ? "none"
                                : "auto",
                            opacity: isRegulationModeModalButtonClicked
                                ? "0.5"
                                : 1,
                        }}
                        onClick={() => {
                            setIsRegulationModesModalButtonClicked(true);
                            setIsRegulationModeModalGiveUpButtonClicked(true);
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={getGiveUpActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                        width={"350px"}
                        height={"87px"}
                        pointerEvents={
                            isRegulationModeModalButtonClicked ? "none" : "auto"
                        }
                        opacity={
                            isRegulationModeModalButtonClicked ? "0.5" : "1"
                        }
                        onClick={() => {
                            setIsRegulationModesModalButtonClicked(true);
                            setIsRegulationModeModalGiveUpButtonClicked(true);
                        }}
                    />
                )}
            </div>
        </Modal>
    );
}

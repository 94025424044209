import { transitions } from "@constants";
import { proxy, ref, subscribe } from "valtio";

type StoreType = {
    collapsed: boolean;
    kioskImage: ImageType;
    uploadedFiles: UploadedFilesType[];
    transitions: TransitionsType;
    isAppSynchronized: boolean;
    isLicenseProblem: boolean;
    isTechProblemModalOpened: boolean;
    isMiddlewareApiUrlExists: boolean;
    middlewareApiUrl: string;
    isAutoLaunchActivated: boolean;
    posEditor: string;
    isAppPaused: boolean;
};

export const store = proxy<StoreType>({
    collapsed: false,
    kioskImage: {},
    uploadedFiles: [],
    transitions,

    isAppSynchronized: localStorage.getItem("isAppSynchronized")
        ? JSON.parse(localStorage.getItem("isAppSynchronized") as string)
        : false,
    isLicenseProblem: false,
    isTechProblemModalOpened: false,
    isMiddlewareApiUrlExists: localStorage.getItem("isMiddlewareApiUrlExists")
        ? JSON.parse(localStorage.getItem("isMiddlewareApiUrlExists") as string)
        : false,
    middlewareApiUrl: localStorage.getItem("middlewareApiUrl")
        ? JSON.parse(localStorage.getItem("middlewareApiUrl") as string)
        : "http://localhost:5001/api/v1",
    isAutoLaunchActivated: localStorage.getItem("isAutoLaunchActivated")
        ? JSON.parse(localStorage.getItem("isAutoLaunchActivated") as string)
        : false,
    posEditor:
        localStorage.getItem("posEditor") !== "undefined"
            ? JSON.parse(localStorage.getItem("posEditor") as string)
            : "Cesar",
    isAppPaused:
        JSON.parse(localStorage.getItem("isAppPaused") as string) ?? false,
});

subscribe(store, () => {
    localStorage.setItem(
        "isAppSynchronized",
        JSON.stringify(store.isAppSynchronized)
    );
    localStorage.setItem(
        "isMiddlewareApiUrlExists",
        JSON.stringify(store.isMiddlewareApiUrlExists)
    );
    localStorage.setItem(
        "middlewareApiUrl",
        JSON.stringify(store.middlewareApiUrl)
    );
    localStorage.setItem(
        "isAutoLaunchActivated",
        JSON.stringify(store.isAutoLaunchActivated)
    );
    localStorage.setItem("posEditor", JSON.stringify(store.posEditor));
    localStorage.setItem("isAppPaused", JSON.stringify(store.isAppPaused));
});

export function setCollapsed(collapsed: boolean): void {
    store.collapsed = collapsed;
}

export function setUploadedFiles(uploadedFiles: UploadedFilesType[]): void {
    store.uploadedFiles = uploadedFiles.map(ref);
}

export function setIsAppSynchronized(synchronized: boolean): void {
    store.isAppSynchronized = synchronized;
}

export function setIsTechProblemModalOpened(opened: boolean): void {
    store.isTechProblemModalOpened = opened;
}

export function setIsLicenseProblem(isProblem: boolean): void {
    store.isLicenseProblem = isProblem;
}

export function setIsMiddlewareApiUrlExists(exists: boolean): void {
    store.isMiddlewareApiUrlExists = exists;
}

export function setMiddlewareApiUrl(url: string): void {
    store.middlewareApiUrl = `http://${url}:5001/api/v1`;
}

export function setIsAutoLaunchActivated(activated: boolean): void {
    store.isAutoLaunchActivated = activated;
}

export function setPosEditor(posEditor: string): void {
    store.posEditor = posEditor;
}

export function setIsAppPaused(isAppPaused: boolean): void {
    store.isAppPaused = isAppPaused;
}

import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function Validate(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    const { customerLanguage, isPrm, kioskWays, navigationIndex } =
        useSnapshot(kioskStore);
    return isPrm && kioskWays[navigationIndex].name !== "orderTaking" ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 281.609 70"
            {...props}
        >
            <g
                id="Groupe_88"
                data-name="Groupe 88"
                transform="translate(-6346 4106)"
            >
                <rect
                    id="Rectangle_21"
                    data-name="Rectangle 21"
                    width={parseInt(props.width as string) - 2}
                    height={parseInt(props.height as string) - 2}
                    rx="9"
                    transform="translate(6346 -4106)"
                    fill="#141414"
                />
                <text
                    id="Valider"
                    transform="translate(6487 -4058)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Validate", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 87" {...props}>
            <g
                id="Groupe_60"
                data-name="Groupe 60"
                transform="translate(-6346 4106)"
            >
                <rect
                    id="Rectangle_21"
                    data-name="Rectangle 21"
                    width={parseInt(props.width as string) - 2}
                    height={parseInt(props.height as string) - 2}
                    rx="9"
                    transform="translate(6346 -4106)"
                    fill="#141414"
                />
                <text
                    id="Valider"
                    transform="translate(6521 -4048)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Validate", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
}
Validate.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};

import { StyledButton } from "@aureskonnect/react-ui";
import { ELECTRON_MODE, WEBVIEW_MODE } from "@constants";
import { t } from "i18next";

import React from "react";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { setIsAuthenticationModalOpened } from "@pages/Kiosk/store";

type PropsType = {
    toggleSyncModal: () => void;
};

export function AppNotSynchronizedModalWrapper({
    toggleSyncModal,
}: PropsType): JSX.Element {
    function handleCloseApplication() {
        const closeApp = "close_app";
        const url = `http://localhost:6009/api/v1/kiosk?systemAction=${closeApp}`;

        if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
            fetch(url).catch((error) =>
                console.log(
                    `Error while using system action with action: "${closeApp}" and Error: "${error.message}"`
                )
            );
        } else if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
            (window as RendererWindow).electronAPI?.sendQuitEvent();
        }
    }

    return (
        <React.Fragment>
            <FlexboxGrid
                justifyContentCentered={true}
                alignItemsCentered={true}
                gap="30px"
                className="flex-column"
            >
                <video
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    style={{
                        height: "200px",
                        width: "200px",
                    }}
                >
                    <source src="./images/diagram.webm" type="video/mp4" />
                </video>
                <h2
                    className="text-uppercase text-center"
                    style={{
                        font: "normal normal bold 50px/55px Bebas Neue",
                        letterSpacing: "2px",
                        color: "#171717",
                    }}
                >
                    {t("App not synchronized")}
                </h2>
            </FlexboxGrid>
            <div className="d-flex flex-column align-items-center">
                <span>contact@support.com</span>
                <span>01.98.47.35.43</span>
            </div>

            <FlexboxGrid
                alignItemsCentered={true}
                justifyContentCentered={true}
                gap="10px"
            >
                <StyledButton
                    outline={true}
                    rounded={true}
                    variant="light"
                    onClick={handleCloseApplication}
                    className="sync-btn__clz text-uppercase"
                >
                    {t("Leave")}
                </StyledButton>
                <StyledButton
                    variant="primary"
                    rounded={true}
                    className="sync-btn__clz text-uppercase"
                    onClick={() => {
                        toggleSyncModal();

                        setIsAuthenticationModalOpened(true);
                    }}
                >
                    {t("Sync My App")}
                </StyledButton>
            </FlexboxGrid>
        </React.Fragment>
    );
}

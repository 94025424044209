import { StyledButton, StyledModal } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { ModalBody } from "reactstrap";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import {
    setIsSyncConfirmationModalWrapperOpened,
    setIsSyncProgressModalWrapperOpened,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setLocalProject,
    setProject,
    setSelectedSaleModeOrderTaking,
} from "@pages/Kiosk/store";

import "./index.css";

export function SyncConfirmationModalWrapper(): JSX.Element {
    const {
        isSyncConfirmationModalWrapperOpened,
        selectedEntity,
        selectedEntityChild,
        selectedShopApp,
    } = useSnapshot(syncStore);
    const { isLocalConfigModalOpened } = useSnapshot(kioskStore);

    async function handleSyncButtonOnClickEvent() {
        setProject(selectedShopApp.project as ProjectType);
        if (
            ((
                (selectedShopApp.project as ProjectType).template.pages.ways[
                    "salesMethods"
                ] as PageType
            ).skipped as boolean) === true
        ) {
            const activeSalesMode = (
                (selectedShopApp.project as ProjectType).template.content
                    .salesMethods as ProjectContentItemType
            ).items.filter((item) => item.active === true);
            if (activeSalesMode.length === 1) {
                setSelectedSaleModeOrderTaking(
                    activeSalesMode[0].settingKey as string
                );
            }
        }
        if (isLocalConfigModalOpened) {
            setLocalProject(selectedShopApp.project as ProjectType);
        }
        //! please dont active this line without telling me M@HeR

        // (window as RendererWindow).electronAPI?.activateAutoLaunch({
        //     isAutoLaunchActivated: (selectedShopApp.project as ProjectType)
        //         .template.autoLaunch as boolean,
        // });

        const franchiseId =
            selectedEntity?.schema !== "" &&
            selectedEntity?.uid === selectedEntityChild?.franchiseUuid
                ? selectedEntity?.schema
                : selectedEntityChild?.schema;
        const shopId = selectedEntityChild?.id;

        const newShopDetail = {
            schema: franchiseId,
            id: shopId,
            franchise_uid: selectedEntityChild?.franchiseUuid,
            shop_uid: selectedEntityChild?.uid,
            uid_franchise_database_info:
                selectedEntity?.franchiseUuidDatabaseInfo,
        };

        localStorage.setItem("shopDetail", JSON.stringify(newShopDetail));

        setIsSyncConfirmationModalWrapperOpened(false);
        setIsSyncProgressModalWrapperOpened(true);
    }

    function handleChooseAnotherAppButtonClickEvent(): void {
        setIsSyncConfirmationModalWrapperOpened(false);
    }

    return (
        <StyledModal
            backdrop={false}
            centered={true}
            className="sync-confirmation-modal__clz"
            isOpen={isSyncConfirmationModalWrapperOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                <img
                    src="./images/alert.png"
                    style={{
                        height: "124px",
                        width: "144px",
                        marginTop: "30px",
                    }}
                    alt="Alert"
                />
                <div
                    style={{
                        textAlign: "center",
                        letterSpacing: "2px",
                        font: "normal normal bold 50px/55px Bebas Neue",
                    }}
                >
                    {t(
                        "Do you want to synchronize this station With the kiosk application ?",
                        { name: selectedEntityChild?.name }
                    )}
                </div>
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                    gap="10px"
                >
                    <StyledButton
                        rounded={true}
                        className="m-2 text-uppercase"
                        style={{
                            width: "291px",
                            height: "61px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                            font: "normal normal bold 16px/19px Segoe UI",
                        }}
                        onClick={handleChooseAnotherAppButtonClickEvent}
                    >
                        {t("Choose another application")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        className="m-2 text-uppercase"
                        variant="primary"
                        style={{
                            font: "normal normal bold 16px/19px Segoe UI",
                            width: "179px",
                            height: "61px",
                            background: "#242424 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #000000",
                            borderRadius: "12px",
                        }}
                        onClick={handleSyncButtonOnClickEvent}
                    >
                        {t("Synchronize")}
                    </StyledButton>
                </FlexboxGrid>
            </ModalBody>
        </StyledModal>
    );
}

import { CheckIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import React from "react";
import { Card } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import { kioskStore as store } from "@pages/Kiosk/store";
import { setIsSkippedStepInformationModes } from "@pages/Kiosk/store/actions";

type PropsType = {
    item: ProjectMainContentItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ProjectMainContentItemType[]>
    >;
    setConfig: (items: ProjectMainContentItemType[]) => void;
};

export function InformationModesCardWrapper({
    item,
    index,
    setItems,
    setConfig,
}: PropsType): JSX.Element {
    const {
        project,
        selectedSaleModeValue,
        localProject: { template },
    } = useSnapshot(store);
    const imageSrc = getImageContentById(item.id);

    function handleCheckIconOnClickEvent(index: number) {
        setItems((prevState: ProjectMainContentItemType[]) => {
            let newState = [...prevState];
            newState = newState.map(
                (item: ProjectMainContentItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            active: {
                                ...(item.active as ItemContentType),
                                [selectedSaleModeValue]: !(
                                    item.active as ItemContentType
                                )[selectedSaleModeValue],
                            },
                        };
                    }

                    return { ...item };
                }
            );

            setConfig(newState);
            return newState;
        });
        if (
            (
                template.content.informationModes as ProjectContentItemType
            ).items.filter((item) => {
                return (
                    (
                        (item as ProjectMainContentItemType)
                            .active as ItemContentType
                    )[selectedSaleModeValue] !== undefined &&
                    (
                        (item as ProjectMainContentItemType)
                            .active as ItemContentType
                    )[selectedSaleModeValue] === true
                );
            }).length === 1 &&
            (
                (project.template.pages.ways.informationModes as PageType)
                    .skipped as ItemContentType
            )[selectedSaleModeValue] === true
        ) {
            setIsSkippedStepInformationModes(false, selectedSaleModeValue);
        }
    }

    return (
        <div
            style={{
                opacity:
                    (item.active as ItemContentType)[selectedSaleModeValue] ===
                    false
                        ? "40%"
                        : "",
            }}
        >
            <Card
                style={{
                    borderBottom:
                        (item.active as ItemContentType)[
                            selectedSaleModeValue
                        ] === true
                            ? "blue 10px solid"
                            : "#BCBCBC 10px solid",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                }}
                onClick={() => handleCheckIconOnClickEvent(index)}
            >
                <CheckIcon
                    className="mt-1 ml-1"
                    height={45}
                    width={45}
                    style={{
                        fill:
                            (item.active as ItemContentType)[
                                selectedSaleModeValue
                            ] === true
                                ? "blue"
                                : "#BCBCBC",
                        cursor: "pointer",
                    }}
                />
                <div className="d-flex justify-content-center align-items-end">
                    <img
                        key={index}
                        src={imageSrc}
                        style={{
                            width: "110px",
                            height: "110px",
                        }}
                    />
                </div>
            </Card>
        </div>
    );
}

import moment from "moment";

export function getSuspendedItem(suspendSale) {
    let isSuspended = false;
    const currentDateTime = moment().format("HH:mm");

    try {
        if (suspendSale === undefined) {
            return false;
        }

        for (let period = 0; period <= suspendSale.length; period++) {
            const start = suspendSale[period]?.HeureDebut ?? "";
            const end = suspendSale[period]?.HeureFin ?? "";
            if (start <= currentDateTime && currentDateTime <= end) {
                isSuspended = true;
                break;
            }
        }

        return isSuspended;
    } catch (error) {
        console.error(
            "Error while getting suspended products: ",
            error.message
        );
        return isSuspended;
    }
}

import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { useSalesModeModification } from "@hooks/useSalesModeModification";

import { getProductTitle } from "@components/OrderTaking/Helpers";
import OrderTakingScreen from "@components/OrderTaking/Screens/OrderTakingScreen";
import PrmOrderTakingScreen from "@components/OrderTaking/Screens/PrmOrderTakingScreen";

import { kioskStore } from "@pages/Kiosk/store";

const parentVariant = {
    initial: { opacity: 1 },
    animate: { opacity: 1, transition: { staggerChildren: 3 } },
};

export function Screens() {
    const {
        project: { files: images },
        isPrm,
    } = useSnapshot(kioskStore);

    const nestedCategories = useSelector(
        (state) => state.orderSlice.nestedCategories,
        shallowEqual
    );
    useSalesModeModification();
    const [
        isAllergenDialogOpened,
        setIsAllergenDialogOpened = { setIsAllergenDialogOpened },
    ] = React.useState(false);
    const globalcard = useSelector(
        (state) => state.orderSlice.globalcard,
        shallowEqual
    );

    const { isSelectedCategoryActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );
    const backgroundImage = Object.values(images).find((image) => {
        return image.key === "background" && image.name === "orderTaking";
    });

    const imageSrc = getImageContentById(backgroundImage?.id);

    const handleAllergenDialogOnCLickEvent = () => {
        setIsAllergenDialogOpened(!isAllergenDialogOpened);
    };

    let subCategory =
        globalcard.categories[nestedCategories[nestedCategories.length - 1]];
    let category = globalcard.categories[nestedCategories[0]];
    const title =
        nestedCategories.length > 1
            ? subCategory?.isRedirect
                ? getProductTitle(subCategory?.displayTitle)
                : getProductTitle(subCategory?.name)
            : getProductTitle(category.name);

    return isPrm ? (
        <PrmOrderTakingScreen
            isSelectedCategoryActive={isSelectedCategoryActive}
            imageSrc={imageSrc}
            title={title}
            nestedCategories={nestedCategories}
        />
    ) : (
        <OrderTakingScreen
            isAllergenDialogOpened={isAllergenDialogOpened}
            setIsAllergenDialogOpened={setIsAllergenDialogOpened}
            globalcard={globalcard}
            isSelectedCategoryActive={isSelectedCategoryActive}
            imageSrc={imageSrc}
            handleAllergenDialogOnCLickEvent={handleAllergenDialogOnCLickEvent}
            parentVariant={parentVariant}
            title={title}
            nestedCategories={nestedCategories}
        />
    );
}

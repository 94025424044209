/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CASHPAD,
    CATALOGUE_MODE,
    ISNEPTING_TYPE_10,
    ISNEPTING_TYPE_46_OR_45,
    ISVALINA_WITH_MULTIPLE_MODE,
    ISVALINA_WITH_SINGLE_MODE,
    KIOSK_AUTHORIZATION_ID,
    Retrait_C_C,
    SEND_MAIL_WITH_SMALL_TICKET,
    SEND_NORMAL_TICKET,
    SEND_ONLY_SMALL_TICKET,
    VALIDATED_EXTENSION_FILE,
    VALINA_PAYMENT_WITHOUT_CONTACT,
} from "@constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Dispatch } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import { mutate } from "swr";
import uuid from "uuid-by-string";
import { snapshot } from "valtio";

import i18n from "@i18n";

import { setIsTechProblemModalOpened, setPosEditor, store } from "@store";

import { useLocalWays } from "@hooks/useLocalWays";

import { refreshTcPosOrder } from "@components/OrderTaking/Helpers";
import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { refreshDataNeedTcpos } from "@components/OrderTaking/Helpers/TCPOS";
import { setCarte, setSetting } from "@components/OrderTaking/Store/feature";
import {
    setConnectedUser,
    setConnectedUserEntity,
    setIsEntitySelectionModalOpened,
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setActiveConfigOptions,
    setActiveLanguages,
    setAmountDeposited,
    setAuthModalStepIndex,
    setChangeMachineConfigurationDate,
    setChosenInformationMode,
    setCustomerLanguage,
    setFinalMessageSelectedInformationMode,
    setFinalMessageSelectedRegulationMode,
    setInitialStepTimeout,
    setIsAccessNotAllowed,
    setIsAmountReturned,
    setIsAnotherPaymentModeButtonClickedOnCBPaymentMode,
    setIsAnotherPaymentModeButtonDisabled,
    setIsAuthenticationErrorModalOpened,
    setIsAuthenticationModalOpened,
    setIsCashMachineConfigured,
    setIsCashMachineNotOccupied,
    setIsCashMachineStatus,
    setIsCashTransactionValidated,
    setIsChangeMachineReady,
    setIsChangeMachineRenderModalOpened,
    setIsCommandInsertionProblemModalOpened,
    setIsConfigOptionsActive,
    setIsCreatingVoucherAccepted,
    setIsCreationVoucherCanceled,
    setIsLanguagesActive,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsLogout,
    setIsOrderInsertedSuccessfully,
    setIsOrderInsertionRefused,
    setIsOrderPartialPaidWithLoyaltyAccountBalance,
    setIsOrderTakingPreviousStep,
    setIsRefundAnomalyModalOpened,
    setIsRegulationModesModalButtonClicked,
    setIsRegulationModesModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsTransactionCanceled,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setIsWithdrawalProblemModalOpened,
    setKeyboardInputsValues,
    setKioskWays,
    setLeftToPayAfterCashMachineValidation,
    setLogo,
    setNavigationIndex,
    setNumericKeyboardInputValue,
    setOrderModificationMode,
    setOrderNumber,
    setPaymentModes,
    setPaymentStepCounter,
    setProject,
    setProjectTimeout,
    setQrCodeValue,
    setReturnedAmount,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setSelectedRegulationModeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    setTotalAmountDeposited,
    setUser,
    setVoucherAmount,
} from "@pages/Kiosk/store";
import {
    setCreditCardPaymentChoice,
    setLocalProject,
    setMoneticConfig,
    setPagesItems,
} from "@pages/Kiosk/store/actions";

export function groupItemByLanguage(
    // eslint-disable-next-line
    items: any[],
    property: string
    // eslint-disable-next-line
): any[] {
    // eslint-disable-next-line
    return items.reduce(function (acc: any, obj: any) {
        if (!acc[property]) {
            acc[property] = [];
        }
        acc[property].push({
            ...obj.languages[property],
            id: obj.id,
            name: obj.name,
        });
        return acc;
    }, {})[property];
}

// @see https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
export function capitalize(s: string): string {
    return s?.charAt(0).toUpperCase() + s?.slice(1);
}

export function getImageContentById(id: string): string {
    const {
        project: { files: images },
        customerLanguage,
    } = snapshot(kioskStore);
    let imageContent = "";

    if (images[id] !== undefined) {
        if (typeof images[id]?.content === "string") {
            imageContent = images[id]?.content as string;
        } else {
            // eslint-disable-next-line
            imageContent = (images[id]?.content as ImageItemLanguagesType)[
                customerLanguage.name
            ].path;
        }
    }
    if (imageContent === "") {
        return "";
    }
    return VALIDATED_EXTENSION_FILE.includes(imageContent.substr(-4))
        ? imageContent?.includes("http")
            ? getFilenameFromUrl(imageContent, "template")
            : `./images/${imageContent}`
        : imageContent;
}

// eslint-disable-next-line
export function moveArrayElementToTheEnd(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
        element,
    ];
}

// eslint-disable-next-line
export function moveArrayElementToTheBegin(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        element,
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
    ];
}

// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export function formatCurrency(currencyData: number): string {
    const { devise } = snapshot(kioskStore);
    return (
        currencyData.toFixed(devise.decimalPrice) + ` ${devise.deviseSymbole}`
    );
}

export function changeLanguage(language: string): void {
    i18n.changeLanguage(language);
}

export function getBackgroundImagePath(
    images: ImageProjectItemType,
    kioskWays: WaysType[],
    navigationIndex: number
): string {
    const backgroundImageId = Object.values(images).find((image) => {
        return (
            image.key === "background" &&
            image.name === kioskWays[navigationIndex].name
        );
    })?.id;

    const imageSrc = getImageContentById(backgroundImageId as string);

    return imageSrc;
}

function initVariablesInPaymentStep() {
    const {
        isRegulationModeModalButtonClicked,
        isAnotherPaymentModeButtonDisabled,
        isCashMachineNotOccupied,
        isCashTransactionValidated,
        isTransactionCanceled,
        leftToPayAfterCashMachineValidation,
        isRegulationModesModalOpened,
        isChangeMachineRenderModalOpened,
        selectedRegulationModeValue,
    } = snapshot(kioskStore);
    if (isRegulationModesModalOpened) {
        setIsRegulationModesModalOpened(false);
    }
    if (isChangeMachineRenderModalOpened) {
        setIsChangeMachineRenderModalOpened(false);
    }
    if (isRegulationModeModalButtonClicked) {
        setIsRegulationModesModalButtonClicked(false);
    }
    if (isAnotherPaymentModeButtonDisabled === false) {
        setIsAnotherPaymentModeButtonDisabled(true);
    }

    console.log({ selectedRegulationModeValue }, "in paymentStep");
    if (selectedRegulationModeValue === "creditCard") {
        console.log("is change machine ready is setted to true");
        setIsChangeMachineReady(true);
    } else {
        setIsChangeMachineReady(false);
    }

    if (isCashMachineNotOccupied === true) {
        setIsCashMachineNotOccupied(false);
    }
    if (isCashTransactionValidated === true) {
        setIsCashTransactionValidated(false);
    }
    if (isTransactionCanceled === true) {
        setIsTransactionCanceled(false);
    }

    if (leftToPayAfterCashMachineValidation !== 0) {
        setLeftToPayAfterCashMachineValidation(0);
    }
    setPaymentStepCounter(0);
}

export async function getSkippedStepNumber(
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    isUserAuthenticated: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string,
    content: ProjectContentType,
    totalPrice: number,
    selectedAuthMode?: string
): Promise<number | undefined> {
    const {
        isSelectedSaleModeWithoutInformationModes,
        leftToPay,
        isClickAndCollectOrderPaid,
        userAuthInfo,
        paymentModes,
        isClickAndCollect,
        clickAndCollectOrderSalesMode,
        isInformationModeActivated,
    } = snapshot(kioskStore);
    const timestamp = new Date().getTime();
    let localCounter = 1;
    let localSelectedInformationMode = "";
    let localSelectedRegulationMode = "";
    let localSelectedAuthMode = "";
    let localSelectedSaleModeValue = isClickAndCollect
        ? clickAndCollectOrderSalesMode
        : selectedSaleModeValue;
    const salesMethodsStepIndex = kioskWays.indexOf(
        kioskWays.filter((page) => page?.name === "salesMethods")[0]
    );
    const connectionStepIndex = kioskWays.findIndex(
        (page) => page?.name === "connection"
    );
    const { middlewareApiUrl } = snapshot(store);
    for (let i = 1; i < kioskWays.length - navigationIndex; i++) {
        let flag = false;
        if (
            ((kioskWays[navigationIndex + i] as PageType).skipped as boolean) &&
            ["authenticationModes", "salesMethods", "meansOfPayment"].includes(
                kioskWays[navigationIndex + i].name
            )
        ) {
            switch (kioskWays[navigationIndex + i].name) {
                case "authenticationModes":
                    if (!isClickAndCollect) {
                        if (
                            isAuthenticationAccepted ||
                            connectionStepIndex === -1
                        ) {
                            const activeAuthModeName = (
                                content.authenticationModes as ProjectContentItemType
                            ).items.filter((item) => item.active === true)[0]
                                .shortName;
                            setSelectedAuthMode(activeAuthModeName as string);
                            localSelectedAuthMode =
                                activeAuthModeName as string;
                        }
                    }
                    localCounter += 1;
                    break;
                case "salesMethods":
                    {
                        const activeSalesMode = (
                            content.salesMethods as ProjectContentItemType
                        ).items.filter((item) => item.active === true)[0];

                        const isLocalSelectedSaleModeWithoutInformationModes =
                            (
                                content.salesMethods as ProjectContentItemType
                            ).items.filter(
                                (option: ProjectMainContentItemType) => {
                                    return (
                                        option.name ===
                                        (isClickAndCollect
                                            ? clickAndCollectOrderSalesMode
                                            : activeSalesMode.name)
                                    );
                                }
                            )[0]?.informationModes?.items.length === 0 ||
                            (
                                content.informationModes as ProjectContentItemType
                            ).items.filter(
                                (option: ProjectMainContentItemType) => {
                                    return (
                                        (option.active as ItemContentType)[
                                            isClickAndCollect
                                                ? clickAndCollectOrderSalesMode
                                                : activeSalesMode.name
                                        ] === true
                                    );
                                }
                            ).length === 0;
                        setIsSelectedSaleModeWithoutInformationModes(
                            isLocalSelectedSaleModeWithoutInformationModes
                        );
                        setSelectedSaleModeValue(
                            activeSalesMode.name as string
                        );
                        setSelectedSaleModeOrderTaking(
                            activeSalesMode.settingKey as string
                        );
                        setSelectedSaleModeId(activeSalesMode.id as string);
                        localSelectedSaleModeValue =
                            activeSalesMode.name as string;
                        localCounter += 1;
                    }
                    break;
                case "meansOfPayment":
                    {
                        const activeRegulationsModes = (
                            content.meansOfPayment as ProjectContentItemType
                        ).items.filter((item) => item.active === true)[0];
                        setFinalMessageSelectedRegulationMode(
                            activeRegulationsModes.name
                        );
                        setSelectedRegulationModeValue(
                            activeRegulationsModes.shortName as string
                        );
                        localSelectedRegulationMode =
                            activeRegulationsModes.shortName as string;
                        localCounter += 1;
                    }
                    break;
            }
        } else if (
            kioskWays[navigationIndex + i].name === "informationModes" &&
            ((
                (kioskWays[navigationIndex + i] as PageType)
                    ?.skipped as ItemContentType
            )[
                isClickAndCollect
                    ? clickAndCollectOrderSalesMode
                    : selectedSaleModeValue
            ] ||
                (
                    (kioskWays[navigationIndex + i] as PageType)
                        .skipped as ItemContentType
                )[localSelectedSaleModeValue])
        ) {
            const isLocalSelectedSaleModeWithoutInformationModes =
                (content.salesMethods as ProjectContentItemType).items.filter(
                    (option: ProjectMainContentItemType) => {
                        return (
                            option.name ===
                            (isClickAndCollect
                                ? clickAndCollectOrderSalesMode
                                : localSelectedSaleModeValue)
                        );
                    }
                )[0]?.informationModes?.items.length === 0 ||
                (
                    content.informationModes as ProjectContentItemType
                ).items.filter((option: ProjectMainContentItemType) => {
                    return (
                        (option.active as ItemContentType)[
                            isClickAndCollect
                                ? clickAndCollectOrderSalesMode
                                : localSelectedSaleModeValue
                        ] === true
                    );
                }).length === 0;

            if (isLocalSelectedSaleModeWithoutInformationModes === false) {
                const activeInformationMode = (
                    content.informationModes as ProjectContentItemType
                ).items
                    .map((datum: ProjectMainContentItemType) => {
                        if (
                            (datum.active as ItemContentType)[
                                isClickAndCollect
                                    ? clickAndCollectOrderSalesMode
                                    : localSelectedSaleModeValue
                            ] === true
                        ) {
                            return datum;
                        }
                    })
                    .filter((element) => element !== undefined)[0];

                if (activeInformationMode !== undefined) {
                    localSelectedInformationMode = (
                        activeInformationMode as ProjectMainContentItemType
                    ).shortName as string;

                    setSelectedInformationMode(
                        (activeInformationMode as ProjectMainContentItemType)
                            .shortName as string
                    );
                    setFinalMessageSelectedInformationMode(
                        (activeInformationMode as ProjectMainContentItemType)
                            .prettyName as string
                    );
                }
            } else {
                setSelectedInformationMode("");
                setFinalMessageSelectedInformationMode("none");
            }
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "informationModes" &&
            (salesMethodsStepIndex === -1 ||
                isSelectedSaleModeWithoutInformationModes === true ||
                isInformationModeActivated === false)
        ) {
            localSelectedInformationMode = "";
            setSelectedInformationMode("");
            setFinalMessageSelectedInformationMode("none");
            localCounter += 1;
        } else if (
            (kioskWays[navigationIndex + i].name ===
                "informationModesConsommation" &&
                ((selectedInformationMode === "" &&
                    localSelectedInformationMode === "") ||
                    isSelectedSaleModeWithoutInformationModes === true ||
                    isInformationModeActivated === false)) ||
            (kioskWays[navigationIndex + i].name === "payment" &&
                (["atTheCounter", "BDP"].includes(
                    selectedRegulationModeValue
                ) ||
                    ["atTheCounter", "BDP"].includes(
                        localSelectedRegulationMode
                    ) ||
                    (selectedRegulationModeValue === "" &&
                        localSelectedRegulationMode === "" &&
                        kioskWays[navigationIndex].name !== "meansOfPayment") ||
                    isOrderPaidWithLoyaltyAccountBalance === true ||
                    leftToPay === 0))
        ) {
            localCounter += 1;
        } else if (
            (kioskWays[navigationIndex + i].name === "authenticationModes" &&
                ((!isAuthenticationAccepted && connectionStepIndex > -1) ||
                    isUserAuthenticated)) ||
            (kioskWays[navigationIndex + i].name === "meansOfPayment" &&
                (isOrderPaidWithLoyaltyAccountBalance === true ||
                    isClickAndCollectOrderPaid))
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name ===
                "authenticationModeConsommation" &&
            (!isAuthenticationAccepted ||
                isUserAuthenticated ||
                (isAuthenticationAccepted && !isUserAuthenticated)) &&
            localSelectedAuthMode === "" &&
            (selectedAuthMode === "" || selectedAuthMode === undefined)
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "clickAndCollect" &&
            selectedSaleModeValue !== "Retrait C&C" &&
            localSelectedSaleModeValue !== "Retrait C&C"
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "connection" &&
            isUserAuthenticated
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "loyaltyConsommation"
        ) {
            const totalPaymentAmount = paymentModes.reduce(
                (acc: number, curr) => acc + curr.paymentAmount,
                0
            );
            const isOrderTotallyPaid = totalPaymentAmount === totalPrice;
            console.log(
                JSON.stringify({ isOrderTotallyPaid, isUserAuthenticated })
            );

            if (isUserAuthenticated === false || isOrderTotallyPaid) {
                localCounter += 1;
                setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
            } else {
                try {
                    const loyaltyBalanceResult = await fetch(
                        `${middlewareApiUrl}/loyaltyBalance?timestamp=${timestamp}`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(userAuthInfo),
                        }
                    ).then((response) => response.json());

                    console.log(
                        `loyaltyBalance request result is ${JSON.stringify(
                            loyaltyBalanceResult
                        )}`
                    );
                    // eslint-disable-next-line
                    if ((loyaltyBalanceResult as any).code === 2) {
                        if (
                            // eslint-disable-next-line
                            (loyaltyBalanceResult as any).response
                                .loyaltyAccount <= 0
                        ) {
                            localCounter += 1;
                            setIsOrderPartialPaidWithLoyaltyAccountBalance(
                                false
                            );
                        } else {
                            // eslint-disable-next-line
                            setUser((loyaltyBalanceResult as any).response);
                            flag = true;
                            return localCounter;
                        }
                    } else {
                        localCounter += 1;
                        setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    }
                } catch (error: unknown) {
                    localCounter += 1;
                    setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    console.error(
                        `Error while getting user information, with message: ${
                            (error as Error).message
                        }`
                    );
                }
            }
        } else {
            flag = true;
            if (kioskWays[navigationIndex + localCounter].name === "payment") {
                initVariablesInPaymentStep();
            }
            return localCounter;
        }

        if (flag) {
            break;
        }
    }
}

export async function getPreviousSkippedStepNumber(
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    isUserAuthenticated: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string
): Promise<number | undefined> {
    let localCounter = 1;
    const salesMethodsStepIndex = kioskWays.indexOf(
        kioskWays.filter((page) => page?.name === "salesMethods")[0]
    );
    const {
        isSelectedSaleModeWithoutInformationModes,
        leftToPay,
        userAuthInfo,
        authModalStepIndex,
        isClickAndCollect,
        clickAndCollectOrderSalesMode,
        isInformationModeActivated,
    } = snapshot(kioskStore);
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    const connectionStepIndex = kioskWays.findIndex(
        (page) => page?.name === "connection"
    );
    for (let i = 1; i <= navigationIndex; i++) {
        let flag = false;
        if (
            // eslint-disable-next-line
            (((kioskWays[navigationIndex - i] as PageType)
                .skipped! as boolean) &&
                [
                    "authenticationModes",
                    "salesMethods",
                    "meansOfPayment",
                ].includes(kioskWays[navigationIndex - i].name)) ||
            (kioskWays[navigationIndex - i].name === "informationModes" &&
                (
                    (kioskWays[navigationIndex - i] as PageType)
                        .skipped as ItemContentType
                )[
                    isClickAndCollect
                        ? clickAndCollectOrderSalesMode
                        : selectedSaleModeValue
                ]) ||
            (kioskWays[navigationIndex - i].name === "clickAndCollect" &&
                selectedSaleModeValue !== Retrait_C_C) ||
            (kioskWays[navigationIndex - i].name ===
                "informationModesConsommation" &&
                (selectedInformationMode === "" ||
                    isSelectedSaleModeWithoutInformationModes === true ||
                    isInformationModeActivated === false)) ||
            (kioskWays[navigationIndex - i].name === "payment" &&
                (["atTheCounter", "BDP"].includes(
                    selectedRegulationModeValue
                ) ||
                    (selectedRegulationModeValue === "" &&
                        kioskWays[navigationIndex].name !== "meansOfPayment") ||
                    isOrderPaidWithLoyaltyAccountBalance === true ||
                    leftToPay === 0)) ||
            kioskWays[navigationIndex - i].name ===
                "authenticationModeConsommation" ||
            (kioskWays[navigationIndex - i].name === "authenticationModes" &&
                !isAuthenticationAccepted &&
                connectionStepIndex > -1) ||
            (kioskWays[navigationIndex - i].name === "informationModes" &&
                (salesMethodsStepIndex === -1 ||
                    isSelectedSaleModeWithoutInformationModes === true ||
                    isInformationModeActivated === false)) ||
            (kioskWays[navigationIndex - i].name === "connection" &&
                isUserAuthenticated)
        ) {
            localCounter += 1;
        } else if (kioskWays[navigationIndex - i].name === "orderTaking") {
            setIsOrderTakingPreviousStep(true);
            flag = true;
            return localCounter;
        } else if (
            kioskWays[navigationIndex - i].name === "loyaltyConsommation"
        ) {
            if (isUserAuthenticated === false) {
                localCounter += 1;
                setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
            } else {
                try {
                    const loyaltyBalanceResult = await fetch(
                        `${middlewareApiUrl}/loyaltyBalance?timestamp=${timestamp}`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(userAuthInfo),
                        }
                    ).then((response) => response.json());

                    console.log(
                        `loyaltyBalance request result is ${JSON.stringify(
                            loyaltyBalanceResult
                        )}`
                    );
                    // eslint-disable-next-line
                    if ((loyaltyBalanceResult as any).code === 2) {
                        if (
                            // eslint-disable-next-line
                            (loyaltyBalanceResult as any).response
                                .loyaltyAccount <= 0
                        ) {
                            localCounter += 1;
                            setIsOrderPartialPaidWithLoyaltyAccountBalance(
                                false
                            );
                        } else {
                            // eslint-disable-next-line
                            setUser((loyaltyBalanceResult as any).response);
                            flag = true;
                            return localCounter;
                        }
                    } else {
                        localCounter += 1;
                        setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    }
                } catch (error: unknown) {
                    localCounter += 1;
                    setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    console.error(
                        `Error while getting user information, with message: ${
                            (error as Error).message
                        }`
                    );
                }
            }
        } else {
            flag = true;
            if (kioskWays[navigationIndex - localCounter].name === "payment") {
                initVariablesInPaymentStep();
            }

            if (
                kioskWays[navigationIndex - localCounter].name ===
                    "authenticationModes" &&
                authModalStepIndex === 0 &&
                isAuthenticationAccepted
            ) {
                setIsUserAuthenticated(false);
            }
            return localCounter;
        }

        if (flag) {
            break;
        }
    }
}

export function truncateString(string: string, maxLength: number): string {
    return string.length > maxLength ? `${string.slice(0, maxLength)}` : string;
}

export function getSubStepBackgroundImagePath(
    images: ImageProjectItemType,
    selectedMode: string
): string {
    const backgroundImageId = Object.values(images).find((image) => {
        return image.key === "background" && image.subName === selectedMode;
    })?.id;
    return getImageContentById(backgroundImageId as string);
}

export function getLogoImagePath(images: ImageProjectItemType): string {
    const logoId = Object.values(images).find((image) => {
        return image.key === "Logo";
    })?.id;
    return getImageContentById(logoId as string);
}

export function getTopBannerImagePath(
    activePageName: string,
    images: ImageProjectItemType
): string {
    const topBannerBackgroundImageId = Object.values(images).find((image) => {
        return (
            image.key === "topBannerBackground" && image.name === activePageName
        );
    })?.id;
    return getImageContentById(topBannerBackgroundImageId as string);
}

export function handleAbortCBTransaction(): void {
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    console.log(
        "handleAbortCBTransaction function, the launched API is",
        `${middlewareApiUrl}/AbortCBTransaction`
    );
    fetch(`${middlewareApiUrl}/AbortCBTransaction?timestamp=${timestamp}`)
        .then((response) => response.json())
        .then((response) => {
            console.log(
                "the response of aborting CB transaction",
                JSON.stringify(response)
            );
            if (response.code === 2) {
                console.log(
                    "the CB transaction is canceled by clicking another payment mode button in payment step"
                );
                setIsAnotherPaymentModeButtonClickedOnCBPaymentMode(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while cancelling the CB transaction, with the message: ${error.message}`
            );
        });
}

export function handleAbortCashMachineTransaction(): void {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    const url = `${middlewareApiUrl}/AbortTransactionCash?timestamp=${timestamp}`;
    console.log(
        "handleAbortCashMachineTransaction function, the launched API is",
        { url }
    );

    fetch(url)
        .then((response) => response.json())
        .then((response) => {
            console.log(
                "the response of aborting cash machine transaction",
                JSON.stringify(response)
            );
            if (response.code === 2) {
                console.log(
                    "the cash machine transaction is canceled, we pass the amount deposited variable to 0 and the is transaction canceled tu true"
                );
                setAmountDeposited(0);
                setIsTransactionCanceled(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while cancelling the change machine transaction, with the message: ${error.message}`
            );
        });
}

export function generateItemPriceUuid(strings: string[]): string {
    const lowercasedStrings = strings?.map((element) => element.toLowerCase());
    const sorted = [...lowercasedStrings].sort((a, b) => a.localeCompare(b));
    const sortedStrings = sorted.join("");
    const sortedLetters = sortedStrings.split("").sort().join("");
    return uuid(sortedLetters + sortedStrings);
}

export async function handleUserAuthentication(
    authModeInfo: AuthModeInfoType,
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string,
    authModalStepIndex: number,
    isAuthenticationErrorModalOpened: boolean,
    content: ProjectContentType,
    totalPrice: number,
    // eslint-disable-next-line
    clientInfo?: any
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const {
        saleMode,
        localSelectedInformationMode,
        localFinalMessageSelectedInformationMode,
        chosenLocalInformationMode,
        isOrderTakingPreviousStep,
        isClickAndCollect,
    } = snapshot(kioskStore);
    const timestamp = new Date().getTime();
    console.log(
        "handleUserAuthentication function with auth mode info structure:",
        JSON.stringify(authModeInfo)
    );

    await fetch(`${middlewareApiUrl}/loyaltyBalance?timestamp=${timestamp}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(authModeInfo),
    })
        .then((response) => response.json())
        .then(async (result) => {
            console.log(
                `Result of getting user information: ${JSON.stringify(result)}`
            );
            setQrCodeValue("");
            if (result.code === 3) {
                if (isClickAndCollect) {
                    setIsUserAuthenticated(true);
                    setAuthModalStepIndex(0);
                    setSelectedAuthMode("");
                    setUser({
                        ...clientInfo,
                        loyaltyAccount: 0,
                        loyaltyAmount: 0,
                    });
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    true,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(totalPrice)
                                )
                            )
                    );
                } else {
                    setIsAuthenticationErrorModalOpened(
                        !isAuthenticationErrorModalOpened
                    );
                }
            } else {
                setIsUserAccountModalOpened(false);
                setAuthModalStepIndex(0);
                setSelectedAuthMode("");
                setIsUserAuthenticated(true);
                setNumericKeyboardInputValue("");
                setKeyboardInputsValues({});
                setUser(result.response);

                if (authModalStepIndex !== 0) {
                    setNavigationIndex(authModalStepIndex);
                } else if (
                    selectedSaleModeValue === "Delivery" ||
                    saleMode.name === "Delivery"
                ) {
                    if (isOrderTakingPreviousStep === false) {
                        const salesMethodsStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (page) => page?.name === "salesMethods"
                            )[0]
                        );
                        setNavigationIndex(
                            salesMethodsStepIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        salesMethodsStepIndex,
                                        isAuthenticationAccepted,
                                        true,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content,
                                        Number(totalPrice)
                                    )
                                )
                        );
                    } else {
                        setIsOrderTakingPreviousStep(true);
                        const orderTakingStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (page) => page?.name === "orderTaking"
                            )[0]
                        );
                        setNavigationIndex(orderTakingStepIndex);
                        setSelectedSaleModeValue(saleMode.name);
                        setSelectedSaleModeId(saleMode.id);
                        setSelectedSaleModeOrderTaking(saleMode.settingKey);
                        setOrderModificationMode();
                        setSelectedInformationMode(
                            localSelectedInformationMode
                        );
                        setFinalMessageSelectedInformationMode(
                            localFinalMessageSelectedInformationMode
                        );
                        setChosenInformationMode(chosenLocalInformationMode);
                        if (localSelectedInformationMode === "") {
                            setFinalMessageSelectedInformationMode("none");
                        }
                    }
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    true,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(totalPrice)
                                )
                            )
                    );
                }
            }
        })
        .catch((error) =>
            console.error(
                `Error while getting user information, with message: ${error.message}`
            )
        );
}

function handlePrintVoucher(voucherId: string): void {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    fetch(
        `${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}&timestamp=${timestamp}`
    )
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "The result of printing voucher",
                JSON.stringify(result)
            );
        })
        .catch((error) =>
            console.error(
                `Error while printing voucher, with the message:${error.message}`
            )
        );
}

export async function handlePutVoucher(voucher: VoucherType): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    const apiUrl = `${middlewareApiUrl}/PutBonAvoir?timestamp=${timestamp}`;
    console.log(
        "handlePutVoucher",
        "The voucher structure:",
        JSON.stringify(voucher)
    );
    mutate(
        apiUrl,
        await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(voucher),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(
                    "the response of inserting the voucher is:",
                    JSON.stringify(result)
                );
                if (result.code === 2) {
                    handlePrintVoucher(result.id_avoir);
                }
            })
            .catch((error) =>
                console.error(
                    `Error while inserting the voucher, with the message:${error.message}`
                )
            )
    );
}

export function handlePrintClientTicket(orderNumber: number): void {
    const timestamp = new Date().getTime();
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), 60000);
    const { middlewareApiUrl } = snapshot(store);
    const { emailTicket, isSendMailWithPdfActive, isPrintTicket } =
        snapshot(kioskStore);
    console.log("handlePrintClientTicket func is called after order insertion");
    const ticketType = isSendMailWithPdfActive
        ? SEND_MAIL_WITH_SMALL_TICKET
        : isPrintTicket === false
        ? SEND_ONLY_SMALL_TICKET
        : SEND_NORMAL_TICKET;
    const url =
        ticketType === 1
            ? `${middlewareApiUrl}/printTicket?numTicket=${orderNumber}&clientMail=${emailTicket}&sendMailWithPdf=${ticketType}&timestamp=${timestamp}`
            : `${middlewareApiUrl}/printTicket?numTicket=${orderNumber}&sendMailWithPdf=${ticketType}&timestamp=${timestamp}`;
    fetch(url, { headers: { "Cache-Control": "no-cache" }, signal })
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "The result of printing client ticket",
                JSON.stringify(result)
            );
        })
        .catch((error) =>
            console.error(
                `Error while printing client ticket, with the message:${JSON.stringify(
                    error.message
                )}`
            )
        )
        .finally(() => clearTimeout(timeoutId));
}

function handleRefundDepositedAmount(
    orderRegulationModes: PaymentModesType,
    isCreatingVoucherAccepted: boolean,
    voucherAmount: number
) {
    const { middlewareApiUrl } = snapshot(store);

    console.log(
        "Inside handleRefundDepositedAmount func, we aim to refund the deposited amount with change machine!"
    );
    console.log(JSON.stringify(orderRegulationModes));

    const localPaymentModes = JSON.parse(JSON.stringify(orderRegulationModes));
    console.log(
        "inside handleRefundDepositedAmount func paymentModes:",
        JSON.stringify(localPaymentModes)
    );
    const isChangeMachinePaymentModeInMiddlwareOrderReglements =
        localPaymentModes.some(
            (mode: PaymentModeType) => mode.paymentMode === "changeMachine"
        );

    console.log({ isChangeMachinePaymentModeInMiddlwareOrderReglements });
    if (isChangeMachinePaymentModeInMiddlwareOrderReglements) {
        const changeMachinePaymentMode = localPaymentModes.find(
            (mode: PaymentModeType) => mode.paymentMode === "changeMachine"
        );
        console.log(
            "This is the object of change machine payment mode",
            JSON.stringify({
                changeMachinePaymentMode,
            }),
            "So we need to return the payment amount + the voucher amount if already passed by refund anomaly",
            "In this case the total amount is",
            JSON.stringify({
                paymentAmount:
                    changeMachinePaymentMode.paymentAmount +
                    (isCreatingVoucherAccepted ? voucherAmount : 0),
            })
        );
        setReturnedAmount(
            changeMachinePaymentMode.paymentAmount +
                (isCreatingVoucherAccepted ? voucherAmount : 0)
        );
        console.log(
            "To refund the amount already deposited this api is launched:",
            JSON.stringify(
                `${middlewareApiUrl}/RetraitMontant?unitaire=${-(
                    changeMachinePaymentMode.paymentAmount +
                    (isCreatingVoucherAccepted ? voucherAmount : 0)
                )}`
            )
        );
        fetch(
            `${middlewareApiUrl}/RetraitMontant?unitaire=${-(
                changeMachinePaymentMode.paymentAmount +
                (isCreatingVoucherAccepted ? voucherAmount : 0)
            )}`
        )
            .then((response) => response.json())
            .then((response) => {
                const localRenderAmount = Number(
                    response.response
                        .split(`:`)[2]
                        .split(`,`)[0]
                        .substr(
                            1,
                            response.response.split(`:`)[2].split(`,`)[0]
                                .length - 2
                        )
                );

                if (localRenderAmount > 0) {
                    console.log(
                        "Here the amount returned and the change machine render modal is opened"
                    );
                    setIsChangeMachineRenderModalOpened(true);
                } else {
                    console.log(
                        "Here the amount not returned and the Withdrawal Problem modal is opened to print a voucher with the amount deposited"
                    );
                    setIsChangeMachineRenderModalOpened(false);
                    setIsWithdrawalProblemModalOpened(true);
                }
            })
            .catch((error) => {
                setIsWithdrawalProblemModalOpened(true);
                console.log(
                    `Error when withdrawing the deposited amount, with the message: ${JSON.stringify(
                        error.message
                    )}`
                );
            });
    } else {
        setIsAmountReturned(true);
    }
}

export async function handlePutCommand(
    middlwareOrder: ObjectType
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const controller = new AbortController();
    const signal = controller.signal;

    console.log(
        "Put command function",
        "The order structure:",
        JSON.stringify(middlwareOrder)
    );
    await fetch(`${middlewareApiUrl}/PutCommandeV2`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(middlwareOrder),
        signal,
    })
        .then((response) => response.json())
        .then(async (result) => {
            console.log(
                "the response of inserting the order is:",
                JSON.stringify(result)
            );
            if (result.code === 2) {
                putOrderSuccessfullyInserted(result);
            } else {
                putOrderInsertionRefused(middlwareOrder);
            }
        })
        .catch((error) => {
            console.log(
                `Error while handling put command request, with message: ${JSON.stringify(
                    error.message
                )}`
            );
            putOrderInsertionRefused(middlwareOrder);
        });
}

export async function handlePutClickAndCollectOrder(
    clickAndCollectOrderId: string,
    localPaymentModes: PaymentModesType,
    isCreatingVoucherAccepted: boolean,
    voucherAmount: number
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);

    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsCommandInsertionProblemModalOpened(true);
    }, 60000);

    console.log(
        "Put click and collect command function",
        "The order structure:",
        JSON.stringify({ clickAndCollectOrderId, localPaymentModes })
    );
    await fetch(
        `${middlewareApiUrl}/PutCommandClickAndCollect?clickAndCollectOrderId=${clickAndCollectOrderId}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(localPaymentModes),
            signal,
        }
    )
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "the response of inserting the order is:",
                JSON.stringify(result)
            );
            if (result.code === 2) {
                console.log("the order inserted successfully");
                setOrderNumber(
                    String(result.response[0]).slice(
                        8,
                        result.response[0].length
                    )
                );
                console.log(
                    "Inside Put click and collect command func verify if creating voucher accepted?",
                    { isCreatingVoucherAccepted }
                );
                if (isCreatingVoucherAccepted === true) {
                    console.log(
                        "yes!! creating voucher is accepted. So, handlePrintVoucher"
                    );
                    handlePrintVoucher(result.response[1]);
                }
                handlePrintClientTicket(result.response[0]);
                setIsOrderInsertedSuccessfully(true);
            } else {
                console.log(
                    "Oops! There seems to be an error while inserting the order."
                );
                console.log(
                    "SO,refund the deposited amount is necessary, handleRefundDepositedAmount function"
                );

                console.log(
                    "inside put command func regulation list",
                    // eslint-disable-next-line
                    JSON.stringify(localPaymentModes)
                );

                handleRefundDepositedAmount(
                    // eslint-disable-next-line
                    localPaymentModes,
                    isCreatingVoucherAccepted,
                    voucherAmount
                );

                console.log("Command Insertion Problem Modal Is Opened!!");
                setIsCommandInsertionProblemModalOpened(true);
                setIsOrderInsertionRefused(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while handling put command request, with message: ${JSON.stringify(
                    error.message
                )}`
            );
            console.log(
                "SO,refund the deposited amount is necessary, handleRefundDepositedAmount function"
            );
            console.log(
                "inside put commande func regulation list",
                // eslint-disable-next-line
                JSON.stringify(localPaymentModes)
            );

            handleRefundDepositedAmount(
                // eslint-disable-next-line
                localPaymentModes,
                isCreatingVoucherAccepted,
                voucherAmount
            );

            console.log("Command Insertion Problem Modal Is Opened!!");
            setIsCommandInsertionProblemModalOpened(true);
            setIsOrderInsertionRefused(true);
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}

function putOrderSuccessfullyInserted(
    // eslint-disable-next-line
    putOrderResult: any
): void {
    const { isCreatingVoucherAccepted } = snapshot(kioskStore);
    const { posEditor } = snapshot(store);
    const partnerCmdNumber = putOrderResult.response[5];
    if (posEditor === CASHPAD && partnerCmdNumber === undefined) {
        console.log("Command Partner Insertion Problem Modal Is Opened!!");
        setIsCommandInsertionProblemModalOpened(true);
        setIsOrderInsertionRefused(true);
        setIsAmountReturned(true);
    }
    console.log("the order inserted successfully");

    setOrderNumber(
        posEditor === CASHPAD && partnerCmdNumber !== undefined
            ? String(partnerCmdNumber)
            : String(putOrderResult.response[0]).slice(
                  8,
                  putOrderResult.response[0].length
              )
    );
    console.log(
        "Inside Put command func verify if creating voucher accepted?",
        { isCreatingVoucherAccepted }
    );
    if (isCreatingVoucherAccepted === true) {
        console.log(
            "yes!! creating voucher is accepted. So, handlePrintVoucher"
        );
        handlePrintVoucher(putOrderResult.response[4]);
    }

    setIsOrderInsertedSuccessfully(true);
    handlePrintClientTicket(putOrderResult.response[0]);
    setIsOrderInsertedSuccessfully(true);
}

function putOrderInsertionRefused(middlwareOrder: ObjectType): void {
    const { isCreatingVoucherAccepted, voucherAmount } = snapshot(kioskStore);
    console.log("Oops! There seems to be an error while inserting the order.");
    console.log(
        "SO,refund the deposited amount is necessary, handleRefundDepositedAmount function"
    );

    console.log(
        "inside put command func regulation list",
        // eslint-disable-next-line
        JSON.stringify((middlwareOrder as any).reglements)
    );

    handleRefundDepositedAmount(
        // eslint-disable-next-line
        (middlwareOrder as any).reglements,
        isCreatingVoucherAccepted,
        voucherAmount
    );

    console.log("Command Insertion Problem Modal Is Opened!!");
    setIsCommandInsertionProblemModalOpened(true);
    setIsOrderInsertionRefused(true);
}

export function getEntityData(
    data: EntityDataType | EntityDataType[]
): EntityType[] {
    let entities: EntityType[] = [];

    if (Array.isArray(data)) {
        entities = data.map((datum: EntityDataType) => getEntityData(datum)[0]);
    } else {
        const entity: EntityType = {
            uid: data["uid"] as string,
            crm_id: data["CRMId"] as string,
            name: data["Nom commercial"] as string,
            franchise: data["Name"] as string,
            schema: data["franchise_schema"] as string,
            nature: data["Nature"] as string,
            type: data["ETKType"] as EntityTypeType,
            accountType: data["Type du compte"] as string,
            address: data["Adresse"] as string,
            children_uid: data["childs_profilelink"] as string[],
            parent: data["parent_profilelink"] as EntityParentType,
            franchiseUuid: data["uuidFranchise"],
            franchiseUuidDatabaseInfo: data["uid_franchise_database_info"],
        };

        if (data["IdStore"] !== undefined) {
            entity.id = data["IdStore"] as string | undefined;
        }

        if (data["subRows"] !== undefined) {
            const children: EntityType[] = [];

            data["subRows"].forEach((subRow: EntityDataType) => {
                children.push(getEntityData(subRow)[0]);
            });

            entity.children = children;
        }

        entities.push(entity);
    }

    return entities;
}

export async function decryptWord(wordToDecrypt: string): Promise<string> {
    try {
        return await fetch(
            `${process.env.REACT_APP_CONSOLE_APP_API}/decryptWord`,
            {
                headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `wordToDecrypted=${wordToDecrypt}`,
                method: "POST",
            }
        ).then((response) => {
            return response.text();
        });
    } catch (error) {
        console.log(
            `Error while decrypting word ${wordToDecrypt}, with message: ${JSON.stringify(
                (error as Error).message
            )}`
        );
        return "";
    }
}

export function getYesActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const yesImageSrc = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "Yes"
            ) as ActionsButtonsDesignItemType
        ).id
    );

    return yesImageSrc;
}

export function getNoActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const noImageSrc = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "No"
            ) as ActionsButtonsDesignItemType
        ).id
    );

    return noImageSrc;
}

export function getAlertActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const alertImageSrc =
        actionsButtons !== undefined &&
        actionsButtons.items !== undefined &&
        actionsButtons?.items?.length > 0
            ? getImageContentById(
                  (
                      (actionsButtons as ActionsButtonsDesignType).items.find(
                          (actionButton) => actionButton.name === "Alert"
                      ) as ActionsButtonsDesignItemType
                  ).id
              )
            : "./images/alert.png";

    return alertImageSrc;
}

export function getOtherPaymentMethodButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const otherPaymentMethodImageSrc =
        actionsButtons?.items !== undefined && actionsButtons?.items?.length > 0
            ? getImageContentById(
                  (
                      (actionsButtons as ActionsButtonsDesignType).items.find(
                          (actionButton) =>
                              actionButton.name === "otherPaymentMethod"
                      ) as ActionsButtonsDesignItemType
                  ).id
              )
            : "";

    return otherPaymentMethodImageSrc;
}

export function initCardReader(ipAddress: string): void {
    const { middlewareApiUrl } = snapshot(store);
    const { selectedShopApp } = snapshot(syncStore);

    const timestamp = new Date().getTime();
    const timeoutId = setTimeout(() => controller.abort(), 360000);
    const controller = new AbortController();

    const signal = controller.signal;
    // eslint-disable-next-line
    const isValina =
        selectedShopApp?.monetics !== undefined &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(selectedShopApp.monetics as any).some(
            // eslint-disable-next-line
            (item: any) => item.mark === "Valina"
        );

    const isValinaMultiMode: boolean =
        isValina &&
        // eslint-disable-next-line
        (
            (Object.values(selectedShopApp?.monetics as any)[0] as any)
                .payment_method as any
        )?.value !== VALINA_PAYMENT_WITHOUT_CONTACT.toString();

    console.log("isTpaValinaAvailable:", isValina);
    // eslint-disable-next-line
    const moneticNepting: any = selectedShopApp.monetics
        ? // eslint-disable-next-line
          Object.values(selectedShopApp.monetics as any)[0]
        : undefined;

    const isTerminalProcessed: boolean =
        moneticNepting &&
        moneticNepting?.mark === "Nepting" &&
        (moneticNepting?.setup_type?.value === "ERT 45" ||
            moneticNepting?.setup_type?.value === "ERT 46");

    if (isValina) {
        setMoneticConfig({
            type: "Valina",
            mode: isValinaMultiMode
                ? ISVALINA_WITH_MULTIPLE_MODE
                : ISVALINA_WITH_SINGLE_MODE,
        });
        if (isValinaMultiMode) {
            setCreditCardPaymentChoice("");
        } else {
            setCreditCardPaymentChoice("withCode");
        }
        fetch(`${middlewareApiUrl}/ValinaSettings?timestamp=${timestamp}`, {
            signal,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.code !== 0) {
                    setIsLogout(false);
                    console.log(
                        `Error while initialize tpe  from Valina Settings api response  `
                    );
                } else {
                    setIsLogout(true);
                    console.log(`success while initialize tpe Valina `);
                }
            })
            .catch((error) => {
                console.log(
                    `Error while initialize tpe Valina , with message: ${JSON.stringify(
                        error.message
                    )}`
                );
            })
            .finally(() => {
                clearTimeout(timeoutId);
            });
    } else {
        setMoneticConfig({
            type: "Nepting",
            mode: isTerminalProcessed
                ? ISNEPTING_TYPE_46_OR_45
                : ISNEPTING_TYPE_10,
        });
        fetch(
            `${middlewareApiUrl}/LogoutLecteur?originName=${ipAddress}&Type=Logout&timestamp=${timestamp}`,
            { signal }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.code === 0) {
                    setIsLogout(false);
                } else {
                    setIsLogout(true);
                }
            })
            .catch((error) => {
                console.log(
                    `Error while logout credit card, with message: ${JSON.stringify(
                        error.message
                    )}`
                );
            })
            .finally(() => {
                clearTimeout(timeoutId);
            });
    }
}
export function configCashMachine(ipCashMachine: string): void {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    // eslint-disable-next-line
    let changeMachineConfigurationDate: any = "";
    fetch(
        `${middlewareApiUrl}/ConfigMonnayeur?IpCash=${ipCashMachine}&timestamp=${timestamp}`
    )
        .then((response) => response.json())
        .then((response) => {
            if (response.code === 0) {
                setIsCashMachineConfigured(false);
                console.log("The change machine config failed");
            } else {
                console.log("The change machine config done successfully");
                changeMachineConfigurationDate = moment(new Date()).format(
                    "YYYY-MM-DD"
                );
                console.log(
                    `The change machine configuration date is :${changeMachineConfigurationDate}`
                );
                setChangeMachineConfigurationDate(
                    changeMachineConfigurationDate
                );
                setIsCashMachineConfigured(true);
            }
        })
        .catch((error) =>
            console.error(
                `Error while change machine initialization, with the message:${JSON.stringify(
                    error.message
                )}`
            )
        );
}

export function getPeripheralDeviceImageSrc(item: string): string {
    if (item.toLowerCase() === "change machine") {
        return "cash-management";
    }
    if (item.toLowerCase() === "printer") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "led") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "tpe") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "scanner") {
        return "led";
    }
    return "placeholder";
}

export function removeItemFromList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[],
    index: number
): [
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    ),
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[]
] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[] = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
}

export function addItemInList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[],
    index: number,
    element:
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
): (
    | PageType
    | ConsommationPageType
    | StandbyScreenPageType
    | PaymentPageType
    | OrderTakingPageType
    | PrinterOptionsPageType
)[] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[] = Array.from(list);
    result.splice(index, 0, element);
    return result;
}

export function getCatalog(
    franchiseSchema: string,
    shopId: string,
    // eslint-disable-next-line
    dispatch: Dispatch<any>
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);

    const timestamp = new Date().getTime(); // current timestamp

    return new Promise<void>((resolve, reject) => {
        fetch(
            `${middlewareApiUrl}/kiosk/${franchiseSchema}/${shopId}/card?timestamp=${timestamp}`,
            { headers: { "Cache-Control": "no-cache" } }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.error === true) {
                    throw Error("There's an issue while getting catalog");
                }

                dispatch(setCarte(data));

                if (process.env.REACT_APP_ELECTRON_ENV !== "dev") {
                    (window as RendererWindow).electronAPI?.getCatalogEvent({
                        data: JSON.stringify(data),
                    });
                }

                resolve();
            })
            .catch((error) => {
                console.log(
                    `Error while getting catalog, with message: ${JSON.stringify(
                        error.message
                    )}`
                );
                reject(error);
            });
    });
}

export function updateStepsProject(project: ProjectType): void {
    const localKioskWays = useLocalWays(project);

    setKioskWays(localKioskWays);

    setProjectTimeout(project.template.timeout);

    setIsConfigOptionsActive(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).active
    );

    setActiveConfigOptions(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).items.filter((option) => option.active === true)
    );

    setIsLanguagesActive(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).active &&
            (
                (project.template.content as ProjectContentType)
                    .languages as LanguagesType
            )?.items.filter((option) => option.active === true).length !== 1
    );

    setActiveLanguages(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).items.filter((language) => language.active === true)
    );

    setCustomerLanguage(
        (project.template.content.languages as LanguagesType).items.filter(
            (language) => language.isDefault === true
        )[0]
    );

    setInitialStepTimeout(project.template.initialStepTimeout as number);

    setLogo((project.template.content.generalDesign as GeneralDesignType).logo);
}

export function getProjectByFranchiseUidAndShopUid(
    franchise_uid: string,
    shop_uid: string,
    // eslint-disable-next-line
    dispatch: Dispatch<any>
): void {
    const { middlewareApiUrl } = snapshot(store);
    const { selectedShopApp } = snapshot(syncStore);
    const timestamp = new Date().getTime();

    fetch(
        `${middlewareApiUrl}/settings/consomation?franchise_uuid=${franchise_uid}&shop_uuid=${shop_uid}&nameKiosk=${selectedShopApp.name}&timestamp=${timestamp}`
    )
        .then((response) => response.json())
        .then(({ kiosk }) => {
            if (kiosk.length > 0) {
                setPosEditor(kiosk[0].posEditor);
            }
            const modifiedProject = kiosk.filter(
                (kiosk: Record<string, string>) => {
                    return kiosk.name === selectedShopApp.name;
                }
            );
            if (Array.isArray(modifiedProject) && modifiedProject.length > 0) {
                setProject(modifiedProject[0].project);
                updateStepsProject(modifiedProject[0].project);

                dispatch(
                    setSetting(
                        setOrderTakingSetting(
                            modifiedProject[0].project.template.pages.ways
                                .orderTaking as PageType,

                            (
                                modifiedProject[0].project.template.content
                                    .generalDesign as GeneralDesignType
                            ).colors[0].content,
                            (
                                modifiedProject[0].project.template.content
                                    .generalDesign as GeneralDesignType
                            ).colors[1].content
                        )
                    )
                );

                if (process.env.REACT_APP_ELECTRON_ENV !== "dev") {
                    (window as RendererWindow).electronAPI?.getProjectEvent({
                        data: JSON.stringify(
                            modifiedProject[0].project,
                            null,
                            4
                        ),
                    });
                }
            }
        })
        .catch((error) =>
            console.log(
                `Error while updating project from setting, with message: ${JSON.stringify(
                    error.message
                )}`
            )
        );
}

export async function handleChangeMachineStatus(): Promise<void> {
    const ctrl = new AbortController();
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);

    console.log("The change machine status api is started");
    setIsCashMachineStatus(true);

    await fetchEventSource(
        `${middlewareApiUrl}/StatusCashMachine?timestamp=${timestamp}`,
        {
            signal: ctrl.signal,
            onmessage(event) {
                console.log(
                    `The change machine status api event:${JSON.stringify(
                        event
                    )}`
                );

                switch (event.event) {
                    case "InventoryResponse": {
                        setIsChangeMachineReady(true);
                        setIsCashMachineStatus(false);
                        console.log(
                            "We receive InventoryResponse event witch mean that the change machine is ready and remove the control from change machine status api"
                        );
                        ctrl.abort();
                        break;
                    }
                    case "EventCash": {
                        console.log(
                            "We receive EventCash event witch mean that the change machine is not ready"
                        );
                        setIsChangeMachineReady(false);
                        break;
                    }
                    case "eventError":
                    case "Error":
                    case "IncompleteTransaction":
                    case "PowerControlResponse":
                    case "SpecialDeviceError": {
                        console.log(JSON.stringify(event.data));
                        break;
                    }
                }
            },
            onclose() {
                console.log("Connection close");
                ctrl.abort();
            },
            onerror(error) {
                console.log(
                    `Error while getting the change machine status, with the message: ${JSON.stringify(
                        error.message
                    )}`
                );
                ctrl.abort();
            },
        }
    );
}

export function handleCancelVoucherGetting(totalPrice: number): void {
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    const {
        amountDeposited,
        leftToPayAfterCashMachineValidation,
        isCreationVoucherCanceled,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
    } = snapshot(kioskStore);

    if (isCreationVoucherCanceled === false) {
        console.log("The button of canceling voucher getting is clicked!!!");
        console.log(
            `Creating voucher is canceled, a refund deposited amount api will be started`
        );
        setIsCreationVoucherCanceled(true);
        console.log("YES/NO buttons will be disabled!!");
        if (leftToPayAfterCashMachineValidation !== 0) {
            console.log(
                `The left to pay after cash machine validation is :${leftToPayAfterCashMachineValidation}, it means that the amount deposited already is :${
                    (isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice) - leftToPayAfterCashMachineValidation
                }, and the total deposited amount is:${
                    amountDeposited +
                    (isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice) -
                    leftToPayAfterCashMachineValidation
                }`
            );
        }

        const localTotalDepositedAmount =
            amountDeposited + totalAmountDeposited;

        fetch(
            `${middlewareApiUrl}/RetraitMontant?unitaire=-${localTotalDepositedAmount}&timestamp=${timestamp}`
        )
            .then((response) => response.json())
            .then((response) => {
                console.log(JSON.stringify({ response: response.response }));
                const localRenderAmount = Number(
                    response.response
                        .split(`:`)[2]
                        .split(`,`)[0]
                        .substr(
                            1,
                            response.response.split(`:`)[2].split(`,`)[0]
                                .length - 2
                        )
                );
                console.log({ renderAmount: localRenderAmount });
                if (localRenderAmount > 0) {
                    console.log(
                        `The change machine refund the amount deposited:${localRenderAmount} successfully`
                    );
                    setIsRefundAnomalyModalOpened(false);
                    setIsRegulationModesModalOpened(true);
                    setIsCreationVoucherCanceled(false);
                } else {
                    console.log(
                        `Since the change machine cannot refund the deposited amount, a voucher will be created with the deposited amount of ${localRenderAmount}`
                    );
                    setVoucherAmount(localTotalDepositedAmount);
                    setIsWithdrawalProblemModalOpened(true);
                }
            })
            .catch((error) => {
                console.log(
                    `Error when withdrawing the deposited amount, with the message: ${JSON.stringify(
                        error.message
                    )}`
                );
            });
        setLeftToPayAfterCashMachineValidation(0);

        setTotalAmountDeposited(0);
    }
}

export function acceptVoucherGetting(totalPrice: number): void {
    const {
        leftToPayAfterCashMachineValidation,
        cashUuID,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
        paymentModes,
        selectedRegulationModeValue,
        voucherAmount,
    } = snapshot(kioskStore);

    const selectedPaymentMode = cloneDeep(
        paymentModes.find(
            (mode) => mode.paymentMode === selectedRegulationModeValue
        )
    );

    if (selectedPaymentMode !== undefined) {
        const selectedPaymentModeIndex = paymentModes.findIndex(
            (mode) => mode.paymentMode === selectedRegulationModeValue
        );

        const localPaymentModes = cloneDeep(
            paymentModes.map((paymentMode) => {
                return paymentMode;
            })
        );

        localPaymentModes[selectedPaymentModeIndex] = {
            paymentModeUuiD: cashUuID,
            paymentMode: selectedRegulationModeValue,
            paymentAmount:
                totalAmountDeposited + leftToPayAfterCashMachineValidation,
            data: {
                renderAmount: 0,
            },
            totalAmountDeposited:
                totalAmountDeposited + leftToPayAfterCashMachineValidation,
        };

        console.log(
            "JSON.stringify(PaymentModes) in PaymentStep code 999 IF",
            JSON.stringify([
                ...localPaymentModes,
                {
                    paymentModeUuiD: "",
                    paymentMode: "voucher",
                    //avoir rendu negatif
                    paymentAmount: -voucherAmount,
                    data: {
                        renderAmount: 0,
                        mode_reglement: "ESP",
                    },
                    totalAmountDeposited: 0,
                },
            ])
        );
        setPaymentModes([
            ...localPaymentModes,
            {
                paymentModeUuiD: "",
                paymentMode: "voucher",
                //avoir rendu negatif
                paymentAmount: -voucherAmount,
                data: {
                    renderAmount: 0,
                    mode_reglement: "ESP",
                },
                totalAmountDeposited: 0,
            },
        ]);
    } else {
        console.log(
            "JSON.stringify(PaymentModes) in PaymentStep code 999 ELSE",
            JSON.stringify([
                ...paymentModes,
                {
                    paymentModeUuiD: cashUuID,
                    paymentMode: "changeMachine",
                    paymentAmount: isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice,
                    data: {
                        renderAmount: 0,
                    },
                    totalAmountDeposited:
                        isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                            : totalPrice,
                },
                {
                    paymentModeUuiD: "",
                    paymentMode: "voucher",
                    //avoir rendu negatif
                    paymentAmount: -voucherAmount,
                    data: {
                        renderAmount: 0,
                        mode_reglement: "ESP",
                    },
                    totalAmountDeposited: 0,
                },
            ])
        );
        setPaymentModes([
            ...paymentModes,
            {
                paymentModeUuiD: cashUuID,
                paymentMode: "changeMachine",
                paymentAmount: isOrderPartialPaidWithLoyaltyAccountBalance
                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                    : totalPrice,
                data: {
                    renderAmount: 0,
                },
                totalAmountDeposited:
                    isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice,
            },
            {
                paymentModeUuiD: "",
                paymentMode: "voucher",
                //avoir rendu negatif
                paymentAmount: -voucherAmount,
                data: {
                    renderAmount: 0,
                    mode_reglement: "ESP",
                },
                totalAmountDeposited: 0,
            },
        ]);
    }

    setIsCreatingVoucherAccepted(true);
    setIsRefundAnomalyModalOpened(false);
}

export function getFilenameFromUrl(url: string, path: string): string {
    const { middlewareApiUrl } = snapshot(store);
    if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
        return url;
    }
    try {
        const imageName = url.split("/").pop();
        const expectedImage = ["no-pictures.svg", "nopicture.png"];
        const assetsNewUrl =
            imageName && !expectedImage.includes(imageName)
                ? `${middlewareApiUrl}/static/${path}/${imageName}`
                : "./images/nopicture.png";

        return assetsNewUrl;
    } catch (error) {
        console.log(`getFilenameFromUrl error:url:${url} `);
        return url;
    }
}

export function getGiveUpActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const giveUpImageSrc = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "giveUp"
            ) as ActionsButtonsDesignItemType
        ).id
    );

    return giveUpImageSrc;
}

export function connectWebSocket(): void {
    const middlewareApiUrl = localStorage.getItem("middlewareApiUrl") as string;

    const url =
        middlewareApiUrl.length > 0
            ? "ws://" + middlewareApiUrl.split("/")[2].split(":")[0] + ":8181"
            : "ws://localhost:8181";
    const ws = new WebSocket(url);
    ws.onopen = function () {
        console.log("TCPOS Socket Connection is Opened");
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ws.onmessage = async function ({ data }: any) {
        const { eventType } = JSON.parse(
            JSON.parse(JSON.stringify(data.toString()))
        );
        switch (eventType) {
            case "refreshDataNeeded": {
                refreshDataNeedTcpos();
                break;
            }
            case "articleAddedByBarcode": {
                await refreshTcPosOrder();
                break;
            }
            case "customerAdded": {
                await refreshTcPosOrder();
                break;
            }
            case "changeStatus": {
                await refreshTcPosOrder();
                break;
            }
            case "quantityModified": {
                await refreshTcPosOrder();
                break;
            }
            case "quantitySupressed": {
                await refreshTcPosOrder();
                break;
            }
        }
    };
    ws.onclose = function () {
        console.log("TCPOS Socket Connection is closed...");
    };
}

export function VerifyIsPrinterStepWithPrintTicketButton(): boolean {
    const {
        navigationIndex,
        kioskWays,

        project: {
            template: { pages },
        },
        isUserAuthenticated,
        isSendEmailConfirmed,
        user,
    } = snapshot(kioskStore);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const validEmail =
        (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
            ? isUserAuthenticated &&
              (pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isConfirmationEmailActive === false
                ? emailRegex.test(user.email)
                : true
            : true;

    return (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
        ? (((pages.ways.printerOptions as PrinterOptionsPageType)
              .printerOptionsConfig.isEmailActive &&
              isUserAuthenticated &&
              validEmail) ||
              ((pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isUnauthenticatedEmailActive &&
                  !isUserAuthenticated)) &&
              kioskWays[navigationIndex]?.name === "printerOptions" &&
              !isSendEmailConfirmed
        : false;
}

export function VerifyIsPrinterStepWithEmail(): boolean {
    const {
        project: {
            template: { pages },
        },
        isUserAuthenticated,
        user,
    } = snapshot(kioskStore);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const validEmail =
        (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
            ? isUserAuthenticated &&
              (pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isConfirmationEmailActive === false
                ? emailRegex.test(user.email)
                : true
            : true;
    return (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
        ? ((pages.ways.printerOptions as PrinterOptionsPageType)
              .printerOptionsConfig.isEmailActive &&
              isUserAuthenticated &&
              validEmail) ||
              ((pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isUnauthenticatedEmailActive &&
                  !isUserAuthenticated)
        : false;
}

export async function handleLedControl(
    connectedPort: string,
    ledColor: string
): Promise<void> {
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    await fetch(`${middlewareApiUrl}/LedControl?timestamp=${timestamp}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            Port: connectedPort,
            color: ledColor,
        }),
    })
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "the response of sending led data:",
                JSON.stringify(result)
            );
            if (result.code === 2) {
                return console.log("the result code 2");
            }
        })
        .catch((error) =>
            console.error(
                `Error while sending led data:${JSON.stringify(error.message)}`
            )
        );
}

export function convertSelectedSalesModeValue(saleModeCode: number): string {
    switch (saleModeCode) {
        case 1:
            return "Delivery";
        case 2:
            return "To take";
        case 3:
            return "On the spot";
        default:
            return "To take";
    }
}

export async function handleLoginLocalSettingsButtonOnClickEvent(
    encodedEmail: string,
    encodedPassword: string,
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >,
    setLoginButton: React.Dispatch<React.SetStateAction<string>>
    // eslint-disable-next-line
): Promise<any> {
    const { middlewareApiUrl } = snapshot(store);
    const uuidConnectedEntity = localStorage.getItem("connectedUser") as string;
    const timestamp = new Date().getTime();
    const url = `${middlewareApiUrl}/verifyKioskLocalAccount?timestamp=${timestamp}`;
    const shopDetail = localStorage.getItem("shopDetail")
        ? JSON.parse(localStorage.getItem("shopDetail") as string)
        : {};
    const { project } = snapshot(kioskStore);
    const usersToken = localStorage.getItem("usersToken");
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsTechProblemModalOpened(true);
        setLoginButton("Login");
    }, 30000);
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `email=${encodedEmail}&password=${encodedPassword}&uuidEntity=${uuidConnectedEntity}&uuidShop=${shopDetail.shop_uid}&usersToken=${usersToken}`,
        method: "POST",
    })
        .then((response) => response.json())
        .then(async (result) => {
            if (result.error === true) {
                setIsErrorLogInModalWrapperOpened(true);
                setLoginButton("Login");
            } else if (result.tokenAccess) {
                console.log(
                    `Loged using MiddleWare with email ${encodedEmail} access token : ${JSON.stringify(
                        usersToken
                    )}`
                );
                const tokens = JSON.parse(usersToken as string);
                const connectedUserToken =
                    tokens[decodeURIComponent(encodedEmail)];
                setConnectedUser({
                    email: decodeURIComponent(encodedEmail),
                    firstName: connectedUserToken.firstName,
                });
                setLocalProject(project);
                setPagesItems(project.template.pages);
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(true);
                setLoginButton("Login");
            } else if (
                result.data.profileAuthorization[KIOSK_AUTHORIZATION_ID]
                    .Modifie === false
            ) {
                setIsAccessNotAllowed(true);
                setIsErrorLogInModalWrapperOpened(true);
                setLoginButton("Login");
            } else {
                localStorage.setItem(
                    "usersToken",
                    JSON.stringify(result.usersToken)
                );
                setConnectedUserEntity(result.data.uidEntity);
                setConnectedUser({
                    email: result.data.email,
                    firstName: result.data.firstName,
                });
                localStorage.setItem("EmailLocalUser", result.data.email);

                setLocalProject(project);
                setPagesItems(project.template.pages);
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(true);
                setLoginButton("Login");
            }
        })
        .catch((error: unknown) => {
            const tokens = JSON.parse(usersToken as string);
            if (tokens !== null && tokens[decodeURIComponent(encodedEmail)]) {
                const connectedUserToken =
                    tokens[decodeURIComponent(encodedEmail)];
                setConnectedUser({
                    email: decodeURIComponent(encodedEmail),
                    firstName: connectedUserToken.firstName,
                });
                setLocalProject(project);
                setPagesItems(project.template.pages);
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(true);
                setLoginButton("Login");
                console.log(
                    `Loged in CATCH ERROR with email ${encodedEmail} access token : ${JSON.stringify(
                        usersToken
                    )}`
                );
                return;
            }
            console.log(
                `Error while verifying custom account, with message ${JSON.stringify(
                    (error as Error).message
                )}`
            );
            setIsTechProblemModalOpened(true);
            setLoginButton("Login");
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}
export async function handleLoginSynchronizationButtonOnClickEvent(
    encodedEmail: string,
    encodedPassword: string,
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >,
    setLoginButton: React.Dispatch<React.SetStateAction<string>>
    // eslint-disable-next-line
): Promise<any> {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    const url = `${middlewareApiUrl}/verifyKioskAccount?timestamp=${timestamp}`;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsTechProblemModalOpened(true);
        setLoginButton("Login");
    }, 30000);
    await fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `email=${encodedEmail}&password=${encodedPassword}`,
        method: "POST",
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.error === true) {
                setIsErrorLogInModalWrapperOpened(true);
                setLoginButton("Login");
            } else {
                localStorage.setItem(
                    "connectedUser",
                    data["data"]["uidEntity"]
                );
                setConnectedUserEntity(data["data"]["uidEntity"]);
                setConnectedUser({
                    email: data["data"].email,
                    firstName: data["data"].firstName,
                });

                setIsEntitySelectionModalOpened(true);
                setIsAuthenticationModalOpened(false);
                setLoginKeyboardInputsValues({
                    email: "",
                    password: "",
                });
                setLoginButton("Login");
            }
        })
        .catch((error) => {
            console.log(
                `Error while verifying customer account, with message: ${JSON.stringify(
                    error.message
                )}`
            );
            setIsTechProblemModalOpened(true);
            setLoginButton("Login");
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}

import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    handleLedControl,
} from "@helpers/general";

import { kioskStore, setIsFenceProblemDetected } from "@pages/Kiosk/store";

export function TechnicalProblemModalWrapper(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isFenceProblemDetected,
        isLedActive,
        connectedLedPort,
    } = useSnapshot(kioskStore);

    const [counter, setCounter] = React.useState<number>(0);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    React.useEffect(() => {
        let timer: NodeJS.Timeout;

        if (isFenceProblemDetected === true && counter >= 0) {
            timer = setTimeout(() => setCounter(counter + 1), 1000);
        }
        if (counter === 10) {
            setIsFenceProblemDetected(false);
            if (isLedActive) {
                handleLedControl(connectedLedPort, "Green");
            }
            setCounter(0);
        }

        return () => clearTimeout(timer);
    }, [counter, isFenceProblemDetected]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isFenceProblemDetected}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Technical problem")}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("A fence problem is detected")}
                </span>
            </div>

            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <div
                    style={{
                        backgroundColor: "beige",
                        width: "500px",
                        height: "150px",
                        borderRadius: "20px",
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                    className="d-flex flex-column justify-content-center"
                >
                    <span>contact@support.com</span>
                    <span>01.12.34.56.78</span>
                </div>
            </div>
            <div style={{ height: "100px" }} />
            <div
                style={{ height: "120px" }}
                className="d-flex justify-content-center align-items-center"
            >
                <img
                    src="./images/back.png"
                    alt="action button"
                    onClick={() => {
                        setIsFenceProblemDetected(false);
                        if (isLedActive) {
                            handleLedControl(connectedLedPort, "Green");
                        }
                        setCounter(0);
                    }}
                />
            </div>
        </Modal>
    );
}

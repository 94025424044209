import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { ScrollContainer } from "@components/ScrollContainer";

import {
    setConfigInformationModes,
    kioskStore as store,
} from "@pages/Kiosk/store";

import { InformationModesCardWrapper } from "./InformationModesCardWrapper";
import { SalesMethodsSelectWrapper } from "./SalesMethodsSelectWrapper";

export function InformationModes(): JSX.Element {
    const { selectedSaleModeValue, localProject, activeTabTitle } =
        useSnapshot(store);

    const [mainContentInformationModes, setMainContentInformationModes] =
        React.useState<ProjectMainContentItemType[]>(
            (
                localProject.template.content
                    .informationModes as ProjectContentItemType
            ).items.filter((item: ProjectMainContentItemType) => {
                return (
                    localProject.template.content
                        .salesMethods as ProjectContentItemType
                ).items
                    .filter((saleMethod) => {
                        return saleMethod.name === selectedSaleModeValue;
                    })[0]
                    ?.informationModes?.items?.includes(item.id);
            })
        );

    React.useEffect(() => {
        if (selectedSaleModeValue !== "") {
            setMainContentInformationModes(
                (
                    localProject.template.content
                        .informationModes as ProjectContentItemType
                ).items.filter((item: ProjectMainContentItemType) => {
                    return (
                        localProject.template.content
                            .salesMethods as ProjectContentItemType
                    ).items
                        .filter((saleMethod) => {
                            return saleMethod.name === selectedSaleModeValue;
                        })[0]
                        .informationModes?.items?.includes(item.id);
                })
            );
        }
    }, [selectedSaleModeValue, localProject.template.content.informationModes]);

    return (
        <div className="d-flex flex-column" style={{ margin: 30 }}>
            <div
                style={{
                    font: "normal normal 600 37px/49px Segoe UI",
                    marginBottom: 30,
                    marginLeft: 23,
                }}
            >
                {activeTabTitle}
            </div>
            <SalesMethodsSelectWrapper />
            <ScrollContainer
                style={{
                    maxHeight: "880px",
                    overflowY: "auto",
                }}
            >
                <div
                    style={{
                        margin: "30px",
                        gap: "30px",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridTemplateRows: `repeat(${Math.round(
                            mainContentInformationModes.length / 2
                        )}, 1fr)`,
                    }}
                >
                    {mainContentInformationModes.map(
                        (
                            option: ProjectMainContentItemType,

                            index: number
                        ) => {
                            return (
                                <div key={option.id}>
                                    <span
                                        className="cursor__clz"
                                        style={{
                                            font: "normal normal normal 15px/20px Segoe UI",
                                            color: "#B2B2B2",
                                        }}
                                    >
                                        {t(
                                            (
                                                option as ProjectMainContentItemType
                                            ).name as string
                                        )}
                                    </span>
                                    <InformationModesCardWrapper
                                        item={option}
                                        index={index}
                                        key={index}
                                        setConfig={setConfigInformationModes}
                                        setItems={
                                            setMainContentInformationModes
                                        }
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </ScrollContainer>
        </div>
    );
}

import { BASIC_COMPOSITION_TYPE } from "@constants";
import { t } from "i18next";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { groupWorkflowShopCard } from "@components/OrderTaking/Helpers";
import { setIsCommentKeyboardActive } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { ShowCardProductMemo } from "./ShowCardProduct";

export default function WorkflowShopCarts() {
    const { customerLanguage } = useSnapshot(kioskStore);
    let workflowCards = {};
    const dispatchStore = useDispatch();
    const { isCommentProductActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const { shopCardMessage } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );

    const { workflowData } = useSelector((state) => state.workFlowDataSlice);

    if (workflowData.length === 1) {
        let data = {};
        Object.keys(orderWorkflowItemSlice.item.shopCart).forEach((key) => {
            if (key === BASIC_COMPOSITION_TYPE) {
                data = {
                    ...data,
                    [BASIC_COMPOSITION_TYPE]:
                        orderWorkflowItemSlice.item.shopCart[key],
                };
            } else {
                Object.keys(
                    orderWorkflowItemSlice.item.shopCart[key].compositions
                ).forEach((elmKey) => {
                    let newKey = elmKey;
                    if (data[elmKey] !== undefined) {
                        newKey =
                            elmKey +
                            "|" +
                            orderWorkflowItemSlice.item.shopCart[key]
                                .compositions[elmKey].name;
                    }
                    data = {
                        ...data,
                        [newKey]:
                            orderWorkflowItemSlice.item.shopCart[key]
                                .compositions[elmKey],
                    };
                });
            }
        });

        if (Object.keys(data).length > 0) {
            workflowCards = data;
        }
    } else {
        let listworkflow = workflowData[workflowData.length - 1];
        if (listworkflow) {
            if (listworkflow?.workflow[listworkflow.index]?.path) {
                let path = [];
                listworkflow?.workflow[listworkflow.index].path.forEach(
                    (elm) => {
                        path.push(elm.name);
                    }
                );
                let objectTostep = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, orderWorkflowItemSlice.item.shopCart);
                workflowCards = objectTostep;
            }
        }
    }

    const groupedItems = React.useMemo(
        () => groupWorkflowShopCard(workflowCards),
        [workflowCards]
    );

    const currentStepItems =
        workflowData[workflowData.length - 1]?.workflow[
            workflowData[workflowData.length - 1].index
        ].Items;

    function checkItemToCommentExist() {
        const result = { product: {}, isExist: false };
        if (
            !workflowData[workflowData.length - 1]?.workflow[
                workflowData[workflowData.length - 1].index
            ].haveComment ||
            workflowData[workflowData.length - 1]?.workflow[
                workflowData[workflowData.length - 1].index
            ].maxNbrOfChoices !== 1
        )
            return result;

        let listIuud = currentStepItems.map((item) => {
            return item.iuud;
        });

        groupedItems.forEach((item) => {
            if (listIuud.includes(item.iuud) === true) {
                result.isExist = true;
                result.product = item;
            }
        });
        return result;
    }

    const result = checkItemToCommentExist();

    const showCommentBar =
        isCommentProductActive === true && result.isExist === true;

    function handleAddCommentOnclickEvent() {
        dispatchStore(
            setIsCommentKeyboardActive({
                active: true,
                product: result.product,
            })
        );
    }
    const [localShopCartOpened, setLocalShopCartOpened] = React.useState(false);
    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center flex-column w-100"
                style={{ zIndex: "9999", position: "fixed", bottom: "80px" }}
            >
                <button
                    className={
                        localShopCartOpened
                            ? "workflow-shopcart-close-button"
                            : "cart-open-button"
                    }
                    onClick={() => setLocalShopCartOpened(!localShopCartOpened)}
                    style={{ alignSelf: "center" }}
                >
                    <span className="label-open-cart">{t("Basket")}</span>
                </button>
                {showCommentBar ? (
                    <div
                        style={{
                            background: "var(--global-color)",
                            height: "4vw",
                        }}
                        onClick={handleAddCommentOnclickEvent}
                        className="d-flex justify-content-between align-items-center px-4 w-100"
                    >
                        <span
                            style={{
                                font: "normal normal normal 35px/47px Segoe UI",
                            }}
                        >
                            {t("Comment")}
                        </span>
                        <IoIosArrowDown />
                    </div>
                ) : null}

                <div
                    className={`workflow-basket pt-1 collapse ${
                        localShopCartOpened ? "show" : ""
                    }`}
                >
                    {groupedItems.length > 0 ? (
                        <Swiper
                            slidesPerView={"auto"}
                            spaceBetween={10}
                            watchOverflow
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="shopcart-products-swiper-container swiper-pagination-bullet__clz"
                            style={{
                                width: "100% !important",
                                height: "100% !important",
                            }}
                        >
                            {groupedItems.reverse().map((item, index) => {
                                return (
                                    <SwiperSlide
                                        key={groupedItems.length - index}
                                        className="slide-popup"
                                    >
                                        <ShowCardProductMemo
                                            item={item}
                                            groupedItems={groupedItems}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    ) : (
                        <div
                            style={{ height: "100%" }}
                            className="d-flex justify-content-center d-flex align-items-center"
                        >
                            <h3>
                                {
                                    shopCardMessage.languages[
                                        customerLanguage.name
                                    ].message
                                }{" "}
                            </h3>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function CancelOrderPMR(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 275 65">
            <g
                id="Groupe_11"
                data-name="Groupe 11"
                transform="translate(248 4055)"
            >
                <g
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    transform="translate(-248 -4055)"
                    fill="#fff"
                    stroke="#8b8b8b"
                    strokeWidth="2"
                >
                    <rect
                        width="275"
                        height="65"
                        rx="9"
                        stroke="none"
                        fill="#fff"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#f8f8f6"
                    />
                </g>
                <text
                    id="Cancel_order"
                    transform="translate(-111 -4011)"
                    fill="#8b8b8b"
                    fontSize="22"
                    fontFamily="HelveticaNeue-Medium, Helvetica Neue"
                    fontWeight="500"
                    textAnchor="middle"
                >
                    {t("Cancel my order", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
}
CancelOrderPMR.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    handleLoginLocalSettingsButtonOnClickEvent,
    handleLoginSynchronizationButtonOnClickEvent,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import KioskKeyboard from "@components/common/KioskKeyboard";
import { HidePasswordSvgIcon } from "@components/SvgIcons/HidePasswordSvgIcon";
import { ShowPasswordSvgIcon } from "@components/SvgIcons/ShowPasswordSvgIcon";
import { setLoginKeyboardInputsValues } from "@components/Synchronization/store";

import "./index.css";

type UsualKeyboardWrapperPropsType = {
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setLoginButton: React.Dispatch<React.SetStateAction<string>>;
};

export function UsualKeyboardWrapper({
    setIsErrorLogInModalWrapperOpened,
    setLoginButton,
}: UsualKeyboardWrapperPropsType): JSX.Element {
    const { isAppSynchronized } = useSnapshot(store);
    const [inputs, setInputs] = React.useState<any>({
        email: "",
        password: "",
    });

    const [isPasswordDisplayed, setIsPasswordDisplayed] =
        React.useState<boolean>(false);
    const encodedEmail = encodeURIComponent(inputs.email);
    const encodedPassword = encodeURIComponent(inputs.password);
    const [inputName, setInputName] = React.useState("");
    const keyboard = React.useRef<any>();

    const onChangeAll = (inputs: any) => {
        setInputs({ ...inputs });
        setLoginKeyboardInputsValues({ ...inputs });
    };

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;
        setInputs({
            ...inputs,
            [inputName]: inputVal,
        });
        setLoginKeyboardInputsValues({
            ...inputs,
            [inputName]: inputVal,
        });
        keyboard.current.setInput(inputVal);
    };

    const getInputValue = (inputName: any) => {
        return inputs[inputName] || "";
    };

    return (
        <FlexboxGrid
            alignItemsCentered={true}
            justifyContentCentered={true}
            className="flex-column py-1"
            gap="10px"
        >
            <form
                className="p-4 d-flex flex-column justify-content-center align-items-center"
                style={{ gap: "15px" }}
            >
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        whiteSpace: "nowrap",
                        gap: "10px",
                    }}
                >
                    {t("ID")}
                    <div className="d-flex">
                        <input
                            id="email"
                            type="text"
                            value={getInputValue("email")}
                            onFocus={() => setInputName("email")}
                            placeholder={t("Enter your ID")}
                            onChange={onChangeInput}
                            className="usual-keyboard-password__clz px-3"
                        />
                    </div>
                </label>

                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        gap: "10px",
                    }}
                >
                    {t("Password")}
                    <div className="password-input-container">
                        <input
                            id="password"
                            value={getInputValue("password")}
                            onFocus={() => setInputName("password")}
                            type={isPasswordDisplayed ? "text" : "password"}
                            placeholder={t("Enter your password")}
                            onChange={onChangeInput}
                            className="usual-keyboard-password__clz px-3"
                        />
                        <div
                            className="password-eye-icon"
                            onClick={() =>
                                setIsPasswordDisplayed(
                                    (prevState) => !prevState
                                )
                            }
                        >
                            {isPasswordDisplayed ? (
                                <HidePasswordSvgIcon />
                            ) : (
                                <ShowPasswordSvgIcon />
                            )}
                        </div>
                    </div>
                </label>
            </form>

            <FlexboxGrid
                alignItemsCentered={true}
                justifyContentCentered={true}
                className="w-100"
            >
                <KioskKeyboard
                    enterClick={() => {
                        if (isAppSynchronized) {
                            handleLoginLocalSettingsButtonOnClickEvent(
                                encodedEmail,
                                encodedPassword,
                                setIsErrorLogInModalWrapperOpened,
                                setLoginButton
                            );
                        } else {
                            handleLoginSynchronizationButtonOnClickEvent(
                                encodedEmail,
                                encodedPassword,
                                setIsErrorLogInModalWrapperOpened,
                                setLoginButton
                            );
                        }
                        setLoginButton("Connecting");
                    }}
                    keyboard={keyboard}
                    onChangeAll={onChangeAll}
                    inputName={inputName}
                    theme={"keyboard__clz hg-theme-default"}
                    buttonTheme={[
                        {
                            class: "space-key__clz",
                            buttons: "{space}",
                        },
                    ]}
                />
            </FlexboxGrid>
        </FlexboxGrid>
    );
}

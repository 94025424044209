import { toast } from "react-toastify";

import "./Style.css";

export function ErrorToast(message: string, toastId: string): void {
    toast.error(message, {
        className: "responsive-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        theme: "colored",
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        toastId,
    });
}

export function informationToast(message: string): void {
    toast.info(message, {
        className: "responsive-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        theme: "dark",
        closeOnClick: true,
        hideProgressBar: false,
    });
}

export function successToast(message: string): void {
    toast.success(message, {
        className: "responsive-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        theme: "dark",
        closeOnClick: true,
        hideProgressBar: false,
    });
}

import { CATEGORY_TYPE, ETK_CESAR, TCPOS } from "@constants";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";
import { isEqual } from "lodash";
import { v4 as uuid } from "uuid";
import { snapshot } from "valtio";

import { store } from "@store";

import { kioskStore } from "@pages/Kiosk/store";

import { getAdditionnalSaleItems } from "../../Components/Products/ProductWrapper/hooks";
import {
    addItemCommandTcPos,
    clearTCPOSOrder,
    deletedInvisibleNewOrder,
    editSalesMode,
    getCard,
    getLoyaltyTotal,
    refreshTcPosOrder,
    removeItemCommandTcPos,
    reorderShopCart,
    updateCategoriesOutOfStock,
} from "../../Helpers";

const { posEditor } = snapshot(store);
const initialState = {
    user: {},
    globalcategorys: [],
    globalcard: {},
    globalAllergens: [],
    defaultAllergens: [],
    basicComposition: {},

    listProducts: [],
    OutOfstockItems: [],
    additionalSale: {
        name: "",
        listItems: [],
        selectedItems: [],
        parentId: "",
        design: { nbrRows: 2, nbrColumn: 2, isAutoDesign: true },
    },
    order: {
        orderItems: [],
        totalPrice: 0,
        itemsCount: 0,
        orderFidelity: 0,
    },
    orderCreate: { loading: true },
    activeCategory: "",
    activeCategoryParent: "",
    defaultCategory: "",
    quantity: 0,
    tags: [],
    defaultTags: [],
    catalogueIsLoading: true,
    fidelityTotal: 0,
    currentItemPriceOptions: {
        selectedOptions: [],
        newPrice: 0,
        tva: 0,
    },
    nestedCategories: [],
    isCommentKeyboardActive: false,
    productToComment: {},
    cardType: ETK_CESAR,
    isGenericAdditionalSaleActive: false,
    design: { nbrRows: 2, nbrColumn: 2, isAutoDesign: true },
    isBrokenCard: false,
};

export const orderSlice = createSlice({
    name: "orderSlice",
    initialState,
    reducers: {
        setActiveCategory: (state, action) => {
            state.activeCategory = action.payload;
            state.nestedCategories = [action.payload];
            state.activeCategoryParent =
                state.globalcard.categories[action.payload].categoryParent;
            state.design.isAutoDesign = true;
            state.design.nbrColumn = 2;
            state.design.nbrRows = 2;

            if (
                state.globalcard.categories[action.payload].design !==
                    undefined &&
                Object.keys(state.globalcard.categories[action.payload].design)
                    .length > 0
            ) {
                state.design.isAutoDesign =
                    state.globalcard.categories[
                        action.payload
                    ].design?.isAutoDesign;
                state.design.nbrColumn =
                    state.globalcard.categories[
                        action.payload
                    ].design.nbrColumn;
                state.design.nbrRows =
                    state.globalcard.categories[action.payload].design.nbrRows;
            }
        },

        setListProduct: (state, action) => {
            const { activeCategory } = action.payload;
            let localListProducts =
                state.globalcard.categories[activeCategory].listProduct;
            let activeTags = state.tags
                .filter((tag) => {
                    return tag.active === true;
                })
                .map((activeTag) => {
                    return activeTag.id;
                });
            state.listProducts = localListProducts.filter((product) => {
                return product?.linkedTags?.some((item) =>
                    activeTags.includes(item)
                );
            });
        },

        setActiveCategoryParent: (state, action) => {
            state.activeCategoryParent = action.payload;
            state.nestedCategories = [
                ...state.nestedCategories,
                action.payload,
            ];
            state.design.isAutoDesign = true;
            if (
                state.globalcard.categories[action.payload]?.design !==
                    undefined &&
                Object.keys(state.globalcard.categories[action.payload].design)
                    .length > 0
            ) {
                state.design.isAutoDesign =
                    state.globalcard.categories[
                        action.payload
                    ].design?.isAutoDesign;
                state.design.nbrColumn =
                    state.globalcard.categories[
                        action.payload
                    ].design.nbrColumn;
                state.design.nbrRows =
                    state.globalcard.categories[action.payload].design.nbrRows;
            }
        },

        addToOrder: (state, action) => {
            let newItem = action.payload;
            const arrangedShopCart = reorderShopCart(newItem.shopCart);

            newItem = { ...newItem, shopCart: arrangedShopCart };
            //! refactor with immer
            state.order = produce(state.order, (draftState) => {
                const existItem = draftState.orderItems.find(
                    (elm) =>
                        elm.iuud === newItem.iuud &&
                        isEqual(newItem.shopCart, elm.shopCart) &&
                        isEqual(newItem.selectedOptions, elm.selectedOptions)
                );
                if (existItem) {
                    existItem.quantity += 1;
                }

                const iuudOrder = uuid();

                const orderItems = existItem
                    ? draftState.orderItems.map((x) =>
                          x.iuudOrder === existItem.iuudOrder ? existItem : x
                      )
                    : [
                          ...draftState.orderItems,
                          { ...newItem, iuudOrder, quantity: 1 },
                      ];

                draftState.itemsCount = orderItems.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                );

                const itemsPrice = orderItems.reduce(
                    (a, c) => a + c.quantity * c.price,
                    0
                );

                draftState.totalPrice = Math.round(itemsPrice * 100) / 100;
                draftState.orderItems = orderItems;
                draftState.orderFidelity =
                    draftState.orderFidelity + action.payload.totalFidelity;
            });
            //! refactor end

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                })
            );
            if (action.payload?.haveAdditionalSale) {
                state.additionalSale = getAdditionnalSaleItems(
                    state.globalcard,
                    action.payload
                );
            }
        },
        addClickANdCollectOrder: (state, action) => {
            state.order = action.payload;
            state.order.orderFidelity = getLoyaltyTotal(
                action.payload.orderItems
            );
        },

        orderSalesModeModification: (state, { payload }) => {
            const { orderItems, totalPrice, itemsCount } = payload;
            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
            };
        },

        modifyOrder: (state, action) => {
            let newItem = action.payload;
            const arrangedShopCart = reorderShopCart(newItem.shopCart);

            newItem = { ...newItem, shopCart: arrangedShopCart };

            const orderItems = state.order.orderItems.map((x) =>
                x.iuudOrder === newItem.iuudOrder ? newItem : x
            );
            const orderFidelity = orderItems.reduce(
                (a, c) => a + c.totalFidelity,
                0
            );
            const itemsCount = orderItems.reduce(
                (prev, curr) => prev + curr.quantity,
                0
            );
            const itemsPrice = orderItems.reduce(
                (a, c) => a + c.quantity * c.price,
                0
            );

            const totalPrice = Math.round(itemsPrice * 100) / 100;

            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
                orderFidelity,
            };
        },
        addQuantityOrderItem: (state, action) => {
            state.order = produce(state.order, (draftState) => {
                const existItem = draftState.orderItems.find(
                    (elm) => elm.iuudOrder === action.payload.iuudOrder
                );
                if (existItem) {
                    existItem.quantity += 1;
                }

                const orderItems = draftState.orderItems.map((x) =>
                    x.iuudOrder === existItem.iuudOrder ? existItem : x
                );

                const itemsCount = orderItems.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                );
                const itemsPrice = orderItems.reduce(
                    (a, c) => a + c.quantity * c.price,
                    0
                );

                const totalPrice = Math.round(itemsPrice * 100) / 100;

                draftState.totalPrice = totalPrice;
                draftState.orderItems = orderItems;
                draftState.itemsCount = itemsCount;
                draftState.orderFidelity =
                    draftState.orderFidelity + action.payload.totalFidelity;
            });
            if (action.payload?.haveAdditionalSale) {
                state.additionalSale = getAdditionnalSaleItems(
                    state.globalcard,
                    action.payload
                );
            }
        },

        confirmSalesModeModification: (state, { payload }) => {
            const newOrders = deletedInvisibleNewOrder(
                state.order.orderItems,
                payload.settingKey
            );
            const { orderItems, totalPrice, itemsCount } = editSalesMode(
                newOrders,
                payload.id
            );
            const orderFidelity = orderItems.reduce(
                (a, c) => a + c.totalFidelity,
                0
            );

            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
                orderFidelity,
            };
        },

        removeFromOrder: (state, action) => {
            state.order = produce(state.order, (draftState) => {
                const existItem = draftState.orderItems.find(
                    (elm) => elm.iuudOrder === action.payload.iuudOrder
                );

                if (existItem.quantity > 1) {
                    existItem.quantity -= 1;
                    draftState.orderItems = draftState.orderItems.map((x) =>
                        x.iuudOrder === existItem.iuudOrder ? existItem : x
                    );
                } else {
                    draftState.orderItems = draftState.orderItems.filter(
                        (x) => x.iuudOrder !== existItem.iuudOrder
                    );
                }

                draftState.itemsCount = draftState.orderItems.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                );
                const itemsPrice = draftState.orderItems.reduce(
                    (a, c) => a + c.quantity * c.price,
                    0
                );

                draftState.totalPrice = Math.round(itemsPrice * 100) / 100;
                draftState.orderFidelity =
                    draftState.orderFidelity - action.payload.totalFidelity;
            });

            // let orderItems = [
            //     ...JSON.parse(JSON.stringify(state.order.orderItems)),
            // ];
            // const itemTodelete = orderItems.filter(
            //     (orderItem) => orderItem.iuudOrder === action.payload.iuudOrder
            // )[0];
            // if (itemTodelete.quantity > 1) {
            //     const newItem = {
            //         ...action.payload,
            //         quantity: +itemTodelete.quantity - 1,
            //     };
            //     orderItems = orderItems.map((x) =>
            //         x.iuudOrder === action.payload.iuudOrder ? newItem : x
            //     );
            // } else {
            //     orderItems = orderItems.filter(
            //         (x) => x.iuudOrder !== action.payload.iuudOrder
            //     );
            // }

            // const itemsCount = orderItems.reduce((a, c) => a + c.quantity, 0);
            // const itemsPrice = orderItems.reduce(
            //     (a, c) => a + c.quantity * c.price,
            //     0
            // );
            // const totalPrice = Math.round(itemsPrice * 100) / 100;

            // state.order = {
            //     orderItems,
            //     totalPrice,
            //     itemsCount,
            // };
        },

        clearOrder: (state) => {
            state.order = {
                orderItems: [],
                totalPrice: 0,
                itemsCount: 0,
                orderFidelity: 0,
            };
            state.activeCategory = state.defaultCategory;
            state.nestedCategories = [state.defaultCategory];
            state.activeCategoryParent = state.defaultCategory;
            state.globalAllergens = state.defaultAllergens;
            state.tags = state.defaultTags;
            state.fidelityTotal = 0;
            state.additionalSale = {
                name: "",
                listItems: [],
                selectedItems: [],
                parentId: "",
                design: { nbrRows: 2, nbrColumn: 2, isAutoDesign: true },
            };

            state.isCommentKeyboardActive = false;
            state.productToComment = {};
            state.design.isAutoDesign = true;
            state.design.nbrColumn = 2;
            state.design.nbrRows = 2;
            if (
                state?.globalcard?.categories[state.defaultCategory]?.design !==
                    undefined &&
                Object.keys(
                    state.globalcard.categories[state.defaultCategory].design
                ).length > 0
            ) {
                state.design.isAutoDesign =
                    state.globalcard.categories[
                        state.defaultCategory
                    ].design?.isAutoDesign;
                state.design.nbrColumn =
                    state.globalcard.categories[
                        state.defaultCategory
                    ].design.nbrColumn;
                state.design.nbrRows =
                    state.globalcard.categories[
                        state.defaultCategory
                    ].design.nbrRows;
            }
        },

        setAdditionalItems: (state, action) => {
            state.additionalSale = action.payload;
        },
        setOutOfstockItems: (state, action) => {
            state.OutOfstockItems = action.payload;
        },
        selectedAdditionalItems: (state, action) => {
            let newItem = action.payload;
            let existItem = null;
            let indexExistItem = -1;
            if (posEditor !== TCPOS) {
                state.additionalSale.selectedItems.forEach((elm, index) => {
                    if (
                        elm.iuud === newItem.iuud &&
                        isEqual(newItem.shopCart, elm.shopCart) &&
                        isEqual(newItem.selectedOptions, elm.selectedOptions)
                    ) {
                        existItem = elm;
                        indexExistItem = index;
                    }
                });
            }

            if (existItem) {
                existItem.quantity += 1;
                existItem.iuudOrder = uuid();
                state.additionalSale.selectedItems[indexExistItem] = existItem;
            } else {
                state.additionalSale.selectedItems.push({
                    ...newItem,
                    quantity: 1,
                    iuudOrder: uuid(),
                });
            }
        },
        removeFromSelectedAdditionalItems: (state, action) => {
            let newItem = action.payload;
            let selectedItems = state.additionalSale.selectedItems;

            if (TCPOS === posEditor) {
                const indexItem = selectedItems.findIndex(
                    (x) => x.iuud === newItem.iuud
                );

                selectedItems.splice(indexItem, 1);
            } else {
                const existItem = state.additionalSale.selectedItems.find(
                    (elm) =>
                        elm.iuud === newItem.iuud &&
                        isEqual(newItem.shopCart, elm.shopCart)
                );
                if (existItem) {
                    existItem.quantity -= 1;
                }

                selectedItems =
                    existItem.quantity > 0
                        ? state.additionalSale.selectedItems.map((x) =>
                              x.iuud === existItem.iuud ? existItem : x
                          )
                        : state.additionalSale.selectedItems.filter(
                              (x) => x.iuud !== existItem.iuud
                          );
            }

            state.additionalSale = { ...state.additionalSale, selectedItems };
        },
        validateAdditionnalItems: (state, action) => {
            let orderItems = [...state.order.orderItems];

            action.payload.forEach((additionalItem) => {
                let existItem = null;
                let indexExistItem = -1;

                if (posEditor !== TCPOS) {
                    orderItems.forEach((orderItem, index) => {
                        if (
                            orderItem.iuud === additionalItem.iuud &&
                            isEqual(
                                additionalItem.shopCart,
                                orderItem.shopCart
                            ) &&
                            isEqual(
                                additionalItem.selectedOptions,
                                orderItem.selectedOptions
                            )
                        ) {
                            existItem = orderItem;
                            indexExistItem = index;
                        }
                    });
                }

                if (existItem) {
                    existItem.quantity += additionalItem.quantity;
                    orderItems[indexExistItem] = existItem;
                } else {
                    orderItems.push({
                        ...additionalItem,
                        quantity: additionalItem.quantity,
                    });
                }

                const itemsCount = orderItems.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                );

                const itemsPrice = orderItems.reduce(
                    (a, c) => a + c.quantity * c.price,
                    0
                );
                const totalPrice = Math.round(itemsPrice * 100) / 100;
                let sumFidelity = 0;

                sumFidelity = orderItems.reduce((prev, curr) => {
                    return prev + curr.fidelity;
                }, 0);

                state.order = {
                    orderItems,
                    totalPrice,
                    itemsCount,
                    orderFidelity: sumFidelity,
                };
            });
        },

        setTags(state, action) {
            state.tags = action.payload;
        },
        setGlobalAllergens: (state, action) => {
            state.globalAllergens = action.payload;
        },
        setCarte: (state, { payload }) => {
            const { selectedSaleModeOrderTaking } = snapshot(kioskStore);
            let salesModeActiveCategory = selectedSaleModeOrderTaking
                ? payload.globalCategory.filter(
                      (cat) => cat.KioskVisibility[selectedSaleModeOrderTaking]
                  )
                : [];

            let firstActiveCategory =
                selectedSaleModeOrderTaking &&
                salesModeActiveCategory.length > 0
                    ? salesModeActiveCategory[0].iuud
                    : payload.CategoryActive;

            if (
                payload.globalCard.categories[firstActiveCategory].design !==
                    undefined &&
                Object.keys(
                    payload.globalCard.categories[firstActiveCategory].design
                ).length > 0
            ) {
                state.design.isAutoDesign =
                    payload.globalCard.categories[
                        firstActiveCategory
                    ].design?.isAutoDesign;
                state.design.nbrColumn =
                    payload.globalCard.categories[
                        firstActiveCategory
                    ].design.nbrColumn;
                state.design.nbrRows =
                    payload.globalCard.categories[
                        firstActiveCategory
                    ].design.nbrRows;
            }
            state.globalcard = payload.globalCard;
            state.globalcategorys = payload.globalCategory;
            state.activeCategory = firstActiveCategory;
            state.defaultCategory = firstActiveCategory;
            state.defaultAllergens = payload.globalAllergens;
            state.activeCategoryParent = "";
            state.nestedCategories = [firstActiveCategory];
            state.listProducts =
                payload.globalCard.categories[firstActiveCategory].listProduct;
            state.tags = payload.tags;
            state.defaultTags = payload.tags;
            state.globalAllergens = payload.globalAllergens;
            state.basicComposition = payload.basicComposition;
            state.catalogueIsLoading = false;
            state.cardType = payload.globalCard.cardType
                ? payload.globalCard.cardType
                : ETK_CESAR;
            state.isGenericAdditionalSaleActive =
                payload.globalCard.modifier.genericSalesAdd !== undefined;
        },
        setLoyaltyTotal: (state, action) => {
            state.fidelityTotal = getLoyaltyTotal(action.payload);
        },

        setNewItemProperties: (state, action) => {
            state.currentItemPriceOptions = action.payload;
        },

        clearNewItemProperties: (state) => {
            state.currentItemPriceOptions.selectedOptions = [];
            state.currentItemPriceOptions.newPrice = 0;
            state.currentItemPriceOptions.tva = 0;
        },
        setNestedCategories(state, action) {
            state.nestedCategories = [
                ...state.nestedCategories,
                action.payload,
            ];
        },
        updateNestedCategories(state, action) {
            const newList = [...state.nestedCategories];

            newList.length = state.nestedCategories.indexOf(action.payload) + 1;

            state.nestedCategories = newList;

            state.activeCategoryParent = newList[newList.length - 1];
            state.design.isAutoDesign = true;
            state.design.nbrColumn = 2;
            state.design.nbrRows = 2;
            if (
                state.globalcard.categories[state.activeCategoryParent]
                    .design !== undefined &&
                Object.keys(
                    state.globalcard.categories[state.activeCategoryParent]
                        .design
                ).length > 0
            ) {
                state.design.isAutoDesign =
                    state.globalcard.categories[
                        state.activeCategoryParent
                    ].design?.isAutoDesign;
                state.design.nbrColumn =
                    state.globalcard.categories[
                        state.activeCategoryParent
                    ].design.nbrColumn;
                state.design.nbrRows =
                    state.globalcard.categories[
                        state.activeCategoryParent
                    ].design.nbrRows;
            }
        },
        clearNestedCategories: (state) => {
            state.nestedCategories = [state.activeCategory];
        },
        setDefaultCategory: (state, action) => {
            state.defaultCategory = action.payload;
            state.nestedCategories = [action.payload];
            state.activeCategory = action.payload;
        },
        setIsCommentKeyboardActive: (state, action) => {
            let { active, product } = action.payload;
            state.isCommentKeyboardActive = active;
            state.productToComment = product;
        },
        setOutOfStockItems: (state, { payload }) => {
            payload.forEach((elm) => {
                const [[key, value]] = Object.entries(elm);
                if (state.globalcard.items[key] !== undefined) {
                    state.globalcard.items[key].outOfStock = value;
                }
                Object.values(state.globalcard.categories).map((category) => {
                    updateCategoriesOutOfStock(
                        category,
                        state.globalcard.items,
                        value,
                        key,
                        state.globalcard.categories
                    );
                });
            });
        },
    },

    extraReducers: {
        [getCard.pending]: (state) => {
            state.catalogueIsLoading = true;
        },
        [getCard.fulfilled]: (state, { payload }) => {
            if (Object.keys(payload).length === 0) {
                state.catalogueIsLoading = false;
                state.isBrokenCard = true;
                return;
            }
            if (
                payload.globalCard.categories[payload.CategoryActive].design !==
                    undefined &&
                Object.keys(
                    payload.globalCard.categories[payload.CategoryActive].design
                ).length > 0
            ) {
                state.design.isAutoDesign = state.design.nbrColumn =
                    payload.globalCard.categories[
                        payload.CategoryActive
                    ].design?.isAutoDesign;
                state.design.nbrColumn =
                    payload.globalCard.categories[
                        payload.CategoryActive
                    ].design.nbrColumn;
                state.design.nbrRows =
                    payload.globalCard.categories[
                        payload.CategoryActive
                    ].design.nbrRows;
            }
            state.globalcard = payload.globalCard;
            state.globalcategorys = payload.globalCategory;
            state.activeCategory = payload.CategoryActive;
            state.defaultCategory = payload.CategoryActive;
            state.defaultAllergens = payload.globalAllergens;
            state.nestedCategories = [payload.CategoryActive];
            state.defaultTags = payload.tags;
            state.design = { nbrRows: 2, nbrColumn: 2, isAutoDesign: true };
            state.listProducts =
                payload.globalCard.categories[
                    payload.CategoryActive
                ].listProduct;
            state.tags = payload.tags;
            state.globalAllergens = payload.globalAllergens;
            state.basicComposition = payload.basicComposition;
            state.cardType = payload.globalCard.cardType
                ? payload.globalCard.cardType
                : ETK_CESAR;
            state.isGenericAdditionalSaleActive =
                payload.globalCard.modifier.genericSalesAdd !== undefined;
            state.catalogueIsLoading = false;
            state.isBrokenCard = false;
        },
        [getCard.rejected]: (state) => {
            state.catalogueIsLoading = false;
        },
        [addItemCommandTcPos.fulfilled]: (state, { payload }) => {
            const order = payload?.order;
            const lastAddedItem = payload?.lastAddedItem;

            if (Array.isArray(order?.orderItems)) {
                state.order = order;
            }

            if (Array.isArray(payload?.outOfStock)) {
                payload?.outOfStock.forEach((elm) => {
                    const [[key, value]] = Object.entries(elm);
                    Object.values(state.globalcard.categories).map(
                        (category) => {
                            updateCategoriesOutOfStock(
                                category,
                                state.globalcard.items,
                                value,
                                key,
                                state.globalcard.categories
                            );
                        }
                    );
                });
            }

            if (
                lastAddedItem?.haveAdditionalSale &&
                lastAddedItem.isModification === false
            ) {
                state.additionalSale = getAdditionnalSaleItems(
                    state.globalcard,
                    lastAddedItem
                );
            }
        },
        [removeItemCommandTcPos.fulfilled]: (state, { payload }) => {
            if (Array.isArray(payload.order?.orderItems)) {
                state.order = payload.order;
            }
            if (Array.isArray(payload?.outOfStock)) {
                payload?.outOfStock.forEach((elm) => {
                    const [[key, value]] = Object.entries(elm);
                    Object.values(state.globalcard.categories).map(
                        (category) => {
                            updateCategoriesOutOfStock(
                                category,
                                state.globalcard.items,
                                value,
                                key,
                                state.globalcard.categories
                            );
                        }
                    );
                });
            }
        },
        [refreshTcPosOrder.fulfilled]: (state, { payload }) => {
            if (Array.isArray(payload?.orderItems)) {
                state.order = payload;
            }
        },
        [clearTCPOSOrder.fulfilled]: (state, { payload }) => {
            state.order = {
                orderItems: [],
                totalPrice: 0,
                itemsCount: 0,
                orderFidelity: 0,
            };
            state.activeCategory = state.defaultCategory;
            state.nestedCategories = [state.defaultCategory];
            state.activeCategoryParent = state.defaultCategory;
            state.globalAllergens = state.defaultAllergens;
            state.tags = state.defaultTags;
            state.fidelityTotal = 0;
            state.additionalSale = {
                name: "",
                listItems: [],
                selectedItems: [],
                parentId: "",
            };
            if (Array.isArray(payload?.outOfStock)) {
                payload?.outOfStock.forEach((elm) => {
                    const [[key, value]] = Object.entries(elm);
                    Object.values(state.globalcard.categories).map(
                        (category) => {
                            updateCategoriesOutOfStock(
                                category,
                                state.globalcard.items,
                                value,
                                key,
                                state.globalcard.categories
                            );
                        }
                    );
                });
            }
        },
        [clearTCPOSOrder.rejected]: (state, { payload }) => {
            state.order = {
                orderItems: [],
                totalPrice: 0,
                itemsCount: 0,
            };
            state.activeCategory = state.defaultCategory;
            state.nestedCategories = [state.defaultCategory];
            state.activeCategoryParent = state.defaultCategory;
            state.globalAllergens = state.defaultAllergens;
            state.tags = state.defaultTags;
            state.fidelityTotal = 0;
            state.additionalSale = {
                name: "",
                listItems: [],
                selectedItems: [],
                parentId: "",
            };
            if (Array.isArray(payload?.outOfStock)) {
                payload?.outOfStock.forEach((elm) => {
                    const [[key, value]] = Object.entries(elm);
                    Object.values(state.globalcard.categories).map(
                        (category) => {
                            updateCategoriesOutOfStock(
                                category,
                                state.globalcard.items,
                                value,
                                key,
                                state.globalcard.categories
                            );
                        }
                    );
                });
            }
        },
    },
});

const getCategories = (state) => state.orderSlice.globalcategorys;
const getActiveCategory = (state) => state.orderSlice.activeCategory;
const getActiveCategoryParent = (state) =>
    state.orderSlice.activeCategoryParent;
const getOrderItems = (state) => state.orderSlice.order.orderItems;
const getStoreTags = (state) => state.orderSlice.tags;
const getGlobalcard = (state) => state.orderSlice.globalcard;
const getGlobalAllergens = (state) => state.orderSlice.globalAllergens;
const getNestedCategories = (state) => state.orderSlice.nestedCategories;
export const memoizedSelectedProducts = createSelector(
    [
        getActiveCategory,
        getActiveCategoryParent,
        getStoreTags,
        getCategories,
        getGlobalcard,
    ],
    (
        activeCategory,
        activeCategoryParent,
        tags,
        _globalcategorys,
        globalcard
    ) => {
        const selectCategoryIuud = activeCategoryParent
            ? activeCategoryParent
            : activeCategory;
        let localListProducts =
            globalcard.categories[selectCategoryIuud]?.listProduct;

        const selectedCategory = globalcard.categories[selectCategoryIuud];

        let activeTags = tags
            .filter((tag) => {
                return (
                    tag.active === true &&
                    selectedCategory?.tagList.includes(tag.id)
                );
            })
            .map((activeTag) => {
                return activeTag.id;
            });

        return activeTags.length > 0
            ? localListProducts.filter((product) => {
                  return product.type === CATEGORY_TYPE &&
                      product?.isRedirect === false
                      ? true
                      : product.linkedTags?.some((item) =>
                            activeTags.includes(item)
                        );
              })
            : localListProducts;
    }
);

export const memoizedTags = createSelector(
    [
        getStoreTags,
        getActiveCategory,
        getActiveCategoryParent,
        getCategories,
        getGlobalcard,
    ],
    (
        tags,
        activeCategoryParent,
        activeCategory,
        globalcategorys,
        globalcard
    ) => {
        const selectCategoryIuud =
            activeCategory === "" ? activeCategoryParent : activeCategory;
        const selectedCategory = globalcategorys?.find(
            (cat) => cat.iuud === selectCategoryIuud
        );
        const selectedCategoryForTag =
            selectedCategory === undefined
                ? globalcard.categories[selectCategoryIuud].tagList
                : selectedCategory.tagsItems;
        const visibleTags = tags.reduce((prev, curr) => {
            let currentTag = { ...curr, isVisible: false };
            if (selectedCategoryForTag?.includes(currentTag.id)) {
                currentTag = { ...curr, isVisible: true };
            }
            return [...prev, currentTag];
        }, []);
        return visibleTags;
    }
);

export const memoizedActiveCategory = createSelector(
    [getNestedCategories],
    (nestedCategories) => nestedCategories[0]
);
export const memoizedOrderItems = createSelector(
    [getOrderItems],
    (orderItems) => orderItems
);

export const memoizedCategories = createSelector(
    [getCategories],
    (globalcategorys) => globalcategorys
);

export const memoizedActiveCategoryUid = createSelector(
    [getActiveCategory],
    (activeCategory) => activeCategory
);

export const memoizedGlobalAllergens = createSelector(
    [getGlobalAllergens],
    (globalAllergens) => globalAllergens
);

export const {
    order,
    setActiveCategory,
    setListProduct,
    addToOrder,
    removeFromOrder,
    clearOrder,
    setActiveCategoryParent,
    setAdditionalItems,
    selectedAdditionalItems,
    validateAdditionnalItems,
    setTags,
    setGlobalAllergens,
    setBasicComposition,
    setCarte,
    modifyOrder,
    setLoyaltyTotal,
    setNewItemProperties,
    clearNewItemProperties,
    setNestedCategories,
    clearNestedCategories,
    updateNestedCategories,
    orderSalesModeModification,
    removeFromSelectedAdditionalItems,
    addQuantityOrderItem,
    setDefaultCategory,
    confirmSalesModeModification,
    setIsCommentKeyboardActive,
    addClickANdCollectOrder,
    setOutOfStockItems,
} = orderSlice.actions;

export default orderSlice.reducer;

import { TCPOS } from "@constants";
import moment from "moment";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { store } from "@store";

import {
    changeLanguage,
    configCashMachine,
    initCardReader,
} from "@helpers/general";

import { LoadingModalWrapper } from "@components/LocalSettings/LocalConfigModalWrapper/Tcpos/LoadingModalWrapper";
import { ScanQrCodeModalWrapper } from "@components/LocalSettings/ScanQrCodeModalWrapper";
import { UsersListModalWrapper } from "@components/LocalSettings/UsersListModalWrapper";
import { clearTCPOSOrder } from "@components/OrderTaking/Helpers";
import {
    clearMiddlwareOrder,
    clearOrder,
} from "@components/OrderTaking/Store/feature";
import { EntitySelectionModalWrapper } from "@components/Synchronization/EntitySelectionModalWrapper";
import { syncStore } from "@components/Synchronization/store";

import { PausedAppSwiperWrapper } from "@pages/Kiosk/Steps/StandbyStep/PausedAppSwiperWrapper";
import { ProdAppSwiperWrapper } from "@pages/Kiosk/Steps/StandbyStep/ProdAppSwiperWrapper";
import {
    initStoreVariablesInStandbyScreenStep,
    kioskStore,
    setActiveStandbyScreenItems,
    setCustomerLanguage,
    setIsAuthenticationModalOpened,
    setIsOrderTakingPreviousStep,
    setStandbyScreenItems,
} from "@pages/Kiosk/store";

import "./index.css";

export function StandbyStep(): JSX.Element {
    const { posEditor, isAppPaused } = useSnapshot(store);
    const {
        isUsersListModalOpened,
        isScanQrCodeModalOpened,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        isLogout,
        isCashMachineConfigured,
        isFenceProblemDetected,
        changeMachineConfigurationDate,
        isLoadingModalOpened,
    } = useSnapshot(kioskStore);

    const {
        selectedShopApp,
        machineIpAddress,
        isEntitySelectionModalOpened,
    } = useSnapshot(syncStore);

    const dispatch = useDispatch();

    React.useEffect(() => {
        initStoreVariablesInStandbyScreenStep();
        setCustomerLanguage(
            (content.languages as LanguagesType).items.filter(
                (language) => language.isDefault === true
            )[0]
        );
        changeLanguage(
            (content.languages as LanguagesType).items.filter(
                (language) => language.isDefault === true
            )[0].name
        );

        if (TCPOS === posEditor) {
            dispatch(clearTCPOSOrder());
        } else {
            dispatch(clearOrder());
            dispatch(clearMiddlwareOrder());
        }

        setIsOrderTakingPreviousStep(false);
    }, []);

    React.useEffect(() => {
        const creditCardPaymentMode = (
            content.meansOfPayment as ProjectContentItemType
        ).items.filter((item) => item.name === "By credit card");
        if (
            isLogout === false &&
            creditCardPaymentMode.length > 0 &&
            creditCardPaymentMode[0].active === true
        ) {
            initCardReader(machineIpAddress);
        }

        const currentDate = moment(new Date()).format("YYYY-MM-DD");
        if (
            isCashMachineConfigured === false ||
            (changeMachineConfigurationDate !== "" &&
                changeMachineConfigurationDate !== currentDate)
        ) {
            if (isCashMachineConfigured === false) {
                console.log(
                    "The cash machine is in standby mode because the first configuration request failed!!!"
                );
            }
            if (changeMachineConfigurationDate !== currentDate) {
                console.log(
                    "The cash machine configuration in the standby step due to a change in date!!!"
                );
            }
        }

        if (isCashMachineConfigured === false && !(TCPOS === posEditor)) {
            const ipCashMachine = (
                Object.values(
                    selectedShopApp.cash_managements as ObjectType
                )[0] as ObjectType
            )?.ip as string;

            configCashMachine(ipCashMachine);
        }
    }, [
        isLogout,
        isCashMachineConfigured,
        selectedShopApp,
        changeMachineConfigurationDate,
        content,
    ]);

    React.useEffect(() => {
        setActiveStandbyScreenItems(
            (ways.standbyScreen as StandbyScreenPageType).items.filter(
                (item) => item.active === true
            )
        );
        setStandbyScreenItems(
            (
                (content.standbyStateScreen as StandbyStateScreenItemsType)
                    .items as StandbyScreenItemType[]
            ).filter((item) => item.active === true)
        );
    }, [content.standbyStateScreen, ways.standbyScreen]);

    return (
        <div>
            {isUsersListModalOpened === true ? <UsersListModalWrapper /> : null}
            {isScanQrCodeModalOpened === true ? (
                <ScanQrCodeModalWrapper />
            ) : null}
            {isEntitySelectionModalOpened === true ? (
                <EntitySelectionModalWrapper />
            ) : null}
            {isLoadingModalOpened === true ? <LoadingModalWrapper /> : null}
            {isAppPaused === true ? (
                <PausedAppSwiperWrapper />
            ) : (
                <ProdAppSwiperWrapper />
            )}
            <div
                style={{
                    position: "fixed",
                    zIndex: isFenceProblemDetected ? 3 : 2,
                    bottom: "0px",
                    height: "45px",
                    width: "45px",
                    cursor: "pointer",
                }}
                onClick={() => {
                    setIsAuthenticationModalOpened(true);
                }}
            />
        </div>
    );
}

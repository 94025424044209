import {
    ACCOUNT_OPTION,
    ASK_FOR_HELP_BUTTON,
    CONNECT_USER_OPTION,
    DELIVERY_MODE,
    PRM_BUTTON,
    USUAL_MODE,
} from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import shortid from "shortid";
import { useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import { Tooltip } from "reactstrap";

import { useOutsideClick } from "@components/OrderTaking/common/OrderTakingChangeSalesMode/useOutsideClick";
import AllergensDialog from "@components/OrderTaking/Components/Dialogs/Allergen/AllergensDialog";

import {
    kioskStore,
    setAuthModalStepIndex,
    setIsHelpModalOpened,
    setIsLogoutProblemModalOpened,
    setIsPrm,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setUser,
} from "@pages/Kiosk/store";

import { MenuIcon } from "../Icons/MenuIcon";
import { ConfigOptionItem } from "./ConfigOptionItem";
import "./index.css";

export function IconsMenu({ isOpened, setIsMenuOpened, newOptionConfig }) {
    const {
        navigationIndex,
        isUserAuthenticated,
        isPrm,
        user,
        selectedSaleModeValue,
    } = useSnapshot(kioskStore);

    const dropdownRef = React.useRef(null);

    const [isAllergenDialogOpened, setIsAllergenDialogOpened] =
        React.useState(false);
    const [isLogoutButtonClicked, setIsLogoutButtonClicked] =
        React.useState(false);
    const handleAllergenDialogOnCLickEvent = () => {
        setIsAllergenDialogOpened(!isAllergenDialogOpened);
    };
    const localConfigOptions = !isUserAuthenticated
        ? newOptionConfig
        : newOptionConfig.filter((config) => config.title !== ACCOUNT_OPTION);
    const ref = useOutsideClick(setIsLogoutButtonClicked);

    function handleConfigOptionOnClickEvent(option) {
        switch (option.title.toLowerCase()) {
            case PRM_BUTTON:
                setIsPrm(!isPrm);
                break;

            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;
            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);

                break;
            case CONNECT_USER_OPTION:
                if (isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true),
                        setAuthModalStepIndex(navigationIndex);
                }
                break;
            case "allergen":
                setIsAllergenDialogOpened(true);
                break;

            default:
                null;
        }
    }
    const isUserAccountExist = newOptionConfig.filter(
        (item) => item.title === ACCOUNT_OPTION
    )[0];

    return (
        <>
            <AllergensDialog
                open={isAllergenDialogOpened}
                onClose={handleAllergenDialogOnCLickEvent}
            />

            <div className="pmr-active-dropdown" ref={dropdownRef}>
                <div className="menu pt-2" style={{ gap: "5px" }}>
                    {isLogoutButtonClicked ? (
                        <Tooltip
                            className="order-taking-tooltip"
                            placement="left-center"
                            isOpen={isLogoutButtonClicked}
                            target={"Tooltip"}
                            onClick={() => {
                                if (selectedSaleModeValue === DELIVERY_MODE) {
                                    setIsLogoutProblemModalOpened(true);
                                    setIsLogoutButtonClicked(false);
                                } else {
                                    setIsUserAuthenticated(false);
                                    setIsLogoutButtonClicked(false);
                                    setUser({
                                        firstName: "",
                                        lastName: "",
                                        phoneNumber: "",
                                        email: "",
                                        loyaltyAccount: 0,
                                        loyaltyAmount: 0,
                                    });
                                }
                            }}
                            style={{
                                cursor: "pointer",
                                height: "50px",
                                borderRadius: "5px",
                            }}
                        >
                            <span
                                style={{ font: "normal 26px/30px Segoe UI" }}
                                ref={ref}
                            >
                                {t("Sign out")}
                            </span>
                        </Tooltip>
                    ) : null}
                    {isUserAuthenticated && isUserAccountExist !== undefined ? (
                        <div id="Tooltip">
                            <Avatar
                                email={user.email}
                                name={user.lastName}
                                size="65px"
                                round="10px"
                                onClick={() =>
                                    setIsLogoutButtonClicked(
                                        !isLogoutButtonClicked
                                    )
                                }
                            />
                        </div>
                    ) : null}
                    {localConfigOptions
                        .filter(
                            (option) =>
                                option.title.toLowerCase() !== "prm/child" &&
                                option.title.toLowerCase() !== "usual"
                        )
                        .map((option) => {
                            return (
                                <ConfigOptionItem
                                    option={option}
                                    key={shortid.generate()}
                                    handleConfigOptionOnClickEvent={
                                        handleConfigOptionOnClickEvent
                                    }
                                />
                            );
                        })}

                    {isOpened ? (
                        <div
                            onClick={() => {
                                setIsMenuOpened(!isOpened);
                            }}
                        >
                            <MenuIcon />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}

IconsMenu.propTypes = {
    isOpened: PropTypes.bool,
    setIsMenuOpened: PropTypes.func,
    newOptionConfig: PropTypes.array,
};

import { StyledButton, StyledModal } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useNavigate } from "react-router";
import { ModalBody } from "reactstrap";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { kioskStore } from "@pages/Kiosk/store";

export function ErrorPage(): JSX.Element {
    const navigate = useNavigate();
    const [isTechProblemModalOpened, setisTechProblemModalOpened] =
        React.useState(true);
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);
    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    return (
        <StyledModal
            className="tech-problem-modal__clz"
            centered={true}
            isOpen={isTechProblemModalOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                <FlexboxGrid
                    justifyContentCentered={true}
                    alignItemsCentered={true}
                    gap="100px"
                    className="flex-column"
                >
                    <img
                        alt=""
                        src={alertImgSrc}
                        style={{
                            height: "124px",
                            width: "144px",
                            marginTop: "30px",
                        }}
                    />
                    <h2
                        className="text-uppercase text-center"
                        style={{
                            font: "normal normal bold 50px/55px Bebas Neue",
                            letterSpacing: "2px",
                            color: "#171717",
                        }}
                    >
                        {t("Technical problem")}
                    </h2>
                </FlexboxGrid>
                <div
                    className="d-flex flex-column align-items-center px-5"
                    style={{
                        font: "normal normal 300 30px/42px Segoe UI",
                        letterSpacing: "0.17px",
                        textAlign: "center",
                    }}
                >
                    {t("Please contact technical support")}
                </div>
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                >
                    <StyledButton
                        rounded={true}
                        variant="success"
                        onClick={() => {
                            setisTechProblemModalOpened(false);
                            navigate("/");
                            location.reload();
                        }}
                    >
                        {t("Relaunch kiosk app")}
                    </StyledButton>
                </FlexboxGrid>
            </ModalBody>
        </StyledModal>
    );
}

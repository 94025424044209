import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE, TCPOS, VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { store } from "@store";

import {
    getAlertActionButtonImageSrc,
    getGiveUpActionButtonImageSrc,
    getImageContentById,
    getOtherPaymentMethodButtonImageSrc,
} from "@helpers/general";

import { validateTCPOSCommand } from "@components/OrderTaking/Helpers/TCPOS";
import { TryAgain } from "@components/SvgIcons";

import {
    kioskStore,
    setIsAbortTransactionModalOpened,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsPaymentRefused,
    setIsRegulationModesModalOpened,
    setIsTpeNotAvailable,
    setNavigationIndex,
    setRegulationModesModalKey,
} from "@pages/Kiosk/store";

export function TpeAvailablityModal(): JSX.Element {
    const { posEditor } = useSnapshot(store);
    const {
        isPrm,
        project: {
            template: {
                content: { meansOfPayment, generalDesign, actionsButtons },
            },
        },
        isTpeNotAvailable,
    } = useSnapshot(kioskStore);

    const { actionsButtons: basketSettingsActionsButtons } = useSelector(
        // eslint-disable-next-line
        (state: any): any => state.settingSlice.basketSettings
    );
    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );
    const otherPaymentMethodImgSrc = getOtherPaymentMethodButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );
    const givupButton = basketSettingsActionsButtons.filter(
        // eslint-disable-next-line
        (button: any) => button.name.toLowerCase() !== "pay"
    );
    const givupButtonImageSrc = getImageContentById(givupButton[0].id);

    const activePaymentModesNumber = (
        meansOfPayment as ProjectContentItemType
    ).items.filter((item) => item.active).length;

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isTpeNotAvailable}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span
                    style={{
                        font: "normal normal 600 55px/60px Segoe UI",
                        textAlign: "center",
                    }}
                >
                    {t("The payment terminal (TPE) is currently unavailable.")}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center"
                style={{ height: "200px", width: "95%" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/50px Segoe UI",
                    }}
                >
                    {!(TCPOS === posEditor)
                        ? t("Please use another payment method.")
                        : t(
                              "Currently there is an issue with the credit card payment mode. THANKS."
                          )}
                </span>
            </div>

            <div style={{ height: "200px" }} />

            <div
                style={{ height: "150px", gap: "10px" }}
                className="mx-3 d-flex justify-content-center align-items-center"
            >
                <TryAgain
                    height={"87px"}
                    onClick={() => {
                        setIsTpeNotAvailable(false);
                        if (!(TCPOS === posEditor)) {
                            console.log("try again with CREDIT CARD!!");

                            setRegulationModesModalKey(uuid());
                            setIsAnotherPaymentModeButtonDisabled(true);
                            setIsBackButtonDisabled(true);
                            setIsPaymentRefused(false);
                            setIsAbortTransactionModalOpened(false);
                        } else {
                            validateTCPOSCommand();
                        }
                    }}
                />

                {!(TCPOS === posEditor) ? (
                    <>
                        {activePaymentModesNumber === 1 ? (
                            VALIDATED_EXTENSION_FILE.includes(
                                givupButtonImageSrc.substr(-4)
                            ) ? (
                                <img
                                    src={givupButtonImageSrc}
                                    style={{ width: "60%", height: "65px" }}
                                    onClick={() => {
                                        setNavigationIndex(0);
                                    }}
                                />
                            ) : (
                                // <DynamicSvgComponent
                                //     nameSvg={givupButtonImageSrc}
                                //     width="70%"
                                //     height="87px"
                                //     onClick={() => {
                                //         setNavigationIndex(0);
                                //     }}
                                // />
                                <StyledButton
                                    rounded={true}
                                    className="m-2"
                                    style={{
                                        height: "87px",
                                        width: "350px",
                                        background: "black",
                                        fontSize: "29px",
                                        fontFamily:
                                            "HelveticaNeue-Medium, Helvetica Neue",
                                        fontWeight: "500",
                                        boxShadow: "0px 3px 6px #00000029",
                                        border: "1px solid #FFFFFF",
                                        borderRadius: "12px",
                                        marginRight: "0px",
                                    }}
                                    onClick={() => {
                                        setNavigationIndex(0);
                                    }}
                                >
                                    {t("Cancel my order")}
                                </StyledButton>
                            )
                        ) : VALIDATED_EXTENSION_FILE.includes(
                              otherPaymentMethodImgSrc.substr(-4)
                          ) ? (
                            <img
                                src={otherPaymentMethodImgSrc}
                                alt="action button"
                                style={{ width: "60%", height: "87px" }}
                                onClick={() => {
                                    console.log(
                                        "otherPaymentMethod!!",
                                        "open the other payment modes modal!!"
                                    );
                                    setIsTpeNotAvailable(false);
                                    setIsRegulationModesModalOpened(true);
                                    setIsAbortTransactionModalOpened(false);
                                }}
                            />
                        ) : (
                            // <DynamicSvgComponent
                            //     nameSvg={otherPaymentMethodImgSrc}
                            //     width={"70%"}
                            //     height={"87px"}
                            //     onClick={() => {
                            //         console.log(
                            //             "otherPaymentMethod!!",
                            //             "open the other payment modes modal!!"
                            //         );
                            //         setIsTpeNotAvailable(false);
                            //         setIsRegulationModesModalOpened(true);
                            //         setIsAbortTransactionModalOpened(false);
                            //     }}
                            // />
                            <StyledButton
                                rounded={true}
                                className="m-2"
                                style={{
                                    height: "87px",
                                    width: "350px",
                                    background: "black",
                                    fontSize: "29px",
                                    fontFamily:
                                        "HelveticaNeue-Medium, Helvetica Neue",
                                    fontWeight: "500",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border: "1px solid #FFFFFF",
                                    borderRadius: "12px",
                                    marginRight: "0px",
                                }}
                                onClick={() => {
                                    console.log(
                                        "otherPaymentMethod!!",
                                        "open the other payment modes modal!!"
                                    );
                                    setIsTpeNotAvailable(false);
                                    setIsRegulationModesModalOpened(true);
                                    setIsAbortTransactionModalOpened(false);
                                }}
                            >
                                {t("Other means of payment")}
                            </StyledButton>
                        )}
                    </>
                ) : (
                    <>
                        {VALIDATED_EXTENSION_FILE.includes(
                            getGiveUpActionButtonImageSrc(
                                actionsButtons as ActionsButtonsDesignType
                            ).substr(-4)
                        ) ? (
                            <img
                                src={getGiveUpActionButtonImageSrc(
                                    actionsButtons as ActionsButtonsDesignType
                                )}
                                style={{ width: "50%", height: "87px" }}
                                onClick={() => setNavigationIndex(0)}
                                // style={{ backgroundSize: "cover" }}
                            />
                        ) : (
                            // <DynamicSvgComponent
                            //     nameSvg={getGiveUpActionButtonImageSrc(
                            //         actionsButtons as ActionsButtonsDesignType
                            //     )}
                            //     width={"100%"}
                            //     height={"87px"}
                            //     onClick={() => setNavigationIndex(0)}
                            // />
                            <StyledButton
                                rounded={true}
                                className="m-2"
                                style={{
                                    height: "87px",
                                    width: "350px",
                                    background: "black",
                                    fontSize: "29px",
                                    fontFamily:
                                        "HelveticaNeue-Medium, Helvetica Neue",
                                    fontWeight: "500",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border: "1px solid #FFFFFF",
                                    borderRadius: "12px",
                                    marginRight: "0px",
                                }}
                                onClick={() => {
                                    setNavigationIndex(0);
                                }}
                            >
                                {t("giveUp")}
                            </StyledButton>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
}

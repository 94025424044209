import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import { getEntityData } from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { EntitySelection } from "@components/Synchronization/EntitySelectionModalWrapper/EntitySelection";
import { Header } from "@components/Synchronization/Header";
import {
    setFranchiseSchema,
    setIsEntitySelectionModalOpened,
    setSelectedEntity,
    setSelectedEntityChild,
    setSelectedEntityChildren,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setIsAuthenticationModalOpened,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsMenuModalOpened,
} from "@pages/Kiosk/store";

import { EntityChildrenSelection } from "../EntityChildrenSelectionModalWrapper/EntityChildrenSelection";
import "./index.css";

export function EntitySelectionModalWrapper(): JSX.Element {
    const { middlewareApiUrl } = useSnapshot(store);
    const { isEntitySelectionModalOpened, connectedUserEntity } =
        useSnapshot(syncStore);
    const { isLocalConfigModalOpened } = useSnapshot(kioskStore);

    const [
        isEntityChildrenSelectionModalOpened,
        setIsEntityChildrenSelectionModalOpened,
    ] = React.useState(false);

    const [data, setData] = React.useState<EntityType[]>([]);

    async function getEntity() {
        const url = `${middlewareApiUrl}/entity/${
            data.length > 0 ? data[0].franchiseUuid : connectedUserEntity
        }`;
        fetch(url)
            .then((response) => response.json())
            // eslint-disable-next-line
            .then(async (response) => {
                const entities = getEntityData(response.data);
                setData(entities);

                if (response.data.length === 1) {
                    setSelectedEntity(entities[0]);
                    setFranchiseSchema(entities[0].franchise);
                    setSelectedEntityChildren(
                        entities[0].children as EntityType[]
                    );
                }
            })
            .catch((error) => {
                console.log(
                    `Error during the validation of change machine transaction, with the message ${error.message}`
                );
            });
    }
    function handleGoBackButtonOnClickEvent() {
        if (isEntityChildrenSelectionModalOpened) {
            setIsEntityChildrenSelectionModalOpened(false);
            if (isLocalConfigModalOpened) {
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(false);
                setIsMenuModalOpened(false);
            } else {
                setSelectedEntityChild(undefined);
            }
        } else if (
            data.filter(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (entity: any) => entity.uid === connectedUserEntity
            ).length > 0
        ) {
            setIsAuthenticationModalOpened(true);
            setIsEntitySelectionModalOpened(false);
            setSelectedEntity(undefined);
            setSelectedEntityChild(undefined);
            setSelectedEntityChildren([]);
        } else {
            getEntity();
        }
    }

    React.useEffect(() => {
        localStorage.removeItem("selectedShopApp");
        localStorage.removeItem("selectedEntityChild");
        getEntity();
    }, []);

    return (
        <Modal
            isOpen={isEntitySelectionModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1366px",
                    width: "914px",
                    overflow: "unset",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <Header />
            <FlexboxGrid className="h-100 flex-column justify-content-between">
                {isEntityChildrenSelectionModalOpened ? (
                    <EntityChildrenSelection />
                ) : (
                    <EntitySelection
                        setData={setData}
                        data={data}
                        setIsEntityChildrenSelectionModalOpened={
                            setIsEntityChildrenSelectionModalOpened
                        }
                    />
                )}
            </FlexboxGrid>
            <FlexboxGrid
                className="flex-column"
                alignItemsCentered={true}
                justifyContentCentered={true}
                gap="10px"
            >
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                    className="w-100"
                    styles={{
                        background: "#E9E9E9 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000029",
                        border: "1px solid #FFFFFF",
                    }}
                >
                    <StyledButton
                        rounded={true}
                        className="text-uppercase m-2"
                        style={{
                            width: "197px",
                            height: "61px",
                            font: "normal normal 600 18px/24px Segoe UI",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                        }}
                        onClick={handleGoBackButtonOnClickEvent}
                    >
                        {isEntityChildrenSelectionModalOpened
                            ? t("Back")
                            : data.filter(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (entity: any) =>
                                      entity.uid === connectedUserEntity
                              ).length > 0
                            ? t("Sign out")
                            : t("Back")}
                    </StyledButton>
                </FlexboxGrid>
            </FlexboxGrid>
        </Modal>
    );
}

import {
    CATALOGUE_MODE,
    CESAR,
    DEV_MODE,
    ELECTRON_MODE,
    TCPOS,
    WEBVIEW_MODE,
} from "@constants";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { setIsTechProblemModalOpened, store } from "@store";

import {
    getFilenameFromUrl,
    getSkippedStepNumber,
    handleLedControl,
} from "@helpers/general";

import { useLocalStorage } from "@hooks/useLocalStorage";

import {
    checkforLicenseAvailability,
    checkTcposAvailability,
} from "@components/OrderTaking/Helpers";
import { setMachineIpAddress } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsFenceProblemDetected,
    setNavigationIndex,
} from "@pages/Kiosk/store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function ProdAppSwiperWrapper(): JSX.Element {
    const [isCallOnProgress, setIsCallOnProgress] = React.useState(false);

    const { middlewareApiUrl, posEditor, isLicenseProblem } =
        useSnapshot(store);

    const isBrokenCard = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.orderSlice.isBrokenCard,
        shallowEqual
    );
    const {
        project: {
            template: { content },
        },
        activeStandbyScreenItems,
        navigationIndex,
        kioskWays,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isTcposCancelOrderOngoing,
        orderTotalPrice,
        connectedLedPort,
        isLedActive,
    } = useSnapshot(kioskStore);
    const [machineIpAddress] = useLocalStorage<string>(
        "machineIpAddress",
        "0.0.0.0"
    );

    function orangeLedActivation(): void {
        if (isLedActive) {
            handleLedControl(connectedLedPort, "Orange");
        }
    }

    function redLedActivation(): void {
        if (isLedActive) {
            handleLedControl(connectedLedPort, "Red");
        }
    }

    async function handleNavigationClick() {
        if (process.env.REACT_APP_MODE === DEV_MODE) {
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
            return;
        }
        if (isLicenseProblem && process.env.REACT_APP_MODE !== CATALOGUE_MODE) {
            const isLicenseValide = await checkforLicenseAvailability();

            if (!isLicenseValide) {
                // change led color to red
                redLedActivation();
                return;
            }
        }

        if (isTcposCancelOrderOngoing) {
            return;
        }
        if (isBrokenCard) {
            console.log(`Error card catlogue is brocken`);
            setIsTechProblemModalOpened(true);
            // change led color to red
            redLedActivation();
        }
        if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
            return;
        }

        if (TCPOS === posEditor) {
            const isTcposAvailable = await checkTcposAvailability();

            if (isTcposAvailable) {
                // change led color to orange
                orangeLedActivation();
            } else {
                // change led color to red
                redLedActivation();
            }

            isTcposAvailable &&
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            await getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                content,
                                Number(orderTotalPrice)
                            )
                        )
                );
            return;
        }

        if (posEditor !== CESAR) {
            // change led color to orange
            orangeLedActivation();
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
            return;
        }
        if (isCallOnProgress) {
            return;
        }
        setIsCallOnProgress(true);

        const timestamp = new Date().getTime();

        await fetch(
            `${middlewareApiUrl}/checkKioskFence?timestamp=${timestamp}`,
            {
                cache: "no-store",
            }
        )
            .then((response) => response.json())
            .then(async ({ code, fence_problem }) => {
                if (code !== 2) {
                    console.log(
                        "The middleware return an error while checking kiosk fence!"
                    );
                    setIsFenceProblemDetected(true);
                    // change led color to red
                    redLedActivation();
                    return;
                }
                // this is a hidden bug coming from the middleware
                // @todo find a better solution
                if (fence_problem === "false") {
                    // change led color to orange
                    orangeLedActivation();

                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                } else {
                    setIsFenceProblemDetected(true);
                    // change led color to red
                    redLedActivation();
                    console.log(
                        "The middleware return an error while checking kiosk fence!"
                    );
                }
            })
            .catch((error) => {
                console.log(
                    `Error while checking kiosk fence, with message: ${JSON.stringify(
                        error.message
                    )}`
                );
                setIsFenceProblemDetected(true);
                // change led color to red
                redLedActivation();
            })
            .finally(() => {
                setIsCallOnProgress(false);
            });
    }
    const localActiveStandbyScreenItems = activeStandbyScreenItems.map(
        (item: StandbyScreenItemType) => {
            let contentFile = "./images/activeStateScreen.webm";

            if (item.content?.includes("http")) {
                contentFile = getFilenameFromUrl(item.content, "template");
            }

            return {
                ...item,
                content: contentFile,
            };
        }
    );

    React.useEffect(() => {
        if (
            process.env.REACT_APP_MODE === CATALOGUE_MODE ||
            process.env.REACT_APP_MODE === DEV_MODE
        ) {
            return;
        }
        if (
            machineIpAddress === "0.0.0.0" &&
            process.env.REACT_APP_MODE === ELECTRON_MODE
        ) {
            // eslint-disable-next-line
            (window as any)
                .electronAPI!.getAppIpAddressEvent()
                .then((response: string) => {
                    setMachineIpAddress(response);
                });
            return;
        }
        if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
            fetch(`${middlewareApiUrl}/get-ip`)
                .then((response) => response.json())
                .then((response) => {
                    setMachineIpAddress(response);
                    console.log(middlewareApiUrl);
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    console.log(`Error while getting IP`, error);
                });
        }
    }, []);

    return (
        <div
            onClick={handleNavigationClick}
            style={{
                position:
                    process.env.REACT_APP_MODE === CATALOGUE_MODE
                        ? "unset"
                        : "fixed",
                zIndex: -1,
                height: "100%",
                width: "100%",
            }}
        >
            {localActiveStandbyScreenItems.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                    }}
                    noSwipingClass="swiper-slide"
                    className="standby-screen-swiper-wrapper__clz"
                    noSwiping={true}
                >
                    {localActiveStandbyScreenItems.map(
                        (item: StandbyScreenItemType) => {
                            return (
                                <SwiperSlide
                                    key={item.id}
                                    data-swiper-autoplay={item.timeout * 1000}
                                >
                                    {item.type.includes("video") ? (
                                        <video
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: `${
                                                    process.env
                                                        .REACT_APP_CATALOGUE_MODE ===
                                                    "true"
                                                        ? "contain"
                                                        : "fill"
                                                }`,
                                            }}
                                        >
                                            <source
                                                src={item.content}
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <img
                                            src={item.content}
                                            alt={item.id}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: `${
                                                    process.env
                                                        .REACT_APP_CATALOGUE_MODE ===
                                                    "true"
                                                        ? "contain"
                                                        : "fill"
                                                }`,
                                            }}
                                        />
                                    )}
                                </SwiperSlide>
                            );
                        }
                    )}
                </Swiper>
            ) : (
                localActiveStandbyScreenItems.map(
                    (item: StandbyScreenItemType) => {
                        return item.type.includes("video") ? (
                            <video
                                autoPlay={true}
                                muted={true}
                                loop={true}
                                key={item.id}
                                className="h-100 w-100"
                                style={{
                                    objectFit: `${
                                        process.env.REACT_APP_MODE ===
                                        CATALOGUE_MODE
                                            ? "contain"
                                            : "fill"
                                    }`,
                                }}
                            >
                                <source src={item.content} type="video/mp4" />
                            </video>
                        ) : (
                            <img
                                key={item.id}
                                src={item.content}
                                className="h-100 w-100"
                                style={{
                                    objectFit: `${
                                        process.env.REACT_APP_MODE ===
                                        CATALOGUE_MODE
                                            ? "contain"
                                            : "fill"
                                    }`,
                                }}
                            />
                        );
                    }
                )
            )}
        </div>
    );
}

import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore as store } from "@pages/Kiosk/store";

export default function SummaryHeader() {
    const { summaryHeaderBravoText, summaryHeaderLoyaltyPointText } =
        useSelector((state) => state.settingSlice.summary, shallowEqual);
    const { orderFidelity } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );
    const { isUserAuthenticated, customerLanguage } = useSnapshot(store);
    if (!isUserAuthenticated) return null;
    return (
        <div className="header-text pl-4">
            <span
                style={{
                    font: "normal normal bold 40px/54px Nunito",
                }}
            >
                {
                    summaryHeaderBravoText.languages[customerLanguage.name]
                        .content
                }
            </span>
            <br />
            <span
                style={{
                    font: "normal normal 400 30px/40px Segoe UI",
                }}
            >
                {
                    summaryHeaderLoyaltyPointText.languages[
                        customerLanguage.name
                    ].content
                }
                {`${orderFidelity} ${t("points")}`}
            </span>
        </div>
    );
}
SummaryHeader.propTypes = {
    summaryHeaderBravoText: PropTypes.object,
    summaryHeaderLoyaltyPointText: PropTypes.object,
};

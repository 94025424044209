import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import {
    getAlertActionButtonImageSrc,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import {
    kioskStore,
    setIsAbortTransactionModalOpened,
    setIsCashMachineTransactionCanceled,
    setIsCashTransactionValidated,
    setIsChangeMachineRenderModalOpened,
    setIsEventStreamProblem,
    setIsPaymentRefused,
    setIsTransactionCanceled,
    setLeftToPayAfterCashMachineValidation,
    setPaymentModes,
    setPaymentStepCounter,
    setRenderAmount,
} from "@pages/Kiosk/store";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function AbortTransactionModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isAbortTransactionModalOpened,
        selectedRegulationModeValue,
        isTransactionCanceled,
        isAcceptAbortCBTransactionButtonDisabled,
        amountDeposited,
        totalAmountDeposited,
        isAnotherPaymentModeButtonDisabled,
        isBackButtonDisabled,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        leftToPayAfterCashMachineValidation,
        orderTotalPrice,
    } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);
    const abortTransactionRef = React.useRef<HTMLButtonElement | null>(null);

    function handleSettingRenderAmount() {
        if (amountDeposited === 0) {
            console.log(
                `The amount deposited in this transaction is null: ${amountDeposited}`
            );
            console.log(
                `If the totalAmountDeposited is not null and not equal to the total price of the order, the change machine render modal will opened`
            );
            setRenderAmount(totalAmountDeposited);
            if (
                totalAmountDeposited !== 0 &&
                totalAmountDeposited !==
                    (isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : orderTotalPrice)
            ) {
                console.log("The change machine render modal is opened");
                setIsChangeMachineRenderModalOpened(true);
            }
        } else {
            console.log(`The amount deposited in this transaction is not null`);
            setRenderAmount(totalAmountDeposited + amountDeposited);
            console.log(
                `The render amount will be  totalAmountDeposited + amountDeposited: ${
                    totalAmountDeposited + amountDeposited
                }`
            );

            if (
                totalAmountDeposited + amountDeposited !==
                (isOrderPartialPaidWithLoyaltyAccountBalance
                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                    : orderTotalPrice)
            ) {
                console.log(
                    "The modal for the change machine rendering has been opened."
                );
                setIsChangeMachineRenderModalOpened(true);
            }
        }
    }

    function handleAbortTransaction() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        abortTransactionRef.current!.disabled = true;
        const timestamp = new Date().getTime();
        if (isTransactionCanceled === false) {
            console.log("The transaction canceling is accepted!!!");
            if (selectedRegulationModeValue === "changeMachine") {
                if (
                    isAnotherPaymentModeButtonDisabled &&
                    isBackButtonDisabled === false
                ) {
                    const url = `${middlewareApiUrl}/AbortTransactionCash?timestamp=${timestamp}`;

                    fetch(url)
                        .then((response) => response.json())
                        .then((response) => {
                            if (response.code === 2) {
                                console.log(
                                    "The cash machine aborted successfully"
                                );
                                setIsAbortTransactionModalOpened(false);
                                setIsTransactionCanceled(true);
                                handleSettingRenderAmount();
                                setIsEventStreamProblem(true);
                                setIsCashMachineTransactionCanceled(false);
                                setPaymentModes([]);
                                setLeftToPayAfterCashMachineValidation(0);
                            }
                        })
                        .catch((error) => {
                            console.log(
                                `Error while cancelling the ${selectedRegulationModeValue} transaction, with the message: ${error.message}`
                            );
                        })
                        .finally(() => {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            abortTransactionRef.current!.disabled = false;
                        });
                } else {
                    setIsCashTransactionValidated(true);
                    const validCashMachineTransactionAmount =
                        leftToPayAfterCashMachineValidation !== 0
                            ? leftToPayAfterCashMachineValidation -
                              amountDeposited
                            : isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance -
                              amountDeposited
                            : orderTotalPrice - amountDeposited;
                    setLeftToPayAfterCashMachineValidation(
                        validCashMachineTransactionAmount
                    );
                    const url = `${middlewareApiUrl}/ValidCashMachineTransaction?unitaire=${validCashMachineTransactionAmount}&timestamp=${timestamp}`;

                    fetch(url)
                        .then((response) => response.json())
                        // eslint-disable-next-line
                        .then((_) => {
                            console.log(
                                "The cash machine validation was successful after the transaction being aborted"
                            );
                            setIsAbortTransactionModalOpened(false);
                            setIsTransactionCanceled(true);
                            setIsPaymentRefused(true);
                            handleSettingRenderAmount();

                            setIsEventStreamProblem(false);
                            setPaymentModes([]);
                        })
                        .catch((error) => {
                            console.log(
                                `Error during the validation of change machine transaction, with the message ${error.message}`
                            );
                        })
                        .finally(() => {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            abortTransactionRef.current!.disabled = false;
                        });
                }

                setPaymentStepCounter(0);
            } else {
                const url = `${middlewareApiUrl}/AbortCBTransaction?timestamp=${timestamp}`;
                console.log("handle ABORT CB transaction", { url });

                fetch(url)
                    .then((response) => response.json())
                    .then((response) => {
                        console.log(
                            "The response  canceling CB transaction is",
                            JSON.stringify(response)
                        );
                        if (response.code === 2) {
                            setIsAbortTransactionModalOpened(false);
                            setIsTransactionCanceled(true);
                            handleSettingRenderAmount();

                            setPaymentModes([]);
                            setLeftToPayAfterCashMachineValidation(0);
                        }
                    })
                    .catch((error) => {
                        console.log(
                            `Error while cancelling the ${selectedRegulationModeValue} transaction, with the message: ${error.message}`
                        );
                    })
                    .finally(() => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        abortTransactionRef.current!.disabled = false;
                    });
            }
        }
    }

    if (isAcceptAbortCBTransactionButtonDisabled) {
        console.log(
            JSON.stringify({
                isAcceptAbortCBTransactionButtonDisabled:
                    isAcceptAbortCBTransactionButtonDisabled,
            }),
            "We have received a message indicating that the payment has been accepted, which means that we cannot accept the cancellation"
        );
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isAbortTransactionModalOpened}
            contentLabel="help-modal"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "340px" }}
            >
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType
                    )}
                />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "117px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Cancellation of payment")}
                </span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("The payment will be cancelled.")}
                </span>
            </div>

            <div style={{ height: "50px" }}></div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("Would you like to continue ?")}
                </span>
            </div>
            <div style={{ height: "200px" }}></div>
            <div
                style={{ height: "450px" }}
                className="d-flex justify-content-center"
            >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <button
                        style={{
                            all: "unset",
                        }}
                    >
                        <img
                            src={getNoActionButtonImageSrc(
                                actionsButtons as ActionsButtonsDesignType
                            )}
                            style={{ height: "85%" }}
                            onClick={() => {
                                if (isTransactionCanceled === false) {
                                    console.log(
                                        "The transaction canceling is refused!!!"
                                    );
                                    setPaymentStepCounter(0);
                                    setIsAbortTransactionModalOpened(false);
                                }
                            }}
                        />
                    </button>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("No")}
                    </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <button
                        ref={abortTransactionRef}
                        onClick={handleAbortTransaction}
                        style={{
                            all: "unset",
                        }}
                    >
                        <img
                            src={getYesActionButtonImageSrc(
                                actionsButtons as ActionsButtonsDesignType
                            )}
                            style={{
                                height: "85%",
                                pointerEvents:
                                    isAcceptAbortCBTransactionButtonDisabled
                                        ? "none"
                                        : "auto",
                                opacity:
                                    isAcceptAbortCBTransactionButtonDisabled
                                        ? "0.5"
                                        : 1,
                            }}
                        />
                    </button>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("Yes")}
                    </span>
                </div>
            </div>
        </Modal>
    );
}

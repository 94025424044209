import { snapshot } from "valtio";

import { handleAbortCashMachineTransaction } from "@helpers/general";

import {
    kioskStore,
    setIsCashMachineTransactionCanceled,
    setIsChangeMachineRenderModalOpened,
    setIsPrm,
    setNavigationIndex,
    setRenderAmount,
} from "@pages/Kiosk/store";

export const timerIsReached = (): void => {
    const {
        kioskWays,
        navigationIndex,
        totalAmountDeposited,
        selectedRegulationModeValue,
        isAnotherPaymentModeButtonDisabled,
        isBackButtonDisabled,
    } = snapshot(kioskStore);
    setIsPrm(false);
    console.log("initial Step Timeout detected");
    if (kioskWays[navigationIndex].name === "payment") {
        if (totalAmountDeposited !== 0) {
            setRenderAmount(totalAmountDeposited);
            setIsChangeMachineRenderModalOpened(true);
        }
        console.log("handleAbortCashMachineTransaction");
        handleAbortCashMachineTransaction();
        setIsCashMachineTransactionCanceled(true);
        console.log(
            "standby modal in payment step",
            JSON.stringify({
                isAnotherPaymentModeButtonDisabled,
                isBackButtonDisabled,
                selectedRegulationModeValue,
            })
        );
        if (
            isAnotherPaymentModeButtonDisabled &&
            isBackButtonDisabled === false &&
            selectedRegulationModeValue === "changeMachine"
        ) {
            console.log(
                "navigate to standby step when there is an event stream problem"
            );
            setNavigationIndex(0);
        }
    } else {
        setNavigationIndex(0);
    }
};

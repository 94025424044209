import { ETK_CESAR, ITEM_TYPE } from "@constants";

import { useSelector } from "react-redux";

import { getItemPriceWithOption, getPrice } from "../../../../Helpers";
import { defaultOptionProduct } from "./defaultOptionProduct";

function getItemPrice(currentWorkflow, product, selectedSaleModeId, itemMenu) {
    const { cardType } = useSelector((state) => state.orderSlice);
    if (
        product.type !== ITEM_TYPE &&
        (product.isRedirect === undefined || product.isRedirect === false)
    ) {
        return {
            price: 0,
            tva: 0,
        };
    }
    const defaultItemOptions =
        product.optionsKiosk?.Options?.length > 0
            ? defaultOptionProduct(product.optionsKiosk.Options)
            : [];

    let itemPrice = getPrice(
        [selectedSaleModeId, "KIOSK", ...defaultItemOptions],
        product.price,
        defaultItemOptions.length === 0
    );
    // ! in progress waiting for rules

    if (currentWorkflow && product.isStepItem) {
        const {
            nbrOfChoicesWithspecialPrice,
            selectedChoicesWithspecialPrice,
            nbrOfChoicesWithStepPrice,
            selectedChoicesWithStepPrice,
            maxNbrOfChoices,
        } = currentWorkflow.workflow[currentWorkflow.index];

        const { specialPrice, priceOfItemInStep, hasStepItemPrice } = product;

        if (hasStepItemPrice === false) {
            if (cardType == ETK_CESAR) {
                // ? =>si pas de prix gratuit on prend prix ecran si pas taille sinon prix taille parent

                if (
                    nbrOfChoicesWithspecialPrice === 0 &&
                    nbrOfChoicesWithStepPrice === 0
                ) {
                    itemPrice.price = parseFloat(priceOfItemInStep);

                    if (
                        itemMenu?.selectedOptions.length > 0 &&
                        product.options
                    ) {
                        itemPrice = getItemPriceWithOption(
                            itemMenu?.selectedOptionsUuid,
                            product.options,
                            product.price,
                            itemPrice
                        );
                    }
                }

                // ?=> control prix group 1 et 2
                if (nbrOfChoicesWithspecialPrice > 0) {
                    if (
                        selectedChoicesWithspecialPrice <
                            nbrOfChoicesWithspecialPrice ||
                        nbrOfChoicesWithspecialPrice === maxNbrOfChoices
                    ) {
                        itemPrice.price = parseFloat(specialPrice);
                    } else {
                        itemPrice.price = parseFloat(priceOfItemInStep);

                        if (nbrOfChoicesWithStepPrice > 0) {
                            if (
                                nbrOfChoicesWithStepPrice >
                                selectedChoicesWithStepPrice
                            ) {
                                itemPrice.price = parseFloat(priceOfItemInStep);
                            } else if (
                                itemMenu?.selectedOptions.length > 0 &&
                                product.options
                            ) {
                                itemPrice = getItemPriceWithOption(
                                    itemMenu?.selectedOptionsUuid,
                                    product.options,
                                    product.price,
                                    itemPrice
                                );
                            }
                        } else if (
                            itemMenu?.selectedOptions.length > 0 &&
                            product.options
                        ) {
                            itemPrice = getItemPriceWithOption(
                                itemMenu?.selectedOptionsUuid,
                                product.options,
                                product.price,
                                itemPrice
                            );
                        }
                    }
                } else if (nbrOfChoicesWithStepPrice > 0) {
                    itemPrice.price = parseFloat(priceOfItemInStep);
                    if (
                        nbrOfChoicesWithStepPrice >
                            selectedChoicesWithStepPrice ||
                        nbrOfChoicesWithStepPrice === maxNbrOfChoices
                    ) {
                        itemPrice.price = parseFloat(priceOfItemInStep);
                    } else if (
                        itemMenu?.selectedOptions.length > 0 &&
                        product.options
                    ) {
                        itemPrice = getItemPriceWithOption(
                            itemMenu?.selectedOptionsUuid,
                            product.options,
                            product.price,
                            itemPrice
                        );
                    }
                } else {
                    itemPrice.price = parseFloat(priceOfItemInStep);
                    if (
                        itemMenu?.selectedOptions.length > 0 &&
                        product.options
                    ) {
                        itemPrice = getItemPriceWithOption(
                            itemMenu?.selectedOptionsUuid,
                            product.options,
                            product.price,
                            itemPrice
                        );
                    }
                }
            } else {
                if (nbrOfChoicesWithspecialPrice > 0) {
                    if (
                        selectedChoicesWithspecialPrice <
                        nbrOfChoicesWithspecialPrice
                    ) {
                        itemPrice.price = parseFloat(specialPrice);
                    } else if (
                        nbrOfChoicesWithStepPrice === 0 ||
                        nbrOfChoicesWithStepPrice > 0
                    ) {
                        itemPrice.price = parseFloat(priceOfItemInStep);
                        if (
                            itemMenu?.selectedOptions.length > 0 &&
                            product.options
                        ) {
                            itemPrice = getItemPriceWithOption(
                                itemMenu?.selectedOptionsUuid,
                                product.options,
                                product.price,
                                itemPrice
                            );
                        }
                    }
                } else if (nbrOfChoicesWithStepPrice > 0) {
                    itemPrice.price = parseFloat(priceOfItemInStep);
                    if (
                        itemMenu?.selectedOptions.length > 0 &&
                        product.options
                    ) {
                        itemPrice = getItemPriceWithOption(
                            itemMenu?.selectedOptionsUuid,
                            product.options,
                            product.price,
                            itemPrice
                        );
                    }
                }
            }
        }
    }

    return {
        price: itemPrice.price,
        tva: product.price?.saleModeVatRates[selectedSaleModeId]
            ? product.price?.saleModeVatRates[selectedSaleModeId]
            : 0,
    };
}

export default getItemPrice;

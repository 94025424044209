import { CATALOGUE_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";
import { PrmNumericKeyboardWrapper } from "@components/PrmNumericKeyboardWrapper";

import {
    kioskStore,
    setIsInformationModesConsommationModalOpened,
    setKeyboardInputsValues,
    setNumericKeyboardInputValue,
    setPseudoNameValue,
} from "@pages/Kiosk/store";

import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";

export function PrmMode(): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
        isSubStepActionButtonsActive,
        isSubStepInformationMessageActive,
        selectedInformationMode,
        subStepActionButtons,
        isInformationModesConsommationModalOpened,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    React.useEffect(() => {
        setNumericKeyboardInputValue("");
        setPseudoNameValue("");
        setKeyboardInputsValues({});
    }, []);

    return (
        <Modal
            isOpen={isInformationModesConsommationModalOpened}
            ariaHideApp={false}
            onRequestClose={() => {
                setIsInformationModesConsommationModalOpened(
                    !isInformationModesConsommationModalOpened
                );
            }}
            contentLabel="userAccount-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1230px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "10px",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div style={{ marginBottom: "400px" }}>
                {isSubStepInformationMessageActive ? (
                    <SubStepsInformationMessage />
                ) : null}
            </div>
            <div className="d-flex justify-content-center align-items-center mb-5">
                {selectedInformationMode === "nameOrPseudo" ? (
                    <InputKeyboardWrapper />
                ) : (
                    <PrmNumericKeyboardWrapper />
                )}
            </div>

            {isSubStepActionButtonsActive ? (
                <ActionsButtonsWrapper
                    actionsButtonsItems={mainActiveActionsButtons}
                />
            ) : null}
        </Modal>
    );
}

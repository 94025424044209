import PropTypes from "prop-types";

import React from "react";

import { getImageContentById } from "@helpers/general";

export function SummaryOption({
    isSummaryOptionsItemsActive,
    newOptionConfig,
    handleConfigOptionOnClickEvent,
}) {
    return isSummaryOptionsItemsActive ? (
        <>
            {newOptionConfig.length !== 0 ? (
                <hr className="vertical-separation-line" />
            ) : null}

            <div
                className="d-flex justify-content-end align-items-center pr-2"
                style={{ gap: "10px" }}
            >
                {newOptionConfig.map((option, index) => {
                    const imageSrc = getImageContentById(option.id);

                    return (
                        <img
                            className="sales-method-image"
                            key={index}
                            src={imageSrc}
                            alt={option.title}
                            onClick={() => {
                                handleConfigOptionOnClickEvent(option);
                            }}
                        />
                    );
                })}
            </div>
        </>
    ) : null;
}

SummaryOption.propTypes = {
    isSummaryOptionsItemsActive: PropTypes.bool,
    newOptionConfig: PropTypes.array,
    handleConfigOptionOnClickEvent: PropTypes.func,
};

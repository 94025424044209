import classNames from "classnames";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { changeLanguage, getImageContentById } from "@helpers/general";

import { kioskStore, setCustomerLanguage } from "@pages/Kiosk/store";

import { LanguagesWrapper } from "../LanguagesWrapper";
import "./index.css";

import "swiper/swiper-bundle.min.css";

export function UsualModeLanguagesWrapper(): JSX.Element {
    const { activeLanguages, isLanguagesActive } = useSnapshot(kioskStore);

    const languagesWithPairIndex: LanguagesItemType[] = [];
    const languagesWithImpairIndex: LanguagesItemType[] = [];

    function syncCustomerLanguageWithAppLanguage(language: LanguagesItemType) {
        setCustomerLanguage(language);
        changeLanguage(language.name);
    }

    if (activeLanguages.length > 6) {
        activeLanguages.forEach(
            (language: LanguagesItemType, index: number) => {
                const urlFlag = getImageContentById(language.id);

                const refactorLanguage = { ...language, flag: urlFlag };

                if (index % 2 === 0) {
                    languagesWithPairIndex.push(refactorLanguage);
                } else {
                    languagesWithImpairIndex.push(refactorLanguage);
                }
            }
        );
    }
    const localLanguage = activeLanguages.map((item) => {
        const urlFlag = getImageContentById(item.id);

        return { ...item, flag: urlFlag };
    });

    return (
        <React.Fragment>
            {isLanguagesActive ? (
                localLanguage.length <= 6 ? (
                    <div
                        className="mx-3 d-flex justify-content-center my-4"
                        style={{ gap: "20px", flexWrap: "wrap" }}
                    >
                        {localLanguage.map(
                            (item: LanguagesItemType, index: number) => {
                                return (
                                    <LanguagesWrapper
                                        language={item}
                                        index={index}
                                        key={item.id}
                                    />
                                );
                            }
                        )}
                    </div>
                ) : (
                    <Swiper
                        navigation={true}
                        spaceBetween={50}
                        slidesPerView={3}
                        className="kiosk-languages-swiper-container__clz"
                        style={{ zIndex: 0 }}
                        modules={[Navigation]}
                    >
                        {languagesWithPairIndex.map((item, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="d-flex flex-column justify-content-center"
                                    style={{ gap: "30px" }}
                                >
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            gap: "10px",
                                        }}
                                        onClick={() => {
                                            syncCustomerLanguageWithAppLanguage(
                                                item
                                            );
                                        }}
                                    >
                                        <img
                                            src={item.flag}
                                            alt={item.title}
                                            style={{
                                                height: "80px",
                                                width: "120px",
                                            }}
                                        />
                                        <span
                                            style={{
                                                fontSize: "35px",
                                            }}
                                        >
                                            {item.title}
                                        </span>
                                    </div>

                                    {languagesWithImpairIndex[index] !==
                                    undefined ? (
                                        <div
                                            className="d-flex align-items-center"
                                            style={{
                                                gap: "10px",
                                            }}
                                            onClick={() => {
                                                syncCustomerLanguageWithAppLanguage(
                                                    languagesWithImpairIndex[
                                                        index
                                                    ]
                                                );
                                            }}
                                        >
                                            <img
                                                src={
                                                    languagesWithImpairIndex[
                                                        index
                                                    ].flag
                                                }
                                                alt={
                                                    languagesWithImpairIndex[
                                                        index
                                                    ] !== undefined
                                                        ? `${languagesWithImpairIndex[index].title}`
                                                        : ""
                                                }
                                                style={{
                                                    height: "80px",
                                                    width: "120px",
                                                }}
                                            />
                                            <span
                                                className={classNames({
                                                    "customer-language-title__clz":
                                                        index === 0,
                                                })}
                                                style={{
                                                    fontSize: "35px",
                                                }}
                                            >
                                                {
                                                    languagesWithImpairIndex[
                                                        index
                                                    ].title
                                                }
                                            </span>
                                        </div>
                                    ) : null}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                )
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
}

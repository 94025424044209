import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { setIsAppPaused, store } from "@store";

import { setIsEntitySelectionModalOpened } from "@components/Synchronization/store";

import "./index.css";

enum systemActionsType {
    NoAction = "NoAction",
    Reset = "reset",
    ChangeMiddleware = "Change Middleware",
    Active = "pause",
    Update = "update",
    Restart = "restart",
    Close = "close",
    SwitchOff = "switch off",
}

type MenuModalWrapperPropsType = {
    isMenuModalOpened: boolean;
    setIsMenuModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setSystemActions: React.Dispatch<React.SetStateAction<systemActionsType>>;
    setIsSystemConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
type ButtonMenuPropsType = {
    title: string;
    imgSrc: string;
    styleMenu?: React.CSSProperties;
};

export function MenuModalWrapper({
    isMenuModalOpened,
    setIsMenuModalOpened,
    setSystemActions,
    setIsSystemConfirmationOpen,
}: MenuModalWrapperPropsType): JSX.Element {
    const { isAppPaused } = useSnapshot(store);
    const listMenu: ButtonMenuPropsType[] = [
        {
            title: "Reset Kiosk",
            imgSrc: "./images/restart.png",
            styleMenu: { gap: "5px" },
        },
        {
            title: "Change Middleware address",
            imgSrc: "./images/restart.png",
            styleMenu: { gap: "5px" },
        },
        {
            title: isAppPaused ? "Put in production" : "Pause",
            imgSrc: isAppPaused
                ? "./images/play.png"
                : "./images/pause-button.png",
            styleMenu: { gap: "5px" },
        },

        {
            title: "Update the app",
            styleMenu: { opacity: 0.6 },
            imgSrc: "./images/data-synchronization.png",
        },
        {
            title: "Change app",
            imgSrc: "./images/data-synchronization.png",
        },
        {
            title: "Close app",
            styleMenu: {},
            imgSrc: "./images/on-off-button.png",
        },
        {
            title: "Restart the post",
            styleMenu: {},
            imgSrc: "./images/restart.png",
        },
        {
            title: "Switch off the post",
            styleMenu: {},
            imgSrc: "./images/on-off-button.png",
        },
    ];

    const handleClickEvent = (type: string) => {
        switch (type) {
            case "Reset Kiosk":
                setIsSystemConfirmationOpen(true);
                setSystemActions(systemActionsType.Reset);
                break;
            case "Change Middleware address":
                setIsSystemConfirmationOpen(true);
                setSystemActions(systemActionsType.ChangeMiddleware);
                break;
            case "Put in production":
                setIsAppPaused(false);
                setSystemActions(systemActionsType.Active);
                break;
            case "Pause":
                setSystemActions(systemActionsType.Active);
                setIsSystemConfirmationOpen(true);
                // setIsConfirmationStandbyStateModalOpened(true);

                break;
            case "Update the app":
                setIsSystemConfirmationOpen(true);
                setSystemActions(systemActionsType.Update);
                break;
            case "Change app":
                setIsEntitySelectionModalOpened(true);
                break;
            case "Close app":
                setIsSystemConfirmationOpen(true);
                setSystemActions(systemActionsType.Close);
                break;
            case "Restart the post":
                setIsSystemConfirmationOpen(true);
                setSystemActions(systemActionsType.Restart);
                break;
            case "Switch off the post":
                setIsSystemConfirmationOpen(true);
                setSystemActions(systemActionsType.SwitchOff);
                // setIsShutdownModalOpened(true);
                break;
        }
        setIsMenuModalOpened(false);
    };
    return (
        <Modal
            isOpen={isMenuModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                    width: "250px",
                    marginTop: "69rem",
                    marginLeft: "145px",
                    borderRadius: "10px",
                    zIndex: 3,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "fit-content",
                    width: "330px",
                    fontSize: "50px",
                    zIndex: 2,
                    background: "#2c2b2b 0% 0% no-repeat padding-box",
                    border: "1px solid #FFFFFF",

                    borderRadius: "12px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    opacity: 0.9,
                    boxShadow: "0px 3px 6px #00000029",
                    backdropFilter: "blur(30px)",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                style={{
                    height: "35px",
                    font: "600 18px / 24px Segoe UI",
                    color: "white",
                    marginLeft: "30px",
                    marginTop: "10px",
                }}
            >
                {t("Menu")}
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "10px",
                    margin: "10px",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
                }}
            >
                {listMenu.map((menu: ButtonMenuPropsType, index: number) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <div
                            className="menu-buttons__clz"
                            style={menu.styleMenu}
                            onClick={() => {
                                handleClickEvent(menu.title);
                            }}
                            key={index}
                        >
                            <img className="ml-2" src={menu.imgSrc} />
                            {t(menu.title)}
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
}

import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import CardProduct from "@components/OrderTaking/Components/Products/ProductWrapper/CardProduct";
import {
    memoizedActiveCategoryUid,
    memoizedOrderItems,
} from "@components/OrderTaking/Store/feature";
import { initialState } from "@components/OrderTaking/Store/feature/orderWorkflowItemSlice";

import { kioskStore } from "@pages/Kiosk/store";

import { getVisibleItems } from "./hooks";

import "swiper/swiper.min.css";

export default function PmrProducts({
    listProducts,
    listworkflow,
    listAdditionnalItems,
    isWorkFlow,
    workflowData = [],
    orderWorkflowItemSlice = initialState,
}) {
    const { selectedSaleModeOrderTaking } = useSnapshot(kioskStore);
    const activeCategory = useSelector((state) =>
        memoizedActiveCategoryUid(state)
    );
    const orderItems = useSelector((state) => memoizedOrderItems(state));

    const keySlide =
        listProducts !== undefined ? listProducts.length : activeCategory;
    const { additionalSale } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { design } = useSelector((state) => state.orderSlice, shallowEqual);

    const { visibleItems } = getVisibleItems(
        listProducts,
        listAdditionnalItems,
        activeCategory,
        selectedSaleModeOrderTaking,
        isWorkFlow,
        listworkflow,
        false,
        additionalSale,
        design
    );
    if (document.getElementsByClassName("swiper-pagination")[0] !== undefined) {
        visibleItems.length <= 3
            ? document
                  .getElementsByClassName("swiper-pagination")[0]
                  .classList.add("d-none")
            : document
                  .getElementsByClassName("swiper-pagination")[0]
                  .classList.remove("d-none");
    }
    const isSlideAnimationEnter =
        listworkflow || listAdditionnalItems ? 0 : 600;
    return (
        <Slide
            in={true}
            key={keySlide}
            unmountOnExit
            style={{ transformOrigin: "0 0 0 " }}
            timeout={{ appear: 0, enter: isSlideAnimationEnter, exit: 100 }}
            direction="up"
        >
            <div
                style={{
                    paddingRight: "3px",
                    width: "95%",
                }}
            >
                <Swiper
                    slidesPerView={"auto"}
                    slidesPerGroup={3}
                    spaceBetween={15}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className={`prm-products-swiper-container ${
                        visibleItems.length <= 3
                            ? "centered-swiper centered-slides-swiper"
                            : "pr-4 pt-2"
                    } `}
                    style={{ justifyContent: "center !important" }}
                >
                    {visibleItems.map((product) => {
                        return (
                            <SwiperSlide
                                key={product.iuud}
                                className="prm-products-swiper-slide"
                            >
                                <CardProduct
                                    product={product}
                                    listworkflow={listworkflow}
                                    orderItems={orderItems}
                                    workflowData={workflowData}
                                    orderWorkflowItemSlice={
                                        orderWorkflowItemSlice
                                    }
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </Slide>
    );
}
PmrProducts.propTypes = {
    listProducts: PropTypes.array,
    listworkflow: PropTypes.object,
    listAdditionnalItems: PropTypes.array,
    isWorkFlow: PropTypes.bool,
    workflowData: PropTypes.array,
    orderWorkflowItemSlice: PropTypes.object,
};

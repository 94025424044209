import { STEP_PAYEMENT } from "@constants";
import { useSnapshot } from "valtio";

import React, { useEffect, useState } from "react";

import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import { addEventListeners, removeEventListeners } from "./eventListenerUtil";

export const TimeoutLogic = () => {
    const timerRef = React.useRef();
    const [isWarningModalOpen, setWarningModalOpen] = useState(false);
    const {
        timeout,
        navigationIndex,
        isChangeMachineRenderModalOpened,
        isHelpModalOpened,
        isRefundAnomalyModalOpened,
        isAmountReturned,
        isTcposCancelOrderOngoing,
        isOrderPaid,
        kioskWays,
    } = useSnapshot(kioskStore);
    const clearTimer = clearTimeout;

    useEffect(() => {
        if (timerRef.current) clearTimer(timerRef.current);
        const createTimeout = () =>
            setTimeout(() => {
                if (
                    navigationIndex > 0 &&
                    kioskWays[navigationIndex]?.name !== STEP_PAYEMENT &&
                    isOrderPaid === false &&
                    isHelpModalOpened === false &&
                    isWarningModalOpen === false &&
                    isTcposCancelOrderOngoing === false &&
                    (isAmountReturned === true ||
                        (isChangeMachineRenderModalOpened === false &&
                            isRefundAnomalyModalOpened === false))
                ) {
                    setWarningModalOpen(true);
                    clearTimeout(timerRef.current);
                }
            }, timeout * 1000);

        timerRef.current = createTimeout();

        const listener = () => {
            if (isWarningModalOpen === false) {
                clearTimeout(timerRef.current);
                timerRef.current = createTimeout();
            }
        };

        // Initialization
        addEventListeners(listener);

        // Cleanup
        return () => {
            removeEventListeners(listener);
            clearTimeout(timerRef.current);
        };
    }, [isWarningModalOpen, navigationIndex]);

    return isWarningModalOpen ? (
        <StandbyModalWrapper
            isOpen={isWarningModalOpen}
            onRequestClose={() => setWarningModalOpen(false)}
        />
    ) : null;
};

import { ETK_CESAR } from "@constants";
import PropTypes from "prop-types";
import { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import { getProductTitle } from "@components/OrderTaking/Helpers";
import { getPrice } from "@components/OrderTaking/Helpers/getPrice";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

import "swiper/swiper-bundle.min.css";

export function ProductSizeSwiperMoreThanFour({
    productProperties,
    selectedSizeIndex,
    setSelectedSizeIndex,
    visiblePrice,
    listOptions,
    productPrice,
    productImage,
}) {
    let optionPrice = 0;
    if (visiblePrice) {
        const { price } = getPrice(listOptions, productPrice, false);
        optionPrice = price;
    }
    const firstLimit =
        selectedSizeIndex + 1 <= productProperties.length - 1
            ? selectedSizeIndex + 1
            : 0;

    const secondLimit =
        selectedSizeIndex - 1 >= 0
            ? selectedSizeIndex - 1
            : productProperties.length - 1;

    const { devise } = useSnapshot(kioskStore);
    const { cardType } = useSelector((state) => state.orderSlice);
    return (
        <Swiper
            className="product-size-swiper-container-item-more-than-four"
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView="auto" //auto for more 3
            loop={true}
            coverflowEffect={{
                rotate: 0,
                stretch: -35, //-40 // -10 for 2
                depth: 225, //225 //300 for 2
                modifier: 1,
                slideShadows: false,
            }}
            slideToClickedSlide={true}
            onActiveIndexChange={(swiper) =>
                setSelectedSizeIndex(swiper.realIndex)
            }
            spaceBetween={32}
            // loopedSlides={productProperties.length}
            modules={[EffectCoverflow, Pagination]}
        >
            {productProperties.map((item, index) => {
                const optionTitle = getProductTitle(item.name);
                const itemImage =
                    cardType === ETK_CESAR
                        ? productImage
                        : getFilenameFromUrl(item.image, "catalog");
                return (
                    <SwiperSlide
                        key={item.id}
                        className="product-size-swiper-slide-img product-size-swiper-slide"
                        onClick={() => {
                            setSelectedSizeIndex(index);
                        }}
                        style={{
                            opacity:
                                index === selectedSizeIndex
                                    ? "1"
                                    : (index !== selectedSizeIndex &&
                                          index === secondLimit) ||
                                      index === firstLimit
                                    ? "0.5"
                                    : "0.25",
                        }}
                    >
                        <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ gap: "15px" }}
                        >
                            <div
                                className="swiper-slide-product-name"
                                style={{
                                    marginTop:
                                        index !== selectedSizeIndex
                                            ? "56px"
                                            : "unset",
                                }}
                            >
                                {optionTitle}
                            </div>
                            {index === selectedSizeIndex && visiblePrice ? (
                                <div className="swiper-slide-product-price">
                                    <span className="price-text">
                                        +
                                        {optionPrice.toFixed(
                                            devise.decimalPrice
                                        ) + ` ${devise.deviseSymbole}`}
                                    </span>
                                </div>
                            ) : null}
                            <div
                                className="swiper-slide-product-img"
                                style={{
                                    border:
                                        index === selectedSizeIndex
                                            ? "3px solid var(--global-color)"
                                            : "unset",
                                }}
                            >
                                <img
                                    src={itemImage}
                                    style={{ borderRadius: "35px" }}
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

ProductSizeSwiperMoreThanFour.propTypes = {
    productProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSizeIndex: PropTypes.number,
    setSelectedSizeIndex: PropTypes.func,
    visiblePrice: PropTypes.bool,
    listOptions: PropTypes.array,
    productPrice: PropTypes.object,
    productImage: PropTypes.string,
};

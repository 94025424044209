import { useSnapshot } from "valtio";

import React from "react";

import { ChangeMachineRenderModalWrapper } from "@components/common/ChangeMachineRenderModalWrapper";
import { CommandInsertionProblemModalWrapper } from "@components/common/CommandInsertionProblemModalWrapper";
import { WithdrawalProblemModalWrapper } from "@components/common/WithdrawalProblemModalWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function ContainerContent(): JSX.Element {
    const {
        isCommandInsertionProblemModalOpened,
        isWithdrawalProblemModalOpened,
        isChangeMachineRenderModalOpened,
    } = useSnapshot(kioskStore);

    return (
        <React.Fragment>
            {isCommandInsertionProblemModalOpened === true ? (
                <CommandInsertionProblemModalWrapper />
            ) : null}
            {isWithdrawalProblemModalOpened === true ? (
                <WithdrawalProblemModalWrapper />
            ) : null}
            {isChangeMachineRenderModalOpened === true ? (
                <ChangeMachineRenderModalWrapper />
            ) : null}
        </React.Fragment>
    );
}

import { CardMedia } from "@material-ui/core";
import { motion } from "framer-motion/dist/framer-motion";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import { useVideoReplay } from "@hooks/index";

import { LogoWrapper } from "@components/OrderTaking/common/LogoWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export default function OrderTakingTopBanner() {
    const { t } = useTranslation();
    const refVideo = React.useRef(null);
    const { nestedCategories } = useSelector((state) => state.orderSlice);
    useVideoReplay(refVideo, [refVideo, nestedCategories]);

    const { isPrm } = useSnapshot(kioskStore);

    const { workflowData } = useSelector((state) => state.workFlowDataSlice);

    const { isSummaryLogoActive } = useSelector(
        (state) => state.settingSlice.summary,
        shallowEqual
    );

    const { categories } = useSelector(
        (state) => state.orderSlice.globalcard,
        shallowEqual
    );

    const selectedCategory =
        categories[nestedCategories[nestedCategories.length - 1]];

    const topBannerContent =
        selectedCategory && selectedCategory?.video?.url
            ? selectedCategory?.video.url
            : "";

    const topBannerContentImg = getFilenameFromUrl(topBannerContent, "catalog");
    const isVideo = ["mp4", "webm"].includes(topBannerContent.split(".").pop());

    if (isVideo) {
        if (refVideo?.current?.error === null) {
            if (workflowData.length > 0) {
                refVideo?.current?.pause();
            } else {
                refVideo?.current?.play();
            }
        }
    }

    return (
        <motion.div
            key={topBannerContentImg}
            style={{ height: "220px" }}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: 100 }}
        >
            {topBannerContent !== "" ? (
                isVideo ? (
                    <CardMedia
                        key={topBannerContent}
                        ref={refVideo}
                        autoPlay={true}
                        muted={true}
                        component="video"
                        type="video/webm"
                        image={
                            topBannerContentImg !== ""
                                ? topBannerContentImg
                                : topBannerContent
                        }
                        style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                        }}
                    />
                ) : (
                    <CardMedia
                        component="img"
                        image={topBannerContentImg}
                        style={{
                            objectFit: "fill",
                            height: "100%",
                            width: "100%",
                        }}
                    />
                )
            ) : (
                <div
                    className="d-flex align-items-center px-3"
                    style={{
                        objectFit: "fill",
                        height: "220px",
                        width: "100%",
                        background: "black",
                        gap: "30px",
                    }}
                >
                    {isSummaryLogoActive ? (
                        <div className="centered-items">
                            <LogoWrapper
                                width={isPrm ? 100 : 140}
                                height={isPrm ? 100 : 140}
                            />
                        </div>
                    ) : null}
                    <div className="d-flex align-items-center  w-100">
                        <span className="welcome-text">
                            {t("Welcome").toUpperCase()} !
                        </span>
                    </div>
                </div>
            )}
        </motion.div>
    );
}
export const MemoisedOrderTakingTopBanner = React.memo(OrderTakingTopBanner);

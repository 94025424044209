/* eslint-disable @typescript-eslint/no-explicit-any */
import { VALIDATED_EXTENSION_FILE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

type ImageBackgroundType = {
    PayButton: any;
    imageUrl: string;
    opacityStyle: string | undefined;
    DisplayedOrderPrice: string;
    setImgColor: React.Dispatch<React.SetStateAction<string>>;
    isSummery?: boolean;
    handleBasketActionsButtonsOnClickEvent: (button: any) => void;
    width: string;
};

export function PaymentActionButton({
    PayButton,
    imageUrl,
    opacityStyle,
    DisplayedOrderPrice,
    handleBasketActionsButtonsOnClickEvent,
    isSummery = false,
    width,
}: ImageBackgroundType): JSX.Element {
    const [imgColor, setImgColor] = React.useState("#141414");

    const { isPrm } = useSnapshot(kioskStore);
    const isCustomButton = VALIDATED_EXTENSION_FILE.includes(
        imageUrl.substr(-4)
    );

    React.useEffect(() => {
        const canvasTag = document.createElement("canvas");

        if (canvasTag) {
            const ctx = canvasTag.getContext("2d");

            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.src = imageUrl;

            image.onload = () => {
                // Draw the image onto the canvas
                ctx?.drawImage(image, 0, 0);

                // Get the pixel data of the top-left corner (position: 10,10)
                const pixelData = ctx?.getImageData(10, 10, 1, 1).data;

                // Extract RGB values from the pixel data
                if (pixelData) {
                    const red = pixelData[0];
                    const green = pixelData[1];
                    const blue = pixelData[2];

                    const luminance =
                        (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
                    setImgColor(luminance > 0.5 ? "#141414" : "#ffffff");
                }
            };
        }
    }, [imageUrl]);

    return (
        <div
            className="total-order-footer"
            onClick={() => {
                handleBasketActionsButtonsOnClickEvent(PayButton);
            }}
        >
            {isCustomButton ? (
                <img
                    alt="payment button"
                    src={imageUrl}
                    style={{
                        opacity: opacityStyle,
                        width: width,
                        height: "65px",
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={imageUrl}
                    opacity={opacityStyle}
                    width={width}
                    height={"65px"}
                />
            )}
            <div
                className="total-order px-4"
                style={{
                    color: isCustomButton ? imgColor : "#FFF",
                    top: isSummery || isPrm ? "none" : "2rem",
                    bottom: isSummery || isPrm ? "1.5rem" : "none",
                    width: isPrm && !isSummery ? "42%" : "54%",
                    opacity: opacityStyle,
                }}
            >
                {DisplayedOrderPrice}
            </div>
        </div>
    );
}

import { t } from "i18next";
import PropTypes from "prop-types";

import React, { lazy } from "react";

// import { Virtualized } from "./Virtualized";
import useIsInViewport from "@components/OrderTaking/Components/Products/ProductWrapper/hooks/useIsInViewport";

const MemoizedCardProduct = lazy(() =>
    import(
        "@components/OrderTaking/Components/Products/ProductWrapper/CardProduct"
    )
);

export function ResponsiveItemsList({
    visibleItems,
    currentWorkflow,
    orderItems,
    isWorkFlow,
    gridSystem,
    workflowData,
    orderWorkflowItemSlice,
}) {
    const { nbrColumn, nbrRows } = gridSystem;
    let nbItem = nbrColumn * nbrRows;
    const lastElementRef = React.useRef(null);
    // detect if element display on viewport
    const isScrollIconHidden = useIsInViewport(lastElementRef, visibleItems);

    function getList(startIndex, endIndex) {
        return (
            <div
                key={startIndex}
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${nbrColumn},${1}fr)`,
                    gridTemplateRows: `repeat(${nbrRows}, 1fr)`,
                    columnGap:
                        nbrColumn === 2
                            ? "35px"
                            : isWorkFlow
                            ? "20px"
                            : `${12 - 2 * nbrColumn}px`,
                    rowGap: `${12 - 2 * nbrColumn}px`,
                    minHeight: "98%",
                    marginBottom: "10px",
                    marginTop: "10px",
                    justifyContent: "space-evenly",
                }}
            >
                {visibleItems.slice(startIndex, endIndex).map((product) => {
                    return (
                        <div
                            key={product?.iuud}
                            className="product-card"
                            ref={
                                visibleItems.length === endIndex
                                    ? lastElementRef
                                    : null
                            }
                        >
                            <MemoizedCardProduct
                                product={product}
                                currentWorkflow={currentWorkflow}
                                orderItems={orderItems}
                                workflowData={workflowData}
                                orderWorkflowItemSlice={orderWorkflowItemSlice}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    function getBloc() {
        const totalItems = visibleItems.length;

        const grids = [];

        for (let nb = 0; nb < totalItems; nb += nbItem) {
            const endIdx = Math.min(nb + nbItem, totalItems);

            grids.push(getList(nb, endIdx, grids));
        }

        return grids;
    }
    const itemsToView = getBloc();

    return (
        <>
            {itemsToView}.
            {!isScrollIconHidden && itemsToView.length > 1 ? (
                <button
                    className="d-flex flex-column"
                    style={{
                        border: "none",
                        background: "none",
                        position: "sticky",
                        bottom: "2%",
                        zIndex: "9999",
                        width: "min-content",
                        justifyContent: "center",
                        alignItems: "center",
                        left: "100%",
                    }}
                    onClick={() => {
                        lastElementRef.current &&
                            lastElementRef.current.scrollIntoView(true, {
                                behavior: "smooth",
                            });
                    }}
                >
                    <div
                        className="chevron"
                        style={{
                            position: "sticky",
                            bottom: "2%",
                        }}
                    ></div>
                    <div
                        className="chevron"
                        style={{
                            position: "sticky",
                            bottom: "2%",
                        }}
                    ></div>
                    <div
                        className="chevron"
                        style={{
                            position: "sticky",
                            bottom: "2%",
                        }}
                    ></div>
                    <span className="text">{t("Scroll")}</span>
                </button>
            ) : null}
        </>
    );
}
ResponsiveItemsList.propTypes = {
    currentWorkflow: PropTypes.object,
    visibleItems: PropTypes.array,
    orderItems: PropTypes.array,
    isWorkFlow: PropTypes.bool,
    gridSystem: PropTypes.object,
    workflowData: PropTypes.array,
    orderWorkflowItemSlice: PropTypes.object,
};

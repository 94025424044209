import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE, TCPOS } from "@constants";
import swal from "@sweetalert/with-react";
import classNames from "classnames";
import { t } from "i18next";
import { snapshot, useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import Modal from "react-modal";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { store } from "@store";

import { useLocalWays } from "@hooks/useLocalWays";

import { AdvertisingScreen } from "@components/LocalSettings/LocalConfigModalWrapper/AdvertisingScreen";
import { CustomerAccount } from "@components/LocalSettings/LocalConfigModalWrapper/CustomerAccount";
import { InformationModes } from "@components/LocalSettings/LocalConfigModalWrapper/InformationModes";
import { Languages } from "@components/LocalSettings/LocalConfigModalWrapper/Languages";
import { Options } from "@components/LocalSettings/LocalConfigModalWrapper/Options";
import { RegulationModes } from "@components/LocalSettings/LocalConfigModalWrapper/RegulationModes";
import { SalesModes } from "@components/LocalSettings/LocalConfigModalWrapper/SalesModes";
import { KioskAppLogoSvgIcon } from "@components/SvgIcons";
import { MenuSvgIcon } from "@components/SvgIcons/MenuSvgIcon";
import {
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setActiveConfigOptions,
    setActiveLanguages,
    setActiveTabTitle,
    setIsAuthenticationModalOpened,
    setIsConfigOptionsActive,
    setIsLanguagesActive,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setKioskWays,
    setProject,
    setQrCodeValue,
} from "@pages/Kiosk/store";

import ConfirmationSystemActionsModelWrapper from "../ConfirmationSystemActionsModalWrapper";
import { MenuModalWrapper } from "../MenuModalWrapper";
import "./index.css";
import { Tcpos } from "./Tcpos";

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
};
enum systemActionsType {
    NoAction = "NoAction",
    Reset = "reset",
    ChangeMiddleware = "Change Middleware",
    Active = "pause",
    Update = "update",
    Restart = "restart",
    Close = "close",
    SwitchOff = "switch off",
}
export function LocalConfigModalWrapper(): JSX.Element {
    const { posEditor } = useSnapshot(store);
    const { isLocalConfigModalOpened, localProject } = useSnapshot(kioskStore);
    const localKioskWays = useLocalWays(localProject);
    const { selectedShopApp, connectedUser } = useSnapshot(syncStore);
    const [isMenuModalOpened, setIsMenuModalOpened] =
        React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [systemActions, setSystemActions] = React.useState<systemActionsType>(
        systemActionsType.NoAction
    );
    const [isSystemConfirmationOpen, setIsSystemConfirmationOpen] =
        React.useState<boolean>(false);

    const tabs: TabsMenuType[] = [
        {
            title: t("Options"),
            content: <Options />,
        },
        {
            title: t("Sales methods"),
            content: <SalesModes />,
        },
        {
            title: t("Regulation modes"),
            content: <RegulationModes />,
        },
        {
            title: t("Information modes"),
            content: <InformationModes />,
        },
        {
            title: t("Customer account"),
            content: <CustomerAccount />,
        },
        {
            title: t("Languages"),
            content: <Languages />,
        },
        {
            title: t("Advertising screen"),
            content: <AdvertisingScreen />,
        },
    ];

    if (posEditor === TCPOS) {
        const additionalTab = {
            title: t("TCPOS"),
            content: <Tcpos />,
        };
        tabs.push(additionalTab);
    }
    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const handleCloseModal = () => {
        setIsAuthenticationModalOpened(false);
        setIsLocalSettingModalOpened(false);
        setIsLocalConfigModalOpened(false);
        setIsMenuModalOpened(false);
        setQrCodeValue("");
        setLoginKeyboardInputsValues({
            email: "",
            password: "",
        });
    };

    function handleOnClickEvent() {
        const { localProject } = snapshot(kioskStore);

        setIsConfigOptionsActive(
            (
                (localProject.template.content as ProjectContentType)
                    .options as OptionsType
            ).active
        );

        setActiveConfigOptions(
            (
                (localProject.template.content as ProjectContentType)
                    .options as OptionsType
            ).items.filter((option) => option.active === true)
        );

        setIsLanguagesActive(
            (
                (localProject.template.content as ProjectContentType)
                    .languages as LanguagesType
            ).active &&
                (
                    (localProject.template.content as ProjectContentType)
                        .languages as LanguagesType
                )?.items.filter((option) => option.active === true).length > 1
        );

        setActiveLanguages(
            (
                (localProject.template.content as ProjectContentType)
                    .languages as LanguagesType
            ).items.filter((language) => language.active === true)
        );
        setKioskWays(localKioskWays);

        setProject(localProject);

        handleCloseModal();

        return swal({
            icon: "success",
            content: (
                <p>
                    {t("Template saved successfully")}!
                    <br />
                    {t(
                        "Please note, the settings modified locally will be overwritten when of the next synchronization with the ETK back office."
                    )}
                </p>
            ),
            buttons: false,
            timer: 2000,
        });
    }

    React.useEffect(() => {
        setActiveTabTitle(tabs[0].title);
    }, []);

    return (
        <>
            <ConfirmationSystemActionsModelWrapper
                setSystemActions={setSystemActions}
                systemActions={systemActions}
                setIsSystemConfirmationOpen={setIsSystemConfirmationOpen}
                isSystemConfirmationOpen={isSystemConfirmationOpen}
            />
            <MenuModalWrapper
                setIsSystemConfirmationOpen={setIsSystemConfirmationOpen}
                isMenuModalOpened={isMenuModalOpened}
                setIsMenuModalOpened={setIsMenuModalOpened}
                setSystemActions={setSystemActions}
            />
            <Modal
                isOpen={isLocalConfigModalOpened}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "none",
                        zIndex: 2,
                    },
                    content: {
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px",
                        color: "black",
                        height: "1366px",
                        width: "914px",
                        zIndex: 2,
                        backgroundColor: "#FFFFFF",
                        filter: isSystemConfirmationOpen ? "blur(5px)" : "none",
                        borderLeft: "1px solid #707070",
                        borderRight: "1px solid #707070",
                        borderBottom: "1px solid #707070",
                        borderTop: "15px",
                        bottom: "unset",
                        top: "unset",
                        right: "unset",
                        left: "unset",
                        zoom: `${
                            process.env.REACT_APP_MODE === CATALOGUE_MODE
                                ? "50%"
                                : "100%"
                        }`,
                    },
                }}
            >
                <div
                    style={{
                        display: "grid",
                        height: 160,
                        width: 912,
                        position: "relative",
                        gridTemplateColumns: "2fr 3fr",
                        gridTemplateRows: "1fr",
                        background: "#191919",
                    }}
                >
                    {/* <video
                        autoPlay
                        muted
                        loop
                        style={{
                            objectFit: "cover",
                            position: "fixed",
                            height: 160,
                            width: 914,
                            zIndex: -1,
                        }}
                    >
                        <source
                            src="./images/background.webm"
                            type="video/mp4"
                        />
                    </video> */}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "40px",
                            gap: 70,
                        }}
                    >
                        <KioskAppLogoSvgIcon width="300" height="125" />
                        <div
                            style={{
                                height: "125px",
                                width: "1px",
                                borderRight: "1px solid #c4c2c2",
                            }}
                        />
                    </div>

                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ gap: 50, marginBottom: "10px" }}
                    >
                        <div className="d-flex flex-column">
                            <div
                                className="d-flex"
                                style={{
                                    color: "#FFFFFF",
                                    font: "normal bold 30px / 55px Segoe UI",
                                    textAlign: "left",
                                    letterSpacing: "1.2px",
                                    gap: "10px",
                                }}
                            >
                                <span>{t("Hello")}</span>
                                <span className="text-capitalize">
                                    {" "}
                                    {connectedUser.firstName}
                                </span>
                            </div>
                            <div
                                style={{
                                    color: "#FFFFFF",
                                    font: "normal 27px/36px Segoe UI",
                                    textAlign: "left",
                                    letterSpacing: "1.2px",
                                }}
                            >
                                {selectedShopApp.name as string}
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    height: 20,
                                    gap: 10,
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "50%",
                                        borderStyle: "solid",
                                        borderWidth: 0.5,
                                        borderColor: "white",
                                        backgroundColor: "#00d640",
                                        height: 12,
                                        width: 12,
                                    }}
                                />
                                <div
                                    style={{
                                        color: "#FFFFFF",
                                        font: "normal normal normal 15px/55px Segoe UI",
                                        textAlign: "left",
                                        letterSpacing: "1.2px",
                                    }}
                                >
                                    votre borne est à jour
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <Avatar
                                email={connectedUser.email as string}
                                name={connectedUser.firstName as string}
                                size="70px"
                                round={true}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "8fr 1fr",
                        height: "1203px",
                        display: "grid",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 4fr",
                            gridTemplateRows: "1fr",
                            margin: "20px",
                            border: "1px solid #707070",
                            borderRadius: "10px",
                        }}
                        onClick={() => {
                            setIsMenuModalOpened(false);
                        }}
                    >
                        <div
                            style={{
                                background: "#323333",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                            }}
                        >
                            <Nav
                                tabs={true}
                                className="d-flex flex-column"
                                style={{
                                    backgroundColor: "#323333",
                                    border: "unset",
                                    borderRadius: "10px",
                                    marginTop: 40,
                                }}
                            >
                                {tabs.map((element, index) => {
                                    return (
                                        <NavItem
                                            key={element.title}
                                            className="pb-1"
                                            style={{
                                                borderRadius: "10px",
                                                marginBottom: 20,
                                            }}
                                        >
                                            <NavLink
                                                disabled={
                                                    (element?.title ===
                                                        t("Customer account") &&
                                                        (
                                                            localProject
                                                                .template
                                                                .content
                                                                .options as OptionsType
                                                        ).items[0]?.active ===
                                                            false) ||
                                                    (element.title ===
                                                        t(
                                                            "Information modes"
                                                        ) &&
                                                        (
                                                            localProject
                                                                .template
                                                                .content
                                                                .informationModes as ProjectContentItemType
                                                        ).items.length === 0)
                                                }
                                                className={classNames(
                                                    "border-0 menu-wrapper-nav-link__clz",
                                                    {
                                                        "active menu-wrapper-items-nav-link-active__clz":
                                                            activeTab === index,
                                                    }
                                                )}
                                                onClick={() => {
                                                    toggleNav(index);
                                                    setActiveTabTitle(
                                                        element.title
                                                    );
                                                }}
                                            >
                                                <span className="px-2">
                                                    {element.title}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                        </div>
                        <div>
                            <TabContent activeTab={activeTab}>
                                {tabs.map((element, index) => {
                                    return (
                                        <TabPane
                                            tabId={index}
                                            key={element.title}
                                            className={classNames("h-100", {
                                                "design-active-tab__clz":
                                                    activeTab === 4,
                                            })}
                                        >
                                            {element.content}
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            background: " #E9E9E9 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #FFFFFF",
                        }}
                    >
                        <MenuSvgIcon
                            style={{
                                height: "75px !important",
                                width: "75px !important",
                                marginLeft: "15px",
                            }}
                            onClick={() => {
                                setIsMenuModalOpened(!isMenuModalOpened);
                            }}
                        />
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ marginRight: "15px" }}
                        >
                            <StyledButton
                                rounded={true}
                                className="m-2"
                                style={{
                                    width: "179px",
                                    height: "75px",
                                    font: "normal normal 600 18px/24px Segoe UI",
                                    background:
                                        "#FFFFFF 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border: "1px solid #C9C9C9",
                                    borderRadius: "12px",
                                    color: "black",
                                }}
                                onClick={handleCloseModal}
                            >
                                {t("Leave")}
                            </StyledButton>
                            <StyledButton
                                rounded={true}
                                className="m-2"
                                style={{
                                    width: "179px",
                                    height: "75px",
                                    background: "#545454",
                                    font: "normal normal 600 18px/24px Segoe UI",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border: "1px solid #FFFFFF",
                                    borderRadius: "12px",
                                    marginRight: "0px",
                                }}
                                onClick={handleOnClickEvent}
                            >
                                {t("Save")}
                            </StyledButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import moment from "moment";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import { formatCurrency, getPreviousSkippedStepNumber } from "@helpers/general";

import {
    kioskStore,
    setIsAmountReturned,
    setIsChangeMachineRenderModalOpened,
    setIsCreationVoucherCanceled,
    setIsRefundAnomalyModalOpened,
    setIsRegulationModesModalOpened,
    setIsWithdrawalProblemModalOpened,
    setNavigationIndex,
    setTotalAmountDeposited,
} from "@pages/Kiosk/store";

import "./index.css";

export function WithdrawalProblemModalWrapper(): JSX.Element {
    const {
        isPrm,
        project: {
            template: { content },
        },
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isWithdrawalProblemModalOpened,
        isCommandInsertionProblemModalOpened,
        voucherAmount,
        returnedAmount,
        isEventStreamProblem,
        isCreationVoucherCanceled,
        isCashMachineTransactionCanceled,
        isChangeMachineRenderModalOpened,
        isRegulationModeModalGiveUpButtonClicked,
    } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);

    const [counter, setCounter] = React.useState<number>(0);

    async function handleCreateVoucher() {
        const timestamp = new Date().getTime();
        const voucher = {
            echeance: moment(
                new Date().setMonth(new Date().getMonth() + 2)
            ).format("YYYY-MM-DD"),
            montant: isCommandInsertionProblemModalOpened
                ? returnedAmount
                : voucherAmount,
            mode_reglement: "ESP",
        };
        console.log(
            "handleCreateVoucher func in WithdrawalProblemModalWrapper component, the voucher",
            JSON.stringify(voucher)
        );
        const apiUrl = `${middlewareApiUrl}/PutBonAvoir?timestamp=${timestamp}`;
        console.log("handle of the api:", { apiUrl });
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(voucher),
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log(
                        "the response of Putting voucher api is:",
                        JSON.stringify(result)
                    );
                    if (result.code === 2) {
                        console.log(
                            `A voucher with the amount: ${
                                isCommandInsertionProblemModalOpened
                                    ? returnedAmount
                                    : voucherAmount
                            } is created`
                        );
                        handlePrintVoucher(result.id_avoir);
                    }
                })
                .catch((error) =>
                    console.error(
                        `Error while inserting the voucher, with the message:${error.message}`
                    )
                )
        );
    }

    async function handlePrintVoucher(voucherId: string): Promise<void> {
        const timestamp = new Date().getTime();
        console.log(
            "handlePrintVoucher with the ID:",
            { voucherId },
            "the launched api:",
            `${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}`
        );
        await fetch(
            `${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}&timestamp=${timestamp}`
        )
            .then((response) => response.json())
            .then(async (result) => {
                console.log(
                    "the response of printing a voucher:",
                    JSON.stringify(result)
                );
                if (result.code === 2) {
                    console.log("The voucher is printed successfully!!");
                    if (isCommandInsertionProblemModalOpened === true) {
                        console.log(
                            "There is a Command Insertion Problem so this voucher is the proof of the returned amount"
                        );
                        setIsAmountReturned(true);
                    } else if (isCashMachineTransactionCanceled) {
                        console.log(
                            "The cash machine transaction is canceled after a timeout and the change machine cannot return the deposited amount, so this voucher replace the amount deposited"
                        );
                        setNavigationIndex(0);
                    } else {
                        console.log(
                            `A voucher with the amount: ${
                                isCommandInsertionProblemModalOpened
                                    ? returnedAmount
                                    : voucherAmount
                            } has been printed successfully`
                        );
                        console.log(
                            "In handle print voucher function:",
                            JSON.stringify({
                                isCreationVoucherCanceled,
                                isEventStreamProblem,
                            })
                        );

                        if (isEventStreamProblem || isCreationVoucherCanceled) {
                            if (isEventStreamProblem) {
                                console.log(
                                    "We are coming from withdrawal problem after canceling transaction"
                                );
                            } else {
                                console.log(
                                    "We are coming from withdrawal problem after creating voucher canceled"
                                );
                            }
                            setIsRegulationModesModalOpened(true);
                        } else {
                            if (isRegulationModeModalGiveUpButtonClicked) {
                                setNavigationIndex(0);
                            } else {
                                setNavigationIndex(
                                    navigationIndex -
                                        Number(
                                            await getPreviousSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue
                                            )
                                        )
                                );
                            }
                        }
                        setIsCreationVoucherCanceled(false);
                        setTotalAmountDeposited(0);
                    }
                    setIsWithdrawalProblemModalOpened(false);
                    setIsRefundAnomalyModalOpened(false);
                    if (isChangeMachineRenderModalOpened) {
                        setIsChangeMachineRenderModalOpened(false);
                    }
                }
            })
            .catch((error) =>
                console.error(
                    `Error while printing the voucher, with the message:${error.message}`
                )
            );
    }

    React.useEffect(() => {
        let timer: NodeJS.Timeout;

        if (counter >= 0) {
            timer = setTimeout(() => setCounter(counter + 1), 1000);
        }

        if (counter === 5) {
            handleCreateVoucher();
        }

        return () => clearTimeout(timer);
    }, [counter]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isWithdrawalProblemModalOpened}
            contentLabel="help-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "950px",
                    width: "800px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (content.generalDesign as GeneralDesignType).colors[0]
                            .content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "300px" }}
            >
                <img src="./images/exclamation-icon.png" />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Collect your voucher")}
                </span>
            </div>
            <div style={{ height: "200px" }}></div>
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <span
                    style={{ font: "normal normal normal 35px/47px Segoe UI" }}
                >
                    {t(
                        "Following a reimbursement anomaly, a voucher for an amount of {{voucherAmount}} is created.",
                        {
                            voucherAmount: formatCurrency(
                                isCommandInsertionProblemModalOpened
                                    ? returnedAmount
                                    : voucherAmount
                            ),
                        }
                    )}
                </span>
            </div>
        </Modal>
    );
}

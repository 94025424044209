import { BASIC_COMPOSITION_TYPE } from "@constants";

export function getLoyaltyTotal(orderItems) {
    let sum = 0;

    let order = JSON.parse(JSON.stringify(orderItems));

    order.forEach((item) => {
        let localShopCartSum = item?.fidelity ? item?.fidelity : 0;

        if (Object.keys(item?.shopCart).length > 0) {
            Object.values(item.shopCart).forEach((el) => {
                if (el.type !== BASIC_COMPOSITION_TYPE) {
                    localShopCartSum += getSum(el.compositions);
                }
            });
        }
        sum += localShopCartSum * item.quantity;
    });

    return sum;
}

function getSum(compositions) {
    let localSum = 0;

    Object.values(compositions).forEach((product) => {
        let localProductSum = product.fidelity ? product?.fidelity : 0;
        if (
            product.compositions !== undefined &&
            Object.keys(product.compositions).length > 0
        ) {
            localProductSum = localProductSum + getSum(product.compositions);
        }
        localSum += localProductSum;
    });

    return localSum;
}

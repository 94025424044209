/* eslint-disable @typescript-eslint/no-explicit-any */
import { LockIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { handleUserAuthentication } from "@helpers/general";

import KioskKeyboard from "@components/common/KioskKeyboard";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function UsualKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        isAuthenticationErrorModalOpened,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        authModalStepIndex,
        orderTotalPrice,
        customerLanguage,
    } = useSnapshot(kioskStore);

    const [inputs, setInputs] = React.useState<KeyboardInput>({});
    const [inputName, setInputName] = React.useState<string>("");
    // eslint-disable-next-line
    const keyboard = React.useRef<any>(null);
    // eslint-disable-next-line
    const inputRef1 = React.useRef<any>(null);
    // eslint-disable-next-line
    const inputRef2 = React.useRef<any>(null);

    // eslint-disable-next-line

    const onChangeAll = (inputs: KeyboardInput) => {
        setInputs({ ...inputs });
    };

    const getInputValue = (inputName: string) => {
        return inputs[inputName] || "";
    };

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;
        setInputs({
            ...inputs,
            [inputName]: inputVal,
        });
        keyboard.current.setInput(inputVal);
    };

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center py-1"
            style={{
                gap: "10px",
                width: "1060px",
                boxShadow: "0px 3px 6px #0000004F",
                border: "1px solid #707070",
                borderRadius: "20px",
            }}
        >
            <form
                className="p-4 d-flex flex-column justify-content-center align-items-center"
                style={{ gap: "15px" }}
            >
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        whiteSpace: "nowrap",
                        gap: "10px",
                    }}
                >
                    {t("E-mail address")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            width: "962px",
                            height: "65px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                    >
                        <FaRegEnvelope
                            className="m-3"
                            style={{
                                width: "40px",
                                height: "40px",
                                color: "#565555",
                            }}
                        />
                        <input
                            style={{
                                border: "none",
                                width: "920px",
                                height: "60px",
                            }}
                            id="email"
                            type="text"
                            value={getInputValue("email")}
                            onFocus={() => setInputName("email")}
                            placeholder={t("Enter your email", {
                                lng: customerLanguage.name,
                            })}
                            className="px-3"
                            onChange={onChangeInput}
                        />
                    </div>
                </label>

                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        gap: "10px",
                    }}
                >
                    {t("Password")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            width: "962px",
                            height: "65px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                    >
                        <LockIcon
                            className="m-3"
                            style={{
                                width: "40px",
                                height: "40px",
                                color: "#565555",
                            }}
                        />
                        <input
                            style={{
                                border: "none",
                                width: "920px",
                                height: "60px",
                            }}
                            id="password"
                            type="text"
                            value={getInputValue("password")}
                            onFocus={() => setInputName("password")}
                            placeholder={t("Enter your password", {
                                lng: customerLanguage.name,
                            })}
                            className="px-3"
                            onChange={onChangeInput}
                        />
                    </div>
                </label>
            </form>

            <KioskKeyboard
                enterClick={() => {
                    const authModeInfo: AuthModeInfoType = {
                        email: inputs[t("Enter your email")],
                        password: inputs[t("Enter your password")],
                    };

                    handleUserAuthentication(
                        authModeInfo,
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        authModalStepIndex,
                        isAuthenticationErrorModalOpened,
                        content,
                        Number(orderTotalPrice)
                    );
                }}
                keyboard={keyboard}
                onChangeAll={onChangeAll}
                inputName={inputName}
                preventMouseDownDefault={true}
                theme={"keyboard__clz hg-theme-default"}
                buttonTheme={[
                    {
                        class: "space-key__clz",
                        buttons: "{space}",
                    },
                ]}
            />
        </div>
    );
}

import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getProductTitle } from "@components/OrderTaking/Helpers";

import { updateNestedCategories } from "../Store/feature/OrderSlice";

function CategoriesBreadCrumb() {
    const dispatch = useDispatch();

    const nestedCategories = useSelector(
        (state) => state.orderSlice.nestedCategories,
        shallowEqual
    );
    const globalcard = useSelector(
        (state) => state.orderSlice.globalcard,
        shallowEqual
    );

    return (
        <Swiper
            className="d-flex justify-content-center py-1"
            slidesPerView={"auto"}
            direction="horizontal"
        >
            {nestedCategories.map((category, index) => {
                const title = getProductTitle(
                    globalcard.categories[category]?.name
                );
                return (
                    <SwiperSlide
                        key={index}
                        className={classNames(
                            " d-flex justify-content-center d-flex align-items-start authenticated-user-font  ",
                            {
                                "font-weight-bold__clz":
                                    index === nestedCategories.length,
                            }
                        )}
                        style={{
                            opacity:
                                index < nestedCategories.length - 1
                                    ? "35%"
                                    : "1",
                            cursor: "pointer",
                            width: "auto",
                        }}
                        onClick={() =>
                            index < nestedCategories.length - 1 &&
                            dispatch(updateNestedCategories(category))
                        }
                    >
                        <span
                            className={classNames(
                                "d-flex justify-content-center d-flex align-items-center mx-2 px-2",
                                {
                                    "font-weight-bold":
                                        index >= nestedCategories.length - 1,
                                }
                            )}
                        >
                            {title}
                        </span>

                        {index < nestedCategories.length - 1 && <div> / </div>}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

export default CategoriesBreadCrumb;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { getProductTitle } from "@components/OrderTaking/Helpers";

type AllergenCardType = {
    allergen: any;
    handleActiveAllergenOnClickEvent(id: any, group: any): void;
    group?: any;
    allergenIcon: string;
    isProduct: boolean;
};

export function AllergenCard({
    allergen,
    handleActiveAllergenOnClickEvent,
    group,
    allergenIcon,
    isProduct,
}: AllergenCardType): JSX.Element {
    const handleClick = (allergen: any, group: any) => {
        if (isProduct) {
            return;
        }
        handleActiveAllergenOnClickEvent(allergen.id, group);
    };

    const productTitle = getProductTitle(allergen.name);
    return (
        <div
            key={allergen.id}
            className="allergen-card__clz"
            onClick={() => {
                handleClick(allergen, group);
            }}
            style={{
                background:
                    allergen.active === true
                        ? "linear-gradient(180deg, black 40%, var(--global-color) 100%)"
                        : "transparent",
                color: allergen.active === true ? "white" : "black",
                height: "50px",
                lineHeight: "1em",
            }}
        >
            <span className="d-flex justify-content-center align-items-center">
                <img
                    src={allergenIcon}
                    style={{
                        height: "42px",
                        width: "42px",
                        backgroundColor:
                            allergen.active === true
                                ? "#938585"
                                : "var(--global-color)",
                        borderRadius: "100%",
                    }}
                />
            </span>
            <span
                className="d-flex justify-content-center align-items-center two-lines-preview-text"
                style={{
                    width: "175px",
                    height: "100%",
                }}
            >
                {productTitle}
            </span>
        </div>
    );
}

import { proxy, subscribe } from "valtio";

import { KeyboardInput } from "react-simple-keyboard";

type StoreType = {
    franchise: {
        id: string;
        schema: string;
    };
    isEntitySelectionModalOpened: boolean;
    isEntityChildrenSelectionModalOpened: boolean;
    isSyncConfirmationModalWrapperOpened: boolean;
    isSyncProgressModalWrapperOpened: boolean;
    selectedEntity: EntityType | undefined;
    selectedEntityChild: EntityType | undefined;
    selectedEntityChildren: EntityType[] | undefined;
    connectedUserEntity: string;
    connectedUser: ObjectType;
    loginKeyboardInputsValues: KeyboardInput;
    selectedShopApp: ObjectType;
    machineIpAddress: string;
    progressBarValue: number;
    isSyncLocalModalOpened: boolean;
    isConnectionModalOpen: boolean;
};

export const syncStore = proxy<StoreType>({
    franchise: {
        id: "",
        schema: "",
    },
    isEntitySelectionModalOpened: false,
    isEntityChildrenSelectionModalOpened: false,
    isSyncConfirmationModalWrapperOpened: false,
    isSyncProgressModalWrapperOpened: false,
    selectedEntity: undefined,
    selectedEntityChild:
        localStorage.getItem("selectedEntityChild") !== undefined
            ? JSON.parse(localStorage.getItem("selectedEntityChild") as string)
            : undefined,
    selectedEntityChildren: undefined,
    connectedUserEntity: "",
    connectedUser: {},
    loginKeyboardInputsValues: {},
    selectedShopApp:
        JSON.parse(localStorage.getItem("selectedShopApp") as string) || {},
    machineIpAddress:
        JSON.parse(localStorage.getItem("machineIpAddress") as string) ||
        "0.0.0.0",
    progressBarValue: 0,
    isSyncLocalModalOpened: false,
    isConnectionModalOpen: false,
});

subscribe(syncStore, () => {
    localStorage.setItem(
        "machineIpAddress",
        JSON.stringify(syncStore.machineIpAddress)
    );
});

export {
    setFranchiseId,
    setFranchiseSchema,
    setIsEntitySelectionModalOpened,
    setIsEntityChildrenSelectionModalOpened,
    setIsSyncConfirmationModalWrapperOpened,
    setSelectedEntity,
    setSelectedEntityChildren,
    setSelectedEntityChild,
    setIsSyncProgressModalWrapperOpened,
    setConnectedUserEntity,
    setLoginKeyboardInputsValues,
    setSelectedShopApp,
    setMachineIpAddress,
    setConnectedUser,
    setProgressBarValue,
    setIsSyncLocalModalOpened,
    setIsConnectionModalOpen,
} from "./actions";

import { AuresAvatar } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { KioskConfigLogoSvgIcon } from "@components/SvgIcons";

import { syncStore } from "../store";

export function Header(): JSX.Element {
    const { isAppSynchronized } = useSnapshot(store);

    const { connectedUser, selectedEntityChild } = useSnapshot(syncStore);

    return (
        <header
            style={{ height: "157px", background: "#191919", width: "912px" }}
        >
            {/* <video
                autoPlay={true}
                muted={true}
                loop={true}
                style={{
                    objectFit: "cover",
                    position: "fixed",
                    height: "157px",
                    width: "913px",
                    zIndex: -1,
                    background: "#191919",
                }}
            >
                <source src="./images/background.webm" type="video/mp4" />
            </video> */}
            <FlexboxGrid className="justify-content-between mx-5">
                <KioskConfigLogoSvgIcon width="300" height="157" />
                <div className="my-4 bg-white" style={{ width: "1px" }}></div>
                <FlexboxGrid gap="50px">
                    <FlexboxGrid
                        className="flex-column justify-content-end"
                        gap="15px"
                    >
                        <FlexboxGrid className="flex-column" gap="10px">
                            <div
                                style={{
                                    color: "#FFFFFF",
                                    font: "normal normal 600 24px/29px Segoe UI",
                                    textAlign: "left",
                                    letterSpacing: "0.96px",
                                }}
                            >
                                {t("Hello")}{" "}
                                <span className="text-capitalize">
                                    {connectedUser?.firstName as string}
                                </span>{" "}
                                !
                            </div>
                            <div
                                className="text-uppercase"
                                style={{
                                    color: "#FFFFFF",
                                    font: "normal normal normal 14px/17px Segoe UI",
                                    textAlign: "left",
                                    letterSpacing: "0px",
                                }}
                            >
                                {selectedEntityChild?.name}
                            </div>
                        </FlexboxGrid>
                        <FlexboxGrid
                            justifyContentCentered={true}
                            alignItemsCentered={true}
                            gap="10px"
                        >
                            <div
                                style={{
                                    borderRadius: "50%",
                                    borderStyle: "solid",
                                    borderWidth: 0.5,
                                    borderColor: "white",
                                    backgroundColor: isAppSynchronized
                                        ? "#00ff40"
                                        : "#ff0000",
                                    height: "12px",
                                    width: "12px",
                                }}
                            />
                            <div
                                style={{
                                    color: "#FFFFFF",
                                    font: "normal normal normal 15px/55px Nunito",
                                    textAlign: "left",
                                    letterSpacing: "1.2px",
                                }}
                            >
                                {t(
                                    isAppSynchronized
                                        ? "Your kiosk is synchronized"
                                        : "Your kiosk is not synchronized"
                                )}
                            </div>
                        </FlexboxGrid>
                    </FlexboxGrid>
                    <FlexboxGrid
                        justifyContentCentered={true}
                        alignItemsCentered={true}
                    >
                        <AuresAvatar
                            email={connectedUser.email as string}
                            name={connectedUser.firstName as string}
                            size="70px"
                            round={true}
                        />
                    </FlexboxGrid>
                </FlexboxGrid>
            </FlexboxGrid>
        </header>
    );
}

import { POSSIBLE_EXTENSIONS } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

type PropsType = {
    children: React.ReactNode;
    selectedMode?: string;
};

export function PreviewBackgroundWrapper({
    children,
    selectedMode,
}: PropsType): JSX.Element {
    const {
        project: {
            template: { pages },
        },
        kioskWays,
        navigationIndex,
        isPrm,
    } = useSnapshot(kioskStore);
    let filename = "";
    let extension = "";

    let backgroundImageId = pages.ways[kioskWays[navigationIndex].name] as
        | PageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
        | ConsommationPageType;

    if (
        (backgroundImageId === undefined ||
            backgroundImageId.background === undefined) &&
        selectedMode !== undefined &&
        selectedMode !== ""
    ) {
        backgroundImageId = pages.subSteps[selectedMode as string] as
            | PageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType
            | ConsommationPageType;
    }

    if (
        backgroundImageId !== undefined &&
        backgroundImageId.background !== undefined &&
        backgroundImageId.background.id !== undefined &&
        backgroundImageId.background.id !== ""
    ) {
        filename = getImageContentById(
            backgroundImageId.background.id as string
        );

        extension = filename.split(".")[filename.split(".").length - 1];
    }
    return (
        <React.Fragment>
            {POSSIBLE_EXTENSIONS.includes(extension) ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            objectFit: "cover",
                            height:
                                isPrm &&
                                kioskWays[navigationIndex].name !==
                                    "finalMessage" &&
                                kioskWays[navigationIndex].name !== "payment" &&
                                kioskWays[navigationIndex].name !==
                                    "loyaltyConsommation" &&
                                kioskWays[navigationIndex].name !==
                                    "salesMethods"
                                    ? "920px"
                                    : isPrm &&
                                      kioskWays[navigationIndex].name ===
                                          "salesMethods"
                                    ? "1920px"
                                    : "100%",
                            width: "100%",
                            position: "fixed",
                            zIndex: -2,
                        }}
                    >
                        <source src={filename} />
                    </video>
                    {children}
                </React.Fragment>
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${filename})`,
                        backgroundSize: "100% 100%",
                        height:
                            isPrm &&
                            kioskWays[navigationIndex].name !==
                                "finalMessage" &&
                            kioskWays[navigationIndex].name !== "payment" &&
                            kioskWays[navigationIndex].name !==
                                "loyaltyConsommation" &&
                            kioskWays[navigationIndex].name !== "salesMethods"
                                ? "920px"
                                : isPrm &&
                                  kioskWays[navigationIndex].name ===
                                      "salesMethods"
                                ? "1920px"
                                : "100%",
                        width: "100%",
                        zIndex:
                            kioskWays[navigationIndex].name === "salesMethods"
                                ? "unset"
                                : -2,
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}

export const getStepDetails = (stepComposition) => {
    const stepDetails = {
        selectedChoicesWithspecialPrice: 0,
        selectedChoicesWithStepPrice: 0,
    };
    Object.values(stepComposition).forEach((item) => {
        if (item.withSpecialPrice) {
            stepDetails.selectedChoicesWithspecialPrice += item.quantity;
        } else if (item.withStepPrice) {
            stepDetails.selectedChoicesWithStepPrice += item.quantity;
        }
    });
    localStorage.setItem("stepDetails", JSON.stringify(stepDetails));
    return stepDetails;
};

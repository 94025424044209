import { ETK_CESAR } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import { getProductTitle } from "@components/OrderTaking/Helpers";
import { getPrice } from "@components/OrderTaking/Helpers/getPrice";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function ProductSizeSwiperTwo({
    productProperties,
    selectedSizeIndex,
    setSelectedSizeIndex,
    visiblePrice,
    listOptions,
    productPrice,
    productImage,
}) {
    let optionPrice = 0;
    if (visiblePrice) {
        const { price } = getPrice(listOptions, productPrice, false);
        optionPrice = price;
    }
    const { devise } = useSnapshot(kioskStore);
    const { cardType } = useSelector((state) => state.orderSlice);
    return (
        <div className="product-size-swiper-container-item-equal-two">
            {productProperties.map((item, index) => {
                const optionTitle = getProductTitle(item.name);
                const itemImage =
                    cardType === ETK_CESAR
                        ? productImage
                        : getFilenameFromUrl(item.image, "catalog");
                return (
                    <div
                        key={item.id}
                        className="product-size-swiper-slide-img-item-equal-two product-size-swiper-slide-item-equal-two"
                        onClick={() => {
                            setSelectedSizeIndex(index);
                        }}
                        style={{
                            opacity: index !== selectedSizeIndex ? "0.5" : "1",
                            transform:
                                index !== selectedSizeIndex
                                    ? "scale(0.7)"
                                    : "scale(1)",
                        }}
                    >
                        <div
                            className="swiper-slide-product-name"
                            style={{
                                marginTop:
                                    index !== selectedSizeIndex
                                        ? "56px"
                                        : "unset",
                            }}
                        >
                            {optionTitle}
                        </div>
                        {index === selectedSizeIndex && visiblePrice ? (
                            <div className="swiper-slide-product-price">
                                <span className="price-text">
                                    +
                                    {optionPrice.toFixed(devise.decimalPrice) +
                                        ` ${devise.deviseSymbole}`}{" "}
                                </span>
                            </div>
                        ) : null}
                        <div
                            className="swiper-slide-product-img"
                            style={{
                                border:
                                    index === selectedSizeIndex
                                        ? "3px solid var(--global-color)"
                                        : "unset",
                            }}
                        >
                            <img
                                src={itemImage}
                                style={{ borderRadius: "35px" }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

ProductSizeSwiperTwo.propTypes = {
    productProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSizeIndex: PropTypes.number,
    setSelectedSizeIndex: PropTypes.func,
    visiblePrice: PropTypes.bool,
    listOptions: PropTypes.array,
    productPrice: PropTypes.object,
    productImage: PropTypes.string,
};

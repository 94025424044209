import { StyledModal } from "@aureskonnect/react-ui";
import { TCPOS } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { ModalBody } from "reactstrap";

import { setIsTechProblemModalOpened, store } from "@store";

import {
    getAlertActionButtonImageSrc,
    handleLedControl,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function TechnicalProblemModal(): JSX.Element {
    const { isTechProblemModalOpened, isLicenseProblem, posEditor } =
        useSnapshot(store);
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
        isLedActive,
        connectedLedPort,
        navigationIndex,
    } = useSnapshot(kioskStore);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsTechProblemModalOpened(false);
            if (isLedActive) {
                if (navigationIndex === 0) {
                    handleLedControl(connectedLedPort, "Green");
                } else {
                    handleLedControl(connectedLedPort, "Orange");
                }
            }
        }, 6000);
        return () => clearTimeout(timer);
    }, [isTechProblemModalOpened]);

    return (
        <StyledModal
            className="tech-problem-modal__clz"
            centered={true}
            isOpen={isTechProblemModalOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                <FlexboxGrid
                    justifyContentCentered={true}
                    alignItemsCentered={true}
                    gap="100px"
                    className="flex-column"
                >
                    <img
                        alt=""
                        src={alertImgSrc}
                        style={{
                            height: "124px",
                            width: "144px",
                            marginTop: "30px",
                        }}
                    />
                    <h2
                        className="text-uppercase text-center"
                        style={{
                            font: "normal normal bold 50px/55px Bebas Neue",
                            letterSpacing: "2px",
                            color: "#171717",
                        }}
                    >
                        {t("Technical problem")}
                    </h2>
                </FlexboxGrid>
                <div
                    className="d-flex flex-column align-items-center px-5"
                    style={{
                        font: "normal normal 300 30px/42px Segoe UI",
                        letterSpacing: "0.17px",
                        textAlign: "center",
                    }}
                >
                    {isLicenseProblem
                        ? t("Please verify your license")
                        : TCPOS === posEditor
                        ? t("The terminal is temporarily unavailable.")
                        : t("Please contact technical support")}
                </div>
            </ModalBody>
        </StyledModal>
    );
}

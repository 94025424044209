import React from "react";

type CommentOrderSummuryType = {
    comment: string;
};

export function CommentOrderSummury({
    comment,
}: CommentOrderSummuryType): JSX.Element {
    return (
        <div
            className="justify-content-start composed-product-comment mb-2 mt-1 "
            style={{
                height: "max-content",
                borderRadius: "15px",
            }}
        >
            <span
                className="d-flex justify-content-start pl-4"
                style={{
                    font: "normal 600 30px/48px Segoe UI",
                }}
            >
                {comment}
            </span>
        </div>
    );
}

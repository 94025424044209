import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function CustomerAccount(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="221"
            height="50"
            viewBox="0 0 221 50"
            {...props}
        >
            <g
                id="Groupe_57"
                data-name="Groupe 57"
                transform="translate(2005 3998)"
            >
                <g
                    id="Groupe_1"
                    data-name="Groupe 1"
                    transform="translate(500 -3752)"
                >
                    <g
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        transform="translate(-2505 -246)"
                        fill="#f8f8f6"
                        stroke="#141414"
                        strokeWidth="2"
                    >
                        <rect width="221" height="50" rx="9" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="219"
                            height="48"
                            rx="8"
                            fill="#f8f8f6"
                        />
                    </g>
                    <text
                        id="Mon_compte"
                        data-name="Mon compte"
                        transform="translate(-2377 -212)"
                        fill="#141414"
                        fontSize="24"
                        fontFamily="ArialMT, Arial"
                        textAnchor="middle"
                    >
                        {t("My account", {
                            lng: customerLanguage.name,
                        })}
                    </text>
                    <g
                        id="Layer_2"
                        data-name="Layer 2"
                        transform="translate(-2487.989 -236.999)"
                    >
                        <path
                            id="Tracé_1"
                            data-name="Tracé 1"
                            d="M16,17a6,6,0,1,1,6-6,6,6,0,0,1-6,6ZM16,7a4,4,0,1,0,4,4A4,4,0,0,0,16,7Z"
                            fill="#141414"
                        />
                        <path
                            id="Tracé_2"
                            data-name="Tracé 2"
                            d="M16,31A15,15,0,0,1,4.41,25.51l-.52-.64.52-.63a15,15,0,0,1,23.18,0l.52.63-.52.64A15,15,0,0,1,16,31ZM6.51,24.88a13,13,0,0,0,19,0,13,13,0,0,0-19,0Z"
                            fill="#141414"
                        />
                        <path
                            id="Tracé_3"
                            data-name="Tracé 3"
                            d="M16,31a15,15,0,1,1,11.59-5.49A15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z"
                            fill="#141414"
                        />
                        <path
                            id="Tracé_4"
                            data-name="Tracé 4"
                            d="M5.18,24.88S15.25,36.13,25.5,26l1.32-1.12S18.26,16,9.57,21.33Z"
                            fill="#141414"
                        />
                        <circle
                            id="Ellipse_1"
                            data-name="Ellipse 1"
                            cx="5"
                            cy="5"
                            r="5"
                            transform="translate(11 6)"
                            fill="#141414"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { transitions } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { store } from "@store";

import { handleLedControl } from "@helpers/general";

import { stepDataContent } from "@hooks/useStepDataContent";
import { useUpdateProjectSteps } from "@hooks/useUpdateProjectSteps ";

import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { setSetting } from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import { StandbyStep } from "@pages/Kiosk/Steps/StandbyStep";
import {
    kioskStore,
    setIsProjectMustBeUpdated,
    setLocalProject,
    setProject,
} from "@pages/Kiosk/store";

export function StepComponent(): JSX.Element {
    const {
        kioskWays,
        navigationIndex,
        isLedActive,
        connectedLedPort,
        isProjectMustBeUpdated,
        isLocalConfigModalOpened,
        isAnimationIn,
        isAuthenticationModalOpened,
    } = useSnapshot(kioskStore);

    React.useEffect(() => {
        stepDataContent(navigationIndex, kioskWays);
    }, [navigationIndex]);

    const { selectedShopApp } = useSnapshot(syncStore);
    const { middlewareApiUrl, isAppPaused } = useSnapshot(store);
    const dispatch = useDispatch();
    const catalogueIsLoading = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.orderSlice.catalogueIsLoading,
        shallowEqual
    );
    const Component = kioskWays[navigationIndex]?.stepContent;
    const chosenTransition = (transitions as TransitionsType)[
        kioskWays[navigationIndex].animation
    ];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const shopDetail: any = JSON.parse(localStorage.getItem("shopDetail")!);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function getSettingProject() {
        const timestamp = new Date().getTime();
        console.log(
            `Setting new project from setting start on standby screen with url: ${middlewareApiUrl}/settings/kiosk?franchise_uuid=${shopDetail?.franchise_uid}&shop_uuid=${shopDetail?.shop_uid}&nameKiosk=${selectedShopApp?.name}&timestamp=${timestamp}`
        );
        return fetch(
            `${middlewareApiUrl}/settings/kiosk?franchise_uuid=${shopDetail?.franchise_uid}&shop_uuid=${shopDetail?.shop_uid}&nameKiosk=${selectedShopApp?.name}&timestamp=${timestamp}`,
            { cache: "no-store" }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result !== undefined) {
                    if (result.project !== undefined) {
                        setProject(result.project);
                        if (isLocalConfigModalOpened) {
                            setLocalProject(result.project);
                        }
                        useUpdateProjectSteps(result.project);

                        dispatch(
                            setSetting(
                                setOrderTakingSetting(
                                    result.project.template.pages.ways
                                        .orderTaking as PageType,

                                    (
                                        result.project.template.content
                                            .generalDesign as GeneralDesignType
                                    ).colors[0].content,
                                    (
                                        result.project.template.content
                                            .generalDesign as GeneralDesignType
                                    ).colors[1].content
                                )
                            )
                        );
                        setIsProjectMustBeUpdated(false);
                        console.log(
                            "Setting new project from `setting` ends with success on standby screen"
                        );
                    }
                }
            })
            .catch((error) =>
                console.log(
                    `Error while updating project from setting, with message: ${JSON.stringify(
                        error.message
                    )}`
                )
            );
    }

    if (isProjectMustBeUpdated && navigationIndex === 0) {
        getSettingProject();
    }
    React.useEffect(() => {
        if (
            isLedActive &&
            navigationIndex === 0 &&
            !isAppPaused &&
            !isLocalConfigModalOpened &&
            !isAuthenticationModalOpened
        ) {
            handleLedControl(connectedLedPort, "Green");
        }
    });
    if (
        catalogueIsLoading ||
        (isProjectMustBeUpdated && navigationIndex === 0)
    ) {
        return <SpinnerWrapper />;
    }

    return Component !== undefined ? (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <Component />
        </CSSTransition>
    ) : (
        <StandbyStep />
    );
}

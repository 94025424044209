import { ELECTRON_MODE, WEBVIEW_MODE } from "@constants";
import swal from "@sweetalert/with-react";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { PuffLoader } from "react-spinners";

import { setPosEditor, store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { Table } from "@components/common/Table";
import { SyncConfirmationModalWrapper } from "@components/Synchronization/EntityChildrenSelectionModalWrapper/EntityChildrenSelection/SyncConfirmationModalWrapper";
import {
    setMachineIpAddress,
    syncStore,
} from "@components/Synchronization/store";
import { SyncProgressModalWrapper } from "@components/Synchronization/SyncProgressModalWrapper";

import { StateBadge } from "./StateBadge";
import { StatusBadge } from "./StatusBadge";
import { SynchronizeImgWrapper } from "./SynchronizeImgWrapper";

export function EntityChildrenSelection(): JSX.Element {
    const {
        selectedEntity,
        selectedEntityChild,
        isSyncProgressModalWrapperOpened,
        machineIpAddress,
    } = useSnapshot(syncStore);
    const { middlewareApiUrl } = useSnapshot(store);

    const columns: TableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("Application"),
                accessor: ({ name }: { name: string }) =>
                    name !== "" ? name : "-",
            },
            {
                Header: t("Note"),
                accessor: ({ note }: { note: string }) =>
                    note !== "" ? note : "-",
            },
            {
                Header: t("IP"),
                accessor: ({ ip }: { ip: string }) => (ip !== "" ? ip : "-"),
            },
            {
                Header: t("State"),
                accessor: ({ state }: { state: string }) => (
                    <StateBadge state={state} />
                ),
            },
            {
                Header: t("Status"),
                accessor: ({ status }: { status: string }) => (
                    <StatusBadge status={status} />
                ),
            },
            {
                Header: t("Synchronize"),
                accessor: (row: ObjectType) => (
                    <SynchronizeImgWrapper row={row} />
                ),
            },
        ],
        [t]
    );

    const [shopApps, setShopApps] = React.useState<EntityType[]>();

    function settingsSyncro(signal: AbortSignal) {
        const timestamp = new Date().getTime(); // current timestamp

        fetch(
            `${middlewareApiUrl}/settingsynchro?franchise_uuid=${
                selectedEntityChild?.franchiseUuid
            }&shop_uuid=${
                selectedEntityChild?.uid
            }&isDataNeeded=${false}&timestamp=${timestamp}`,
            { headers: { "Cache-Control": "no-cache" }, signal }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.kiosk.length > 0) {
                    setPosEditor(data.kiosk[0].posEditor);
                    setShopApps(data.kiosk);
                } else {
                    setShopApps([]);
                    return swal({
                        icon: "error",
                        content: (
                            <p>{t("There's an issue in shop apps setting")}!</p>
                        ),
                        buttons: false,
                        timer: 5000,
                    });
                }
            })
            .catch((error) => {
                console.log(
                    `Error while getting shop apps, with message: ${error.message}`
                );
                if (!error.message.includes("user aborted")) {
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")}</p>,
                        buttons: false,
                        timer: 5000,
                    });
                }
            });
    }

    React.useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        //! get ip of current kiosk
        if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
            (window as RendererWindow).electronAPI
                ?.getAppIpAddressEvent()
                .then((response: string) => {
                    setMachineIpAddress(response);
                });
        }
        if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
            fetch(`${middlewareApiUrl}/get-ip`, {
                headers: { "Cache-Control": "no-cache" },
                signal,
            })
                .then((response) => response.json())
                .then((response) => {
                    setMachineIpAddress(response);
                })
                .catch((error) => {
                    console.log(
                        `Error while getting IP`,
                        JSON.stringify(error)
                    );
                });
        }
        // ! get setting kiosk

        settingsSyncro(signal);

        return () => {
            setShopApps([]);
            controller.abort();
        };
    }, []);

    return (
        <FlexboxGrid className="p-4 flex-column" gap="10px">
            {selectedEntity !== undefined ? (
                <FlexboxGrid
                    className="justify-content-between"
                    alignItemsCentered={true}
                >
                    <span
                        className="text-uppercase"
                        style={{
                            font: "normal normal bold 50px/55px Bebas Neue",
                        }}
                    >
                        {selectedEntity?.name}
                    </span>
                    <FlexboxGrid className="flex-column text-right">
                        <span
                            style={{
                                font: "normal normal bold 25px/55px Bebas Neue",
                                letterSpacing: "1px",
                            }}
                        >
                            {t("Machine Ip address")}
                        </span>
                        <span
                            style={{
                                font: "normal normal normal 18px/55px Bebas Neue",
                                letterSpacing: "0.72px",
                                color: "#32D600",
                            }}
                        >
                            {machineIpAddress}
                        </span>
                    </FlexboxGrid>
                </FlexboxGrid>
            ) : null}
            {shopApps === undefined ? (
                <FlexboxGrid
                    justifyContentCentered={true}
                    alignItemsCentered={true}
                >
                    <PuffLoader color="#0c1b28" />
                </FlexboxGrid>
            ) : (
                <Table columns={columns} data={shopApps} />
            )}
            <SyncConfirmationModalWrapper />
            {isSyncProgressModalWrapperOpened ? (
                <SyncProgressModalWrapper />
            ) : null}
        </FlexboxGrid>
    );
}

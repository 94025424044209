import React from "react";

import {
    setIsSyncConfirmationModalWrapperOpened,
    setSelectedShopApp,
} from "@components/Synchronization/store";

import syncIcon from "./sync-icon.svg";

export function SynchronizeImgWrapper({
    row,
}: {
    row: ObjectType;
}): JSX.Element {
    return (
        <img
            src={syncIcon}
            alt="sync"
            style={{
                cursor: "pointer",
            }}
            onClick={() => {
                setIsSyncConfirmationModalWrapperOpened(true);
                setSelectedShopApp(row);
            }}
        />
    );
}

import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        isSubStepTopBannerActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isAuthenticationErrorModalOpened,
        isConfirmationModalOpened,
        selectedAuthMode,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = subStepActionButtons
        .filter((item) => {
            return item.active === true;
        })
        .filter((item: ActionsButtonsDesignItemType) => {
            const VALIDATE_BUTTON_ROLE = 1;
            const IGNORE_BUTTON_ROLE = 2;
            const BACK_BUTTON_ROLE = 3;

            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        })
        .sort((a, b) => {
            return a.role > b.role ? -1 : 1;
        });

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    return (
        <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
            <UsualModeSubStepContainerWrapper
                isSubStepTopBannerActive={isSubStepTopBannerActive}
                isSubStepIconActive={isSubStepIconActive}
                isSubStepInformationMessageActive={
                    isSubStepInformationMessageActive
                }
                isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                isLanguagesActive={isLanguagesActive}
                isConfigOptionsActive={isConfigOptionsActive}
                ref={(ref) => setContainerRef(ref)}
            >
                {isAuthenticationErrorModalOpened === true ? (
                    <AuthenticationErrorModal />
                ) : null}

                {containerRef ? (
                    isConfirmationModalOpened === true ? (
                        <ConfirmationModalWrapper containerRef={containerRef} />
                    ) : null
                ) : null}
                <SubStepsLogoWrapper />

                {isSubStepIconActive ? <SubStepsIconWrapper /> : null}

                {isSubStepInformationMessageActive ? (
                    <SubStepsInformationMessage />
                ) : null}

                <div className="d-flex justify-content-center align-items-center">
                    <UsualNumericKeyboardWrapper />
                </div>

                {isSubStepActionButtonsActive ? (
                    <ActionsButtonsWrapper
                        actionsButtonsItems={activeActionsButtons}
                    />
                ) : null}

                <UsualModeLanguagesWrapper />

                {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
            </UsualModeSubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}

import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Back = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const {
        customerLanguage,
        isPrm,
        kioskWays,
        navigationIndex,
        isUserAccountModalOpened,
    } = useSnapshot(kioskStore);
    return isPrm &&
        (kioskWays[navigationIndex].name !== "orderTaking" ||
            isUserAccountModalOpened) ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 281.609 70"
            {...props}
        >
            <g
                id="Groupe_85"
                data-name="Groupe 85"
                transform="translate(1555 185)"
            >
                <g
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    transform="translate(-1555 -185)"
                    fill="#eaeaea"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#eaeaea"
                    />
                </g>
                <text
                    id="Retour"
                    transform="translate(-1415 -137)"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Back", { lng: customerLanguage.name })}
                </text>
            </g>
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 350 87">
            <g
                id="Groupe_64"
                data-name="Groupe 64"
                transform="translate(1555 185)"
            >
                <g
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    transform="translate(-1555 -185)"
                    fill="#eaeaea"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#eaeaea"
                    />
                </g>
                <text
                    id="Retour"
                    transform="translate(-1380 -127)"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Back", { lng: customerLanguage.name })}
                </text>
            </g>
        </svg>
    );
};
Back.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};

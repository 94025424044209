import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorPage } from "../ErrorPage";

export function CustomErrorBoundary({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={(error) => {
                console.log(
                    "🚀 ~ Erreur problem Kiosk avec message :",
                    error.message,
                    "avec detail : ",
                    error.stack
                );
            }}
        >
            {children}
        </ErrorBoundary>
    );
}

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { areEqual } from "react-window";

type ProductImageProps = {
    productId: string;
    productType: string;
    url: string;
    heightOfImage: string;
    widthOfImage: string;
};
export function ProductImage({
    productId,
    productType,
    url,
    heightOfImage,
    widthOfImage,
}: ProductImageProps): JSX.Element {
    const { isCoverImageActive } = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.settingSlice.productSetting,
        shallowEqual
    );

    return (
        <img
            id={`key-img${productId}`}
            className={
                productType === "categories"
                    ? "redirect-image"
                    : "product-image"
            }
            src={url}
            alt={url}
            style={{
                width: "100%",
                objectFit: isCoverImageActive === true ? "cover" : "contain",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "15px",
                objectPosition: "top",
                height: "100%",
                minHeight: heightOfImage,
                minWidth: widthOfImage,
                maxHeight: heightOfImage,
                maxWidth: widthOfImage,
            }}
        />
    );
}
export const MemoizedProductImage = React.memo(ProductImage, areEqual);

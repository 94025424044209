import {
    ASK_FOR_HELP_BUTTON,
    CONNECT_USER_OPTION,
    PRM_BUTTON,
    USUAL_MODE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import { Tooltip } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import { useOutsideClick } from "@components/OrderTaking/common/OrderTakingChangeSalesMode/useOutsideClick";

import {
    setAuthModalStepIndex,
    setIsHelpModalOpened,
    setIsLogoutProblemModalOpened,
    setIsPrm,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setUser,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function ConfigOptionsWrapper(): JSX.Element {
    const {
        activeConfigOptions,
        navigationIndex,
        isUserAuthenticated,
        isPrm,
        isUserAccountModalOpened,
        kioskWays,
        user,
        selectedSaleModeValue,
    } = useSnapshot(store);
    const [isLogoutButtonClicked, setIsLogoutButtonClicked] =
        React.useState<boolean>(false);
    const ref = useOutsideClick(setIsLogoutButtonClicked);

    function handleConfigOptionOnClickEvent(option: OptionsItemType) {
        switch (option.title.toLowerCase()) {
            case PRM_BUTTON:
                setIsPrm(!isPrm);
                break;
            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;
            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);
                break;
            case CONNECT_USER_OPTION:
                if (isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true),
                        setAuthModalStepIndex(navigationIndex);
                }
                break;

            default:
                null;
        }
    }

    const localActiveConfigOptions = isPrm
        ? activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== "prm/child"
          )
        : activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== "usual"
          );

    const localConfigOptions = !isUserAuthenticated
        ? localActiveConfigOptions
        : localActiveConfigOptions.filter(
              (config) => config.title.toLowerCase() !== "customer account"
          );

    return (
        <div className="d-flex justify-content-end align-items-center">
            <div
                className="d-flex justify-content-end align-items-center px-3"
                style={{ gap: "5px" }}
            >
                {isLogoutButtonClicked &&
                kioskWays[navigationIndex].name !== "meansOfPayment" ? (
                    <Tooltip
                        className="d-flex justify-content-center align-items-center order-taking-tooltip p-3"
                        placement="top"
                        isOpen={isLogoutButtonClicked}
                        target={"Tooltip"}
                        onClick={() => {
                            if (selectedSaleModeValue === "Delivery") {
                                setIsLogoutProblemModalOpened(true);
                                setIsLogoutButtonClicked(false);
                            } else {
                                setIsUserAuthenticated(false);
                                setIsLogoutButtonClicked(false);
                                setUser({
                                    firstName: "",
                                    lastName: "",
                                    phoneNumber: "",
                                    email: "",
                                    loyaltyAccount: 0,
                                    loyaltyAmount: 0,
                                });
                            }
                        }}
                        style={{
                            cursor: "pointer",
                            height: "50px",
                            width: "fit-content",
                            borderRadius: "5px",
                        }}
                    >
                        <span
                            // eslint-disable-next-line
                            ref={ref as any}
                            style={{ font: "normal 26px/30px Segoe UI" }}
                        >
                            {t("Sign out")}
                        </span>
                    </Tooltip>
                ) : null}
                {isUserAuthenticated ? (
                    <div id="Tooltip">
                        <Avatar
                            email={user.email}
                            name={user.lastName}
                            size="65px"
                            round="10px"
                            onClick={() =>
                                setIsLogoutButtonClicked(!isLogoutButtonClicked)
                            }
                        />
                    </div>
                ) : null}

                {localConfigOptions.map(
                    (option: OptionsItemType, index: number) => {
                        const imageSrc = getImageContentById(option.id);

                        return (
                            <img
                                key={index}
                                src={imageSrc}
                                alt={option.title}
                                onClick={() => {
                                    handleConfigOptionOnClickEvent(option);
                                }}
                                style={{
                                    height: "70px",
                                    width: "70px",
                                    opacity:
                                        option.title.toLowerCase() ===
                                            "customer account" &&
                                        (isUserAccountModalOpened ||
                                            kioskWays[navigationIndex].name ===
                                                "connection" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModes" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModeConsommation")
                                            ? "0.5"
                                            : "1",
                                    pointerEvents:
                                        option.title.toLowerCase() ===
                                            "customer account" &&
                                        (isUserAccountModalOpened ||
                                            kioskWays[navigationIndex].name ===
                                                "connection" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModes" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModeConsommation")
                                            ? "none"
                                            : "auto",
                                }}
                            />
                        );
                    }
                )}
            </div>
        </div>
    );
}

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "../UsualModeMainContentWrapper/ImageContent";
// import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
import "./index.css";

import "swiper/swiper-bundle.min.css";

export function PrmMainContentWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    const activeItems = (
        (content.meansOfPayment as ProjectContentItemType)
            .items as ProjectMainContentItemType[]
    ).filter((item) => {
        return (
            item.active === true &&
            item.languages[customerLanguage.name].content !== ""
        );
    });

    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 3 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeItems?.map(
                        (item: ProjectMainContentItemType, index: number) => (
                            <ImageContent
                                item={item}
                                key={index}
                                activeItemsLength={activeItemsLength}
                            />
                        )
                    )}
                </div>
            ) : (
                <Swiper
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={40}
                    modules={[Pagination]}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {activeItems.map(
                        (item: ProjectMainContentItemType, index: number) => (
                            <SwiperSlide
                                key={item.id}
                                className="main-content-prm-swiper-slide__clz"
                            >
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            )}
        </React.Fragment>
    );
}

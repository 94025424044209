import { StyledSearchInput } from "@aureskonnect/react-ui";
import { t } from "i18next";
import styled from "styled-components";
import { useSnapshot } from "valtio";

import React from "react";
import BTable from "react-bootstrap/Table";
import { useGlobalFilter, usePagination, useTable } from "react-table";

import { KeyboardWrapper } from "@components/Synchronization/KeyboardWrapper";

import {
    setKeyboardInputsValues,
    kioskStore as store,
} from "@pages/Kiosk/store";

import { Pagination } from "./Pagination";

type PropsType = {
    columns: TableColumnsType[];
    // eslint-disable-next-line
    data: any[];
};

const Styles = styled.div`
    table {
        thead {
            font: normal bold 22px/55px Bebas Neue;
            letter-spacing: 0.88px;
            color: #0a0a0a;
            text-align: center;
        }

        tbody td {
            text-align: center;
            font: normal 12px/28px Arial;
            letter-spacing: 0px;
            vertical-align: middle;
        }
        tbody td:nth-child(4) {
            width: 180px;
        }
    }
`;

export function Table({ columns, data }: PropsType): JSX.Element {
    const { keyboardInputsValues } = useSnapshot(store);
    const [isKeyBoardVisible, setIsKeyBoardVisible] = React.useState(false);
    const [itemPerPage, setItemPerPage] = React.useState(10);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    React.useEffect(() => {
        setGlobalFilter(Object.values(keyboardInputsValues)[0]);
    }, [keyboardInputsValues]);

    return (
        <React.Fragment>
            <StyledSearchInput
                onFocus={() => {
                    setIsKeyBoardVisible(true);
                    setPageSize(5);
                    setItemPerPage(5);
                }}
                onBlur={() => {
                    setIsKeyBoardVisible(false);
                    setPageSize(10);
                    setItemPerPage(10);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const inputs = event.target.value;
                    setKeyboardInputsValues({ inputs });
                }}
                placeholder={`${t("Search")} ...`}
                value={Object.values(keyboardInputsValues)[0]}
            />
            <Styles>
                <BTable
                    responsive
                    {...getTableProps()}
                    style={{
                        display: "block",
                    }}
                >
                    <thead
                        className="d-flex w-100"
                        style={{
                            position: "sticky",
                            top: "0px",
                            margin: " 0 0 0 0",
                            width: "100%",
                        }}
                    >
                        {/* eslint-disable-next-line */}
                        {headerGroups.map((headerGroup: any) => {
                            const { key: headerGroupKey } =
                                headerGroup.getHeaderGroupProps();
                            return (
                                <tr
                                    className="d-flex justify-content-between"
                                    key={headerGroupKey}
                                    {...headerGroup.getHeaderGroupProps()}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    {/* eslint-disable-next-line */}
                                    {headerGroup.headers.map((column: any) => {
                                        const { key: headerKey } =
                                            column.getHeaderProps();
                                        return (
                                            <th
                                                key={headerKey}
                                                {...column.getHeaderProps()}
                                                style={{
                                                    borderBottom: "unset",
                                                    width: "180px",
                                                }}
                                            >
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            overflowY: "scroll",
                            height: isKeyBoardVisible ? "40vw" : "80vw",
                            scrollbarWidth: "none",
                        }}
                        {...getTableBodyProps()}
                    >
                        {page.length ? (
                            // eslint-disable-next-line
                            page.map((row: any) => {
                                prepareRow(row);
                                const { key: rowKey } = row.getRowProps();

                                return (
                                    <tr
                                        className="d-flex justify-content-between"
                                        key={rowKey}
                                        {...row.getRowProps()}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {/* eslint-disable-next-line */}
                                        {row.cells.map((cell: any) => {
                                            const { key: cellKey } =
                                                cell.getCellProps();
                                            return (
                                                <td
                                                    className="white-space-nowrap three-lines-preview-text"
                                                    key={cellKey}
                                                    {...cell.getCellProps()}
                                                    style={{ width: "180px" }}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr
                                style={{
                                    width: "100%",
                                }}
                            >
                                <td
                                    colSpan={headerGroups[0]?.headers?.length}
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    {t("No data")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </BTable>
            </Styles>
            {data.length > itemPerPage ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
            {isKeyBoardVisible ? <KeyboardWrapper /> : null}
        </React.Fragment>
    );
}

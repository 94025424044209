import { StyledButton } from "@aureskonnect/react-ui";
import { IP_ADDRESS_REGEX } from "@constants";
import { AvForm } from "availity-reactstrap-validation";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { FaTimes } from "react-icons/fa";

import { setIsMiddlewareApiUrlExists, setMiddlewareApiUrl } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import {
    kioskStore,
    setIsKeyboardOpened,
    setKeyboardInputsValues,
} from "@pages/Kiosk/store";

import MessageErrorComponent from "./MessageErrorComponent";

type MiddlewareApiUrlInputWrapperType = {
    isInputValueInvalid: boolean;
    setIsInputValueInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    isFieldRequired: boolean;
    setIsUpdateMiddlware?: React.Dispatch<React.SetStateAction<boolean>>;
    isUpdateMiddlware?: boolean;
    setIsSystemConfirmationOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MiddlewareApiUrlInputWrapper({
    isInputValueInvalid,
    setIsInputValueInvalid,
    isFieldRequired,
    setIsUpdateMiddlware,
    isUpdateMiddlware,
    setIsSystemConfirmationOpen,
}: MiddlewareApiUrlInputWrapperType): JSX.Element {
    const { keyboardInputsValues } = useSnapshot(kioskStore);

    const [inputValues, setInputValues] = React.useState<string>(
        Object.values(keyboardInputsValues)[0]
    );
    const [isInitField, setIsInitField] = React.useState<boolean>(true);

    function handleInputOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const inputValue = event.target.value;
        setInputValues(inputValue);
        setKeyboardInputsValues({ ipAddress: inputValue });
        setIsInitField(false);
    }

    function handleValidateButtonOnClickEvent() {
        if (
            inputValues.length > 0 &&
            IP_ADDRESS_REGEX.test(inputValues) === true
        ) {
            setMiddlewareApiUrl(inputValues);
            setIsMiddlewareApiUrlExists(true);
            setIsKeyboardOpened(false);
            setIsUpdateMiddlware?.(false);
            setIsSystemConfirmationOpen?.(false);
            setKeyboardInputsValues({
                idAddpress: "",
            });
            setInputValues("");
        } else {
            setIsInputValueInvalid(true);
        }
    }

    React.useEffect(() => {
        setInputValues(Object.values(keyboardInputsValues)[0]);
    }, [keyboardInputsValues]);

    const errorMessage =
        isInitField === false
            ? Object.values(keyboardInputsValues)[0] === "" &&
              isFieldRequired === true
                ? t("This field is required")
                : isInputValueInvalid
                ? t("Invalid IP address")
                : ""
            : "";

    return (
        <FlexboxGrid
            justifyContentCentered={true}
            alignItemsCentered={true}
            gap="30px"
            className="flex-column"
        >
            <label
                className="d-flex flex-column"
                style={{
                    font: "normal 27px/32px Segoe UI",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                    whiteSpace: "nowrap",
                    gap: "30px",
                    width: "281px",
                }}
            >
                {t("Middleware IP Address")}

                <AvForm>
                    <div>
                        <input
                            style={{
                                height: "65px",
                                width: "280px",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                border: "1px solid #70707033",
                                borderRadius: "6px",
                                boxShadow:
                                    errorMessage !== ""
                                        ? " 0 0 0 .2rem rgba(172, 8, 8, 0.25)"
                                        : " 0 0 0 .2rem rgba(0,123,255,.25)",
                            }}
                            id="middlewareApiUrlInput"
                            data-testid="middlewareApiUrlInput"
                            name="middlewareApiUrlInput"
                            type="text"
                            placeholder={t("IP address")}
                            onChange={handleInputOnChangeEvent}
                            onBlur={handleInputOnChangeEvent}
                            className="px-3"
                            onClick={() => {
                                setIsKeyboardOpened(true);
                            }}
                            value={Object.values(keyboardInputsValues)[0]}
                        />

                        {inputValues && (
                            <FaTimes
                                style={{
                                    position: "relative",
                                    right: "40px",
                                    zIndex: "100",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsInitField(true);
                                    setKeyboardInputsValues({
                                        idAddpress: "",
                                    });
                                    setInputValues("");
                                    setIsKeyboardOpened(false);
                                    setIsInputValueInvalid(false);
                                }}
                            />
                        )}
                    </div>
                    {errorMessage ? (
                        <MessageErrorComponent messageError={errorMessage} />
                    ) : null}
                </AvForm>
            </label>
            <StyledButton
                style={{ width: isUpdateMiddlware ? "50%" : "" }}
                onClick={() => handleValidateButtonOnClickEvent()}
                block={true}
                rounded={true}
                variant="primary"
                disabled={
                    Object.values(keyboardInputsValues)[0] === "" ||
                    Object.values(keyboardInputsValues)[0] === undefined ||
                    isInputValueInvalid === true
                }
            >
                {t("Validate")}
            </StyledButton>
            <StyledButton
                style={{ width: isUpdateMiddlware ? "50%" : "" }}
                onClick={() => {
                    if (isUpdateMiddlware) {
                        setIsUpdateMiddlware?.(false);
                        setIsSystemConfirmationOpen?.(false);
                        setKeyboardInputsValues({
                            idAddpress: "",
                        });
                        setInputValues("");
                    } else {
                        (window as RendererWindow).electronAPI.sendQuitEvent();
                    }
                }}
                block={true}
                rounded={true}
                variant="primary"
            >
                {isUpdateMiddlware ? t("Back") : t("Close app")}
            </StyledButton>
        </FlexboxGrid>
    );
}

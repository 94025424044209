import {
    ASK_FOR_HELP_BUTTON,
    CATALOGUE_MODE,
    CONNECT_USER_OPTION,
    FULL__BASKET_ICON_ROLE,
    PRM_BUTTON,
    USUAL_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById, getSkippedStepNumber } from "@helpers/general";

import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { PaymentActionButton } from "@components/OrderTaking/common/ActionButtonsImg";
import { CommentKeyboard } from "@components/OrderTaking/common/CommentKeyboard";
import { LogoWrapper } from "@components/OrderTaking/common/LogoWrapper";
import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import OrderTakingChangeSalesMode from "@components/OrderTaking/common/OrderTakingChangeSalesMode";
import { BasketChangeConfirmationDialog } from "@components/OrderTaking/Components/Dialogs/CartAlert/BasketChangeConfirmationDialog";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import { Promotion } from "@components/OrderTaking/Components/Promotion";
import { PromotionPrm } from "@components/OrderTaking/Components/Promotion/PromotionPrm";
import { getOrdertakingOptions } from "@components/OrderTaking/Helpers";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

import { InformationModesConsommationModalWrapper } from "@pages/Kiosk/InformationModesConsommationModalWrapper";
import { InformationModesModalWrapper } from "@pages/Kiosk/InformationModesModalWrapper";
import {
    kioskStore,
    setAuthModalStepIndex,
    setIsGlobalSummaryOrderOpened,
    setIsHelpModalOpened,
    setIsInformationModeActivated,
    setIsOrderTakingPreviousStep,
    setIsPrm,
    setIsUserAccountModalOpened,
    setLeftToPay,
    setNavigationIndex,
    setOrderTotalPrice,
    setPromoValue,
} from "@pages/Kiosk/store";

import "./index.css";
import SummaryHeader from "./SummaryHeader";
import { SummaryOption } from "./SummaryOption";

export default function OrderSummary({
    open,
    onClose,
    setIsSummaryOrderOpened,
    isSummaryOrderOpened,
    setIsLocalKeyBoardOpened,
    openDialogCart,
    setOpenDialogCart,
}) {
    const [containerRef, setContainerRef] = React.useState();
    const [isCartChanged] = React.useState(false);
    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const { totalPrice } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );
    const { itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );

    const {
        kioskWays,
        selectedSaleModeValue,
        project,
        isPrm,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        project: {
            template: { content },
        },
        project: { files: images },
        customerLanguage,
        activeConfigOptions,
        isInformationModesModalOpened,
        isInformationModesConsommationModalOpened,
        isConfirmationModalOpened,
        isPromoCodeActive,
        paymentModes,
        isClickAndCollect,
        devise,
    } = useSnapshot(kioskStore);

    const {
        isSummaryLogoActive,
        IsAccumulatedLoyaltyPointsActive,
        summaryHeaderMessage,
        isSalesMethodsChangeActive,
        summaryActionsButtons,
        isSummaryOptionsItemsActive,
        summaryOptionsItems,
        isSummaryBackgroundActive,
    } = useSelector((state) => state.settingSlice.summary, shallowEqual);

    const { designItems } = useSelector((state) => state.settingSlice);

    const { isCommentKeyboardActive } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });
    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(filledBasketIcon[0].id)
            : "";

    const loyaltyCardItem =
        project.template.content.authenticationModes.items.filter((item) => {
            return item.name === "Loyalty card" && item.active === true;
        })[0];

    const newActiveOptions = isPrm
        ? activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== PRM_BUTTON
          )
        : activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== USUAL_MODE
          );

    const newOptionConfig = getOrdertakingOptions(
        newActiveOptions,
        summaryOptionsItems
    );

    const backgroundImage = Object.values(images).find((image) => {
        return (
            image.key === "background" && image.name === "orderTakingSummary"
        );
    });
    const imageSrc = getImageContentById(backgroundImage.id);

    const DisplayedOrderPrice = `${totalPrice.toFixed(devise.decimalPrice)} ${
        devise.deviseSymbole
    }`;

    async function handleCartChangedCLickEvent() {
        const localPaymentAmount = paymentModes?.reduce(
            (acc, curr) => acc + Number(curr.paymentAmount),
            0
        );
        console.log({ localPaymentAmount });
        setLeftToPay(totalPrice - localPaymentAmount);
        const isOrderItemsHaveInformationModeActive = orderItems.some(
            (item) => item.isInformationModeActivated === true
        );
        setIsInformationModeActivated(isOrderItemsHaveInformationModeActive);

        setOrderTotalPrice(totalPrice - localPaymentAmount);

        setNavigationIndex(
            navigationIndex +
                Number(
                    await getSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        content,
                        Number(totalPrice)
                    )
                )
        );

        setIsOrderTakingPreviousStep(true);
    }

    function handleConfigOptionOnClickEvent(option) {
        switch (option.title.toLowerCase()) {
            case PRM_BUTTON:
                setIsPrm(!isPrm);
                break;
            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;
            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);
                break;
            case CONNECT_USER_OPTION:
                if (isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true),
                        setAuthModalStepIndex(navigationIndex);
                }
                break;

            default:
                null;
        }
    }

    React.useEffect(() => {
        if (open === true && orderItems.length === 0) {
            setIsSummaryOrderOpened(false);
        }
    }, [orderItems]);

    return (
        <React.Fragment>
            {isCartChanged && (
                <BasketChangeConfirmationDialog
                    open={isCartChanged}
                    onClose={handleCartChangedCLickEvent}
                    isPrm={isPrm}
                />
            )}
            {isInformationModesModalOpened === true ? (
                <InformationModesModalWrapper />
            ) : null}
            {isInformationModesConsommationModalOpened === true ? (
                <InformationModesConsommationModalWrapper />
            ) : null}
            {containerRef ? (
                isConfirmationModalOpened === true ? (
                    <ConfirmationModalWrapper containerRef={containerRef} />
                ) : null
            ) : null}

            {isCommentKeyboardActive && isSummaryOrderOpened && (
                <CommentKeyboard
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
            <Dialog
                fullScreen={true}
                onClose={onClose}
                open={open}
                container={() => document.querySelector(".fullscreen")}
                PaperProps={{
                    sx: {
                        display: "flex!important",
                        alignItems: "center!important",
                        flexDirection: "column!important",
                        paddingTop: "0px!important",
                        paddingBottom: "0px!important",
                        overflowY: "unset!important",
                        color: "black!important",
                        maxWidth: "unset!important",
                        maxHeight: "unset",
                        backgroundColor: "#FFFFFF!important",
                        borderLeft: "1px solid #707070!important",
                        borderRight: "1px solid #707070!important",
                        borderBottom: "1px solid #707070!important",
                        top: "unset!important",
                        right: "unset!important",
                        left: "unset!important",
                        margin: "unset",
                        zoom: `${
                            process.env.REACT_APP_MODE === CATALOGUE_MODE
                                ? "50%"
                                : "100%"
                        }`,
                    },
                }}
                style={{ backdropFilter: "blur(5px)" }}
                ref={(ref) => setContainerRef(ref)}
            >
                <OrderTakingBackgroundWrapper
                    filename={imageSrc}
                    isBackgroundActive={isSummaryBackgroundActive}
                >
                    <div
                        className="d-flex flex-column  align-items-center"
                        style={{
                            width:
                                process.env.REACT_APP_MODE === CATALOGUE_MODE
                                    ? "30% "
                                    : "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: 2,
                            border:
                                process.env.REACT_APP_MODE === CATALOGUE_MODE
                                    ? "2px solid"
                                    : "0px",
                        }}
                    >
                        {isPrm ? (
                            <PrmBackgroundWrapper isSummary={true} />
                        ) : null}
                        <div className="headerSummary my-3">
                            {isSummaryLogoActive ? (
                                <div
                                    className="centered-items"
                                    style={{ height: "250px" }}
                                >
                                    <LogoWrapper width={140} height={140} />
                                </div>
                            ) : null}

                            <div
                                className="d-flex flex-column justify-content-center"
                                style={{ height: "100%" }}
                            >
                                {loyaltyCardItem !== undefined ? (
                                    <>
                                        <hr className="separation-line" />
                                        {IsAccumulatedLoyaltyPointsActive ? (
                                            <SummaryHeader />
                                        ) : null}
                                        <hr className="separation-line" />
                                    </>
                                ) : null}
                                <div className="header-text">
                                    <span
                                        className="centered-items"
                                        style={{
                                            height: "100px",
                                            font: "normal normal 600 55px/60px Nunito Sans",
                                        }}
                                    >
                                        {
                                            summaryHeaderMessage.languages[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                        {!isPrm ? <hr className="separation-line" /> : null}

                        {isPromoCodeActive && !isPrm ? <Promotion /> : null}

                        <div
                            className="summary-cart-content py-2"
                            style={{
                                width: "100%",
                                height: isPrm ? "" : "100%",
                            }}
                        >
                            {orderItems.map((product) => (
                                <ComposedProductCartPopup
                                    key={product.iuudOrder}
                                    product={product}
                                />
                            ))}
                        </div>

                        {!isPrm ? <hr className="separation-line" /> : null}

                        <div className="footer-summary-cart">
                            <div className="first-block-footer-summary-cart">
                                <div
                                    className="d-flex justify-content-start align-items-center h-100"
                                    style={{
                                        gap: "10px",
                                        width: "20%",
                                    }}
                                >
                                    {filledBasketIconContent !== "" ? (
                                        <div
                                            className="d-flex justify-content-start align-items-center h-100"
                                            style={{
                                                width: "40%",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "95%",
                                                    height: "95%",
                                                }}
                                                src={filledBasketIconContent}
                                                alt={"filledBasket"}
                                            />
                                        </div>
                                    ) : (
                                        <CartIconFilled />
                                    )}
                                    <span>
                                        {itemsCount} {t("Article(s)")}
                                    </span>
                                </div>

                                <div className="config-option">
                                    {isPromoCodeActive && isPrm ? (
                                        <PromotionPrm />
                                    ) : null}
                                    {isSalesMethodsChangeActive &&
                                    !isClickAndCollect ? (
                                        <div className="d-flex justify-content-end align-items-center">
                                            <OrderTakingChangeSalesMode />
                                        </div>
                                    ) : null}

                                    <SummaryOption
                                        isSummaryOptionsItemsActive={
                                            isSummaryOptionsItemsActive
                                        }
                                        newOptionConfig={newOptionConfig}
                                        handleConfigOptionOnClickEvent={
                                            handleConfigOptionOnClickEvent
                                        }
                                    />
                                </div>
                            </div>

                            <div className="summary-actions">
                                {summaryActionsButtons
                                    .filter((item) => {
                                        return item.active === true;
                                    })
                                    .sort((a, b) => {
                                        return a.role > b.role ? -1 : 1;
                                    })
                                    .map((button) => {
                                        const imageSrc = getImageContentById(
                                            button.id
                                        );
                                        const opacityStyle =
                                            button.name.toLowerCase() ===
                                                "pay" && orderItems.length === 0
                                                ? "0.5"
                                                : undefined;

                                        return button.name.toLowerCase() ===
                                            "pay" ? (
                                            <PaymentActionButton
                                                isSummery={true}
                                                imageUrl={imageSrc}
                                                PayButton={button}
                                                opacityStyle={opacityStyle}
                                                DisplayedOrderPrice={
                                                    DisplayedOrderPrice
                                                }
                                                handleBasketActionsButtonsOnClickEvent={
                                                    handleCartChangedCLickEvent
                                                }
                                                width={"591px"}
                                            />
                                        ) : (
                                            <>
                                                {VALIDATED_EXTENSION_FILE.includes(
                                                    imageSrc.substr(-4)
                                                ) ? (
                                                    <img
                                                        src={imageSrc}
                                                        style={{
                                                            height: "65px",
                                                            width: "437px",
                                                        }}
                                                        onClick={() => {
                                                            onClose();
                                                            setIsGlobalSummaryOrderOpened(
                                                                false
                                                            );
                                                            if (
                                                                isPromoCodeActive
                                                            ) {
                                                                setPromoValue(
                                                                    0
                                                                );
                                                            }
                                                            {
                                                                openDialogCart &&
                                                                    setOpenDialogCart(
                                                                        !openDialogCart
                                                                    );
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <DynamicSvgComponent
                                                        nameSvg={imageSrc}
                                                        height={"65px"}
                                                        width={"437px"}
                                                        onClick={() => {
                                                            onClose();
                                                            setIsGlobalSummaryOrderOpened(
                                                                false
                                                            );
                                                            if (
                                                                isPromoCodeActive
                                                            ) {
                                                                setPromoValue(
                                                                    0
                                                                );
                                                            }
                                                            {
                                                                openDialogCart &&
                                                                    setOpenDialogCart(
                                                                        !openDialogCart
                                                                    );
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </OrderTakingBackgroundWrapper>
            </Dialog>
        </React.Fragment>
    );
}

OrderSummary.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.object,
    setIsSummaryOrderOpened: PropTypes.func,
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
    isSummaryOrderOpened: PropTypes.bool,
    setIsLocalKeyBoardOpened: PropTypes.func,
};

import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE, VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    getOtherPaymentMethodButtonImageSrc,
} from "@helpers/general";

import { TryAgain } from "@components/SvgIcons";

import {
    kioskStore,
    setIsCashMachineNotOccupied,
    setIsCashMachineOccupiedModalOpened,
    setIsRegulationModesModalOpened,
    setRegulationModesModalKey,
} from "@pages/Kiosk/store";

export function CashMachineOccupiedModal(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isCashMachineOccupiedModalOpened,
    } = useSnapshot(kioskStore);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );
    const otherPaymentMethodImgSrc = getOtherPaymentMethodButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isCashMachineOccupiedModalOpened}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Change machine unavailable")}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="mx-2 d-flex justify-content-center align-items-center text-center"
                style={{ height: "200px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t(
                        "Currently another customer is using the coin mechanism. Please try again or consider using another payment method. THANKS."
                    )}
                </span>
            </div>

            <div style={{ height: "200px" }} />

            <div
                style={{ height: "150px", gap: "10px" }}
                className=" d-flex justify-content-between align-items-center mx-4"
            >
                <TryAgain
                    height={"87px"}
                    onClick={() => {
                        console.log(
                            "try again with cash machine!!",
                            "set is cash machine occupied to false and render the component"
                        );
                        setIsCashMachineOccupiedModalOpened(false);
                        setRegulationModesModalKey(uuid());
                        setIsCashMachineNotOccupied(false);
                    }}
                />

                {VALIDATED_EXTENSION_FILE.includes(
                    otherPaymentMethodImgSrc.substr(-4)
                ) ? (
                    <img
                        className="d-flex justify-content-center"
                        src={otherPaymentMethodImgSrc}
                        alt="action button"
                        style={{ width: "628px", height: "87px" }}
                        onClick={() => {
                            console.log(
                                "otherPaymentMethod!!",
                                "open the other payment modes modal!!"
                            );
                            setIsCashMachineOccupiedModalOpened(false);
                            setIsRegulationModesModalOpened(true);
                        }}
                    />
                ) : (
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            height: "87px",
                            width: "350px",
                            background: "black",
                            fontSize: "29px",
                            fontFamily: "HelveticaNeue-Medium, Helvetica Neue",
                            fontWeight: "500",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #FFFFFF",
                            borderRadius: "12px",
                            marginRight: "0px",
                        }}
                        onClick={() => {
                            console.log(
                                "otherPaymentMethod!!",
                                "open the other payment modes modal!!"
                            );
                            setIsCashMachineOccupiedModalOpened(false);
                            setIsRegulationModesModalOpened(true);
                        }}
                    >
                        {t("Other means of payment")}
                    </StyledButton>
                )}
            </div>
        </Modal>
    );
}

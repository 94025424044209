import {
    CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT,
    CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY,
    CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT,
    ISNEPTING_TYPE_46_OR_45,
    ISVALINA_WITH_MULTIPLE_MODE,
    PAYMENT_REFUSED,
    TCPOS,
    VALIDE_PAYMENT_WITH_CHANGEMACHINE,
    VALINA_PAYMENT_WITH_CONTACT,
    VALINA_PAYMENT_WITHOUT_CONTACT,
} from "@constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    getPreviousSkippedStepNumber,
    getSkippedStepNumber,
    handleChangeMachineStatus,
} from "@helpers/general";

import { AbortTransactionModalWrapper } from "@components/AbortTransactionModalWrapper";
import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { CashMachineOccupiedModal } from "@components/common/CashMachineOccupiedModal";
import { ChangeMachineRenderModalWrapper } from "@components/common/ChangeMachineRenderModalWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PaymentContainerWrapper } from "@components/common/Payment/PaymentContainer";
import { PaymentMainContentWrapper } from "@components/common/Payment/PaymentMainContentWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { RefundAnomalyModalWrapper } from "@components/common/RefundAnomalyModalWrapper";
import { RegulationModesModalWrapper } from "@components/common/RegulationModesModalWrapper";
import { WithdrawalProblemModalWrapper } from "@components/common/WithdrawalProblemModalWrapper";
import { validateTCPOSCommand } from "@components/OrderTaking/Helpers";
import { TpaValinaModalWrapper } from "@components/TpaValinaModalWrapper";
import { TpeAvailablityModal } from "@components/TpeAvailablityModal";

import {
    kioskStore,
    setActionsButtonsItems,
    setAmountDeposited,
    setChosenTransition,
    setInformationMessageItems,
    setIsAbortTransactionModalOpened,
    setIsAcceptAbortCBTransactionButtonDisabled,
    setIsActionButtonsActive,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsCashMachineNotOccupied,
    setIsCashMachineOccupiedModalOpened,
    setIsChangeMachineReady,
    setIsChangeMachineRenderModalOpened,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsPaymentRefused,
    setIsPaymentTechnicalProblemDetected,
    setIsRefundAnomalyModalOpened,
    setIsRegulationModesModalOpened,
    setIsTopBannerActive,
    setIsTpaValinaActionButtonsActive,
    setIsTpaValinaIconActive,
    setIsTpaValinaInformationMessageActive,
    setIsTpeNotAvailable,
    setIsTransactionCanceled,
    setIsWithdrawalProblemModalOpened,
    setLeftToPay,
    setLeftToPayAfterCashMachineValidation,
    setMainContentItems,
    setNavigationIndex,
    setPaymentModes,
    setPaymentStepCounter,
    setRegulationModesNumber,
    setRenderAmount,
    setTotalAmountDeposited,
    setTpaValinaActionButtons,
    setTpaValinaIconId,
    setTpaValinaInformationMessageItems,
    setVoucherAmount,
} from "@pages/Kiosk/store";

export function PaymentStep(): JSX.Element {
    const { transitions, middlewareApiUrl, posEditor } = useSnapshot(store);
    const {
        isTopBannerActive,
        isHeaderActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isChangeMachineRenderModalOpened,
        actionsButtonsItems,
        isRefundAnomalyModalOpened,
        isRegulationModesModalOpened,
        selectedRegulationModeValue,
        regulationModesNumber,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        isBackWithoutCreditCardPaymentChoice,
        isAbortTransactionModalOpened,
        regulationModesModalKey,
        leftToPayAfterCashMachineValidation,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        paymentModes,
        cashUuID,
        creditCardUuId,
        amountDeposited,
        isTransactionCanceled,
        totalAmountDeposited,
        isWithdrawalProblemModalOpened,
        isPaymentRefused,
        isChangeMachineReady,
        isCashMachineTransactionCanceled,
        isEventStreamProblem,
        isCashMachineStatusStarted,
        isCashMachineNotOccupied,
        isCashMachineOccupiedModalOpened,
        paymentStepCounter,
        isTpeNotAvailable,
        isAnotherPaymentModeButtonClickedOnCBPaymentMode,
        creditCardPaymentChoice,
        orderTotalPrice,
        moneticConfig,
    } = useSnapshot(kioskStore);

    const isvalinaOpen =
        moneticConfig.mode === ISVALINA_WITH_MULTIPLE_MODE &&
        moneticConfig.type === "Valina" &&
        creditCardPaymentChoice === "" &&
        selectedRegulationModeValue === "creditCard" &&
        isBackWithoutCreditCardPaymentChoice &&
        !isRegulationModesModalOpened &&
        !(TCPOS === posEditor);

    const [isTpaValinaModalOpened, setIsTpaValinaModalOpened] =
        React.useState<boolean>(isvalinaOpen);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const [
        isCashTransactionSuccessfullyValidated,
        setIsCashTransactionSuccessfullyValidated,
    ] = React.useState<boolean>(false);

    const [withdrawalAmount, setWithdrawalAmount] = React.useState<number>(0);
    const [isWithdrawalAmountApiLaunched, setIsWithdrawalAmountApiLaunched] =
        React.useState<boolean>(false);

    const [isInventoryResponse, setIsInventoryResponse] =
        React.useState<boolean>(false);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        if (regulationModesNumber === 1) {
            return item.name !== "otherPaymentMethod" && item.active === true;
        } else {
            return item.active === true;
        }
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    async function handleNavigationOnWithdrawalAmount() {
        console.log(
            `The change machine refund the amount deposited:${totalAmountDeposited} successfully`
        );

        if (isEventStreamProblem) {
            if (isCashMachineTransactionCanceled) {
                setIsRegulationModesModalOpened(false);
                console.log(
                    "The cash machine payment step timeout was detected => navigate to standby step after total deposited amount rendering"
                );
                setNavigationIndex(0);
            } else {
                setIsRegulationModesModalOpened(true);
            }
        } else {
            setIsRegulationModesModalOpened(false);
            if (isCashMachineTransactionCanceled) {
                console.log(
                    "The cash machine payment step timeout was detected => navigate to standby step after total deposited amount rendering"
                );
                setNavigationIndex(0);
            } else {
                setNavigationIndex(
                    navigationIndex -
                        Number(
                            await getPreviousSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue
                            )
                        )
                );
            }
        }

        setTotalAmountDeposited(0);
    }

    async function handleNavigationAfterTransactionCancelling() {
        const timestamp = new Date().getTime();
        console.log(
            "If the transaction is canceled, it means that the payment request has been refused."
        );

        if (totalAmountDeposited === 0) {
            if (
                isEventStreamProblem &&
                selectedRegulationModeValue === "changeMachine"
            ) {
                console.log(
                    "le montant total déposé est null et il y a un problème de stream"
                );

                if (isCashMachineTransactionCanceled) {
                    setIsRegulationModesModalOpened(false);
                    console.log(
                        "The cash machine payment step timeout was detected => navigate to standby step (totalAmountDeposited=0)"
                    );
                    setNavigationIndex(0);
                } else {
                    setIsRegulationModesModalOpened(true);
                }
            } else {
                setIsRegulationModesModalOpened(false);
                if (isCashMachineTransactionCanceled) {
                    setNavigationIndex(0);
                } else {
                    setNavigationIndex(
                        navigationIndex -
                            Number(
                                await getPreviousSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue
                                )
                            )
                    );
                }
            }

            setIsPaymentRefused(false);
            setIsTransactionCanceled(false);
            setAmountDeposited(0);
        } else {
            setIsWithdrawalAmountApiLaunched(true);

            console.log(
                `The change machine will refund the amount already deposited:${totalAmountDeposited}`
            );
            console.log(
                "If the variable isChangeMachineReady is TRUE, it means that the change machine is ready to call the withdrawal API"
            );
            console.log(
                `The change machine refund the amount:${totalAmountDeposited}`
            );
            fetch(
                `${middlewareApiUrl}/RetraitMontant?unitaire=${-totalAmountDeposited}&timestamp=${timestamp}`
            )
                .then((response) => response.json())
                .then((response) => {
                    const localRenderAmount = Number(
                        response.response
                            .split(`:`)[2]
                            .split(`,`)[0]
                            .substr(
                                1,
                                response.response.split(`:`)[2].split(`,`)[0]
                                    .length - 2
                            )
                    );
                    setWithdrawalAmount(localRenderAmount);
                })
                .catch((error) => {
                    console.log(
                        `Error when withdrawing the deposited amount, with the message: ${JSON.stringify(
                            error.message
                        )}`
                    );
                });
        }
    }

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            console.log(
                "JSON.stringify(paymentModes) in PaymentStep",
                JSON.stringify(paymentModes)
            );
        }
    }, [paymentModes]);

    async function handleCommandPayment() {
        let url = "";
        const localTotalPrice = isOrderPartialPaidWithLoyaltyAccountBalance
            ? totalOrderAfterPaidWithLoyaltyAccountBalance
            : orderTotalPrice;

        const unitPrice =
            leftToPayAfterCashMachineValidation !== 0
                ? leftToPayAfterCashMachineValidation
                : localTotalPrice;

        if (selectedRegulationModeValue === "changeMachine") {
            console.log(`PaymentWithCashMachine unit price: ${unitPrice}`);
            setIsBackButtonDisabled(false);
            url = `${middlewareApiUrl}/PaymentWithCashMachine?unitaire=${unitPrice}`;
            console.log(
                "payment with change machine api launched is",
                JSON.stringify(url)
            );
        } else {
            console.log(JSON.stringify({ creditCardPaymentChoice }));
            console.log(`Payment with CB unit price: ${unitPrice}`);
            if (moneticConfig.type === "Valina") {
                url = `${middlewareApiUrl}/ReglementCommande?unitaire=${unitPrice}&contact=${
                    creditCardPaymentChoice === "withoutContact"
                        ? VALINA_PAYMENT_WITHOUT_CONTACT
                        : VALINA_PAYMENT_WITH_CONTACT
                }`;
            } else {
                url = `${middlewareApiUrl}/ReglementCommande?unitaire=${unitPrice}`;
            }
            console.log("payment with CB api launched is", JSON.stringify(url));
        }
        let localTotalAmountAlreadyDeposited = 0;
        const ctrl = new AbortController();
        await fetchEventSource(url, {
            signal: ctrl.signal,
            async onmessage(event) {
                console.log(
                    JSON.stringify({ event: event.event, data: event.data })
                );
                switch (event.event) {
                    case "result": {
                        const code = JSON.parse(event.data).code;

                        setIsBackButtonDisabled(false);
                        switch (code) {
                            //code returned after payment validation with the change machine
                            case VALIDE_PAYMENT_WITH_CHANGEMACHINE: {
                                console.log(
                                    `The cash machine transaction is validated, i receive the code 1001`
                                );
                                setLeftToPayAfterCashMachineValidation(
                                    Number(JSON.parse(event.data).response[1])
                                );

                                const localDepositedAmount =
                                    Number(JSON.parse(event.data).response[0]) -
                                    Number(JSON.parse(event.data).response[1]);
                                const localTotalAmountDeposited =
                                    totalAmountDeposited + localDepositedAmount;
                                setTotalAmountDeposited(
                                    localTotalAmountDeposited
                                );

                                const selectedPaymentMode = cloneDeep(
                                    paymentModes.find(
                                        (mode) =>
                                            mode.paymentMode ===
                                            selectedRegulationModeValue
                                    )
                                );

                                if (localDepositedAmount !== 0) {
                                    if (selectedPaymentMode !== undefined) {
                                        const selectedPaymentModeIndex =
                                            paymentModes.findIndex(
                                                (mode) =>
                                                    mode.paymentMode ===
                                                    selectedRegulationModeValue
                                            );
                                        const localPaymentModes = cloneDeep(
                                            paymentModes.map(
                                                (paymentMode) => paymentMode
                                            )
                                        );
                                        console.log({
                                            selectedPaymentModeIndex,
                                        });
                                        console.log(
                                            JSON.stringify({
                                                localPaymentModes,
                                                ccc: localPaymentModes[
                                                    selectedPaymentModeIndex
                                                ],
                                            })
                                        );
                                        localPaymentModes[
                                            selectedPaymentModeIndex
                                        ] = {
                                            paymentModeUuiD: cashUuID,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount:
                                                selectedPaymentMode.paymentAmount +
                                                parseFloat(
                                                    localDepositedAmount.toFixed(
                                                        2
                                                    )
                                                ),
                                            data: {
                                                renderAmount: 0,
                                            },
                                            totalAmountDeposited:
                                                Number(
                                                    selectedPaymentMode.totalAmountDeposited
                                                ) +
                                                Number(
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                ),
                                        };
                                        console.log(
                                            "JSON.stringify(localPaymentModes) in PaymentStep code 1001 If",
                                            JSON.stringify(localPaymentModes)
                                        );
                                        setPaymentModes(localPaymentModes);
                                    } else {
                                        console.log(
                                            "JSON.stringify(localPaymentModes) in PaymentStep code 1001 else",
                                            JSON.stringify([
                                                ...paymentModes,
                                                {
                                                    paymentModeUuiD: cashUuID,
                                                    paymentMode:
                                                        selectedRegulationModeValue,
                                                    paymentAmount: Number(
                                                        parseFloat(
                                                            localDepositedAmount.toFixed(
                                                                2
                                                            )
                                                        )
                                                    ),
                                                    data: {
                                                        renderAmount: 0,
                                                    },
                                                    totalAmountDeposited:
                                                        parseFloat(
                                                            localDepositedAmount.toFixed(
                                                                2
                                                            )
                                                        ),
                                                },
                                            ])
                                        );
                                        setPaymentModes([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD: cashUuID,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount: Number(
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                ),
                                                data: {
                                                    renderAmount: 0,
                                                },
                                                totalAmountDeposited: Number(
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                ),
                                            },
                                        ]);
                                    }
                                }

                                ctrl.abort();
                                break;
                            }
                            //code returned after payment with change machine without a render amount or payment with credit card
                            case CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT: {
                                const selectedPaymentMode = cloneDeep(
                                    paymentModes.find(
                                        (mode) =>
                                            mode.paymentMode ===
                                            selectedRegulationModeValue
                                    )
                                );
                                if (selectedPaymentMode !== undefined) {
                                    const selectedPaymentModeIndex =
                                        paymentModes.findIndex(
                                            (mode) =>
                                                mode.paymentMode ===
                                                selectedRegulationModeValue
                                        );

                                    const localPaymentModes = cloneDeep(
                                        paymentModes.map((paymentMode) => {
                                            return paymentMode;
                                        })
                                    );
                                    localPaymentModes[
                                        selectedPaymentModeIndex
                                    ] = {
                                        paymentModeUuiD: cashUuID,
                                        paymentMode:
                                            selectedRegulationModeValue,
                                        paymentAmount:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            Number(
                                                leftToPayAfterCashMachineValidation
                                            ),
                                        data: {
                                            renderAmount: 0,
                                        },
                                        totalAmountDeposited:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            Number(
                                                leftToPayAfterCashMachineValidation
                                            ),
                                    };
                                    console.log(
                                        "JSON.stringify(localPaymentModes) in PaymentStep code 1002 IF",
                                        JSON.stringify(localPaymentModes)
                                    );
                                    setPaymentModes(localPaymentModes);
                                } else {
                                    console.log(
                                        "JSON.stringify(PaymentModes) in PaymentStep code 1002 else",
                                        JSON.stringify([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD:
                                                    selectedRegulationModeValue ===
                                                    "changeMachine"
                                                        ? cashUuID
                                                        : creditCardUuId,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount: Number(
                                                    leftToPayAfterCashMachineValidation !==
                                                        0
                                                        ? leftToPayAfterCashMachineValidation
                                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice
                                                ),
                                                data: {
                                                    renderAmount: 0,
                                                },
                                                totalAmountDeposited: Number(
                                                    leftToPayAfterCashMachineValidation !==
                                                        0
                                                        ? leftToPayAfterCashMachineValidation
                                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice
                                                ),
                                            },
                                        ])
                                    );
                                    setPaymentModes([
                                        ...paymentModes,
                                        {
                                            paymentModeUuiD:
                                                selectedRegulationModeValue ===
                                                "changeMachine"
                                                    ? cashUuID
                                                    : creditCardUuId,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount: Number(
                                                leftToPayAfterCashMachineValidation !==
                                                    0
                                                    ? leftToPayAfterCashMachineValidation
                                                    : isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : orderTotalPrice
                                            ),
                                            data: {
                                                renderAmount: 0,
                                            },
                                            totalAmountDeposited: Number(
                                                leftToPayAfterCashMachineValidation !==
                                                    0
                                                    ? leftToPayAfterCashMachineValidation
                                                    : isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : orderTotalPrice
                                            ),
                                        },
                                    ]);
                                }
                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            await getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content,
                                                Number(orderTotalPrice)
                                            )
                                        )
                                );
                                ctrl.abort();
                                break;
                            }
                            //code returned after payment with the change machine with an amount returned
                            case CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT: {
                                console.log(
                                    `Payment with change machine (code 1003) with an amount returned:${Number(
                                        JSON.parse(event.data).response[3]
                                    )} and closing the change machine render modal`
                                );
                                setRenderAmount(
                                    Number(JSON.parse(event.data).response[3])
                                );
                                setIsChangeMachineRenderModalOpened(false);
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                                const selectedPaymentMode = cloneDeep(
                                    paymentModes.find(
                                        (mode) =>
                                            mode.paymentMode ===
                                            selectedRegulationModeValue
                                    )
                                );

                                if (selectedPaymentMode !== undefined) {
                                    const selectedPaymentModeIndex =
                                        paymentModes.findIndex(
                                            (mode) =>
                                                mode.paymentMode ===
                                                selectedRegulationModeValue
                                        );

                                    const localPaymentModes = cloneDeep(
                                        paymentModes.map((paymentMode) => {
                                            return paymentMode;
                                        })
                                    );
                                    localPaymentModes[
                                        selectedPaymentModeIndex
                                    ] = {
                                        paymentModeUuiD: cashUuID,
                                        paymentMode:
                                            selectedRegulationModeValue,
                                        paymentAmount:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            leftToPayAfterCashMachineValidation,
                                        data: {
                                            renderAmount: Number(
                                                JSON.parse(event.data)
                                                    .response[3]
                                            ),
                                        },
                                        totalAmountDeposited:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            Number(
                                                leftToPayAfterCashMachineValidation
                                            ) +
                                            Number(
                                                JSON.parse(event.data)
                                                    .response[3]
                                            ),
                                    };
                                    console.log(
                                        "JSON.stringify(localPaymentModes) in PaymentStep code 1003 IF",
                                        JSON.stringify(localPaymentModes)
                                    );
                                    setPaymentModes(localPaymentModes);
                                } else {
                                    console.log(
                                        "JSON.stringify(PaymentModes) in PaymentStep code 1003 else",
                                        JSON.stringify([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD: cashUuID,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount:
                                                    isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice,
                                                data: {
                                                    renderAmount: Number(
                                                        JSON.parse(event.data)
                                                            .response[3]
                                                    ),
                                                },
                                                totalAmountDeposited:
                                                    Number(
                                                        isOrderPartialPaidWithLoyaltyAccountBalance
                                                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                            : orderTotalPrice
                                                    ) +
                                                    Number(
                                                        JSON.parse(event.data)
                                                            .response[3]
                                                    ),
                                            },
                                        ])
                                    );
                                    setPaymentModes([
                                        ...paymentModes,
                                        {
                                            paymentModeUuiD: cashUuID,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount: Number(
                                                isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : orderTotalPrice
                                            ),
                                            data: {
                                                renderAmount: Number(
                                                    JSON.parse(event.data)
                                                        .response[3]
                                                ),
                                            },
                                            totalAmountDeposited:
                                                Number(
                                                    isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice
                                                ) +
                                                Number(
                                                    JSON.parse(event.data)
                                                        .response[3]
                                                ),
                                        },
                                    ]);
                                }

                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            await getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content,
                                                Number(orderTotalPrice)
                                            )
                                        )
                                );
                                ctrl.abort();
                                break;
                            }
                            //code returned after payment with the change machine in the case of a refund anomaly
                            case CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY: {
                                const localVoucherAmount = Number(
                                    Number(JSON.parse(event.data).response) -
                                        (leftToPayAfterCashMachineValidation !==
                                        0
                                            ? leftToPayAfterCashMachineValidation
                                            : isOrderPartialPaidWithLoyaltyAccountBalance
                                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                            : orderTotalPrice)
                                );
                                console.log(
                                    `Payment with the change machine in the case of a refund anomaly (code 999) with a voucher amount:${parseFloat(
                                        localVoucherAmount.toFixed(2)
                                    )} and opening the refund anomaly modal`
                                );
                                setVoucherAmount(
                                    parseFloat(localVoucherAmount.toFixed(2))
                                );
                                setAmountDeposited(
                                    Number(JSON.parse(event.data).response)
                                );
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                                setIsRefundAnomalyModalOpened(true);
                                ctrl.abort();
                                break;
                            }
                            //code returned after payment is refused
                            case PAYMENT_REFUSED: {
                                // if (
                                //     //ETK-webserver stopped
                                //     JSON.parse(event.data).response ===
                                //         "ERREUR PAIEMENT" ||
                                //     //Network problem
                                //     JSON.parse(event.data).response ===
                                //         "Abort" ||
                                //     JSON.parse(event.data).response ===
                                //         "Erreur d'initialiser la connexion avec le lecteur Nepting"
                                // ) {
                                //     console.log(
                                //         "A technical problem was detected"
                                //     );
                                //     setIsPaymentTechnicalProblemDetected(true);
                                //     setIsPaymentTechnicalProblemModalOpened(
                                //         true
                                //     );
                                //     setIsAnotherPaymentModeButtonDisabled(
                                //         false
                                //     );
                                //     setIsBackButtonDisabled(false);
                                // }

                                console.log("The payment is refused");
                                setIsPaymentRefused(true);
                                ctrl.abort();
                                break;
                            }
                        }
                        if (code === VALIDE_PAYMENT_WITH_CHANGEMACHINE) {
                            setIsCashTransactionSuccessfullyValidated(true);
                        } else {
                            setIsCashTransactionSuccessfullyValidated(false);
                        }
                        break;
                    }
                    case "Bloc": {
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(true);
                        console.log(
                            "I receive the event bloc it means that the change machine is on counting and the buttons wil be disabled"
                        );
                        break;
                    }
                    case "Menu": {
                        setIsAnotherPaymentModeButtonDisabled(false);
                        setIsBackButtonDisabled(false);
                        break;
                    }
                    case "usermessage": {
                        if (selectedRegulationModeValue === "changeMachine") {
                            setIsAnotherPaymentModeButtonDisabled(false);
                            setIsBackButtonDisabled(false);

                            if (
                                JSON.parse(event.data).message.includes(
                                    "Montant inseré"
                                )
                            ) {
                                const localMessage = JSON.parse(
                                    event.data
                                ).message.split(" ");

                                setAmountDeposited(Number(localMessage[2]));

                                if (Number(localMessage[9]) <= 0) {
                                    setLeftToPay(0);
                                } else {
                                    setLeftToPay(Number(localMessage[9]));
                                }

                                if (
                                    Number(localMessage[2]) <
                                    (leftToPayAfterCashMachineValidation !== 0
                                        ? leftToPayAfterCashMachineValidation
                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                        : orderTotalPrice)
                                ) {
                                    setIsBackButtonDisabled(false);
                                } else {
                                    setIsBackButtonDisabled(true);
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                }
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "connexion établie !  Montant inseré"
                                )
                            ) {
                                const localMessage = JSON.parse(
                                    event.data
                                ).message.split(" ");

                                setAmountDeposited(Number(localMessage[7]));
                                if (Number(localMessage[14]) <= 0) {
                                    setLeftToPay(0);
                                } else {
                                    setLeftToPay(Number(localMessage[14]));
                                }

                                if (
                                    Number(localMessage[7]) <
                                    (leftToPayAfterCashMachineValidation !== 0
                                        ? leftToPayAfterCashMachineValidation
                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                        : orderTotalPrice)
                                ) {
                                    setIsBackButtonDisabled(false);
                                } else {
                                    setIsBackButtonDisabled(true);
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                }
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "Transaction en cours d'annulation"
                                )
                            ) {
                                localTotalAmountAlreadyDeposited =
                                    totalAmountDeposited;
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "Préparation du rendu"
                                )
                            ) {
                                const localMessage = JSON.parse(
                                    event.data
                                ).message.split(" ");
                                setIsBackButtonDisabled(true);
                                setRenderAmount(
                                    localTotalAmountAlreadyDeposited +
                                        Number(localMessage[4])
                                );
                                setIsChangeMachineRenderModalOpened(true);
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "Vérification et calcul"
                                ) ||
                                JSON.parse(event.data).message.includes(
                                    "Montant total inseré"
                                )
                            ) {
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                            } else {
                                if (amountDeposited === 0) {
                                    setIsBackButtonDisabled(false);
                                } else {
                                    setIsBackButtonDisabled(true);
                                }
                            }
                        } else {
                            if (moneticConfig.type === "Valina") {
                                if (
                                    JSON.parse(event.data).message.includes(
                                        "IDLE"
                                    )
                                ) {
                                    setIsAnotherPaymentModeButtonDisabled(
                                        false
                                    );
                                    setIsBackButtonDisabled(false);
                                } else if (
                                    JSON.parse(event.data).message.includes(
                                        "REMOVE_CARD"
                                    )
                                ) {
                                    setIsAcceptAbortCBTransactionButtonDisabled(
                                        true
                                    );
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                    setIsBackButtonDisabled(true);
                                }
                            } else {
                                if (
                                    JSON.parse(event.data).message.includes(
                                        "INSEREZ CARTE"
                                    )
                                ) {
                                    setIsAnotherPaymentModeButtonDisabled(
                                        false
                                    );
                                    setIsBackButtonDisabled(false);
                                } else if (
                                    JSON.parse(event.data).message.includes(
                                        "PAIEMENT ACCEPTE"
                                    )
                                ) {
                                    console.log("The payment is accepted");
                                    setIsAcceptAbortCBTransactionButtonDisabled(
                                        true
                                    );
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                    setIsBackButtonDisabled(true);
                                }
                            }
                        }
                    }
                }
            },
            onclose() {
                console.log("connection close in payment API");
                ctrl.abort();
            },
            onerror(error) {
                console.log(
                    `Error during the payment, with the message: ${error.message}`
                );
                setIsPaymentTechnicalProblemDetected(true);
                setIsAnotherPaymentModeButtonDisabled(false);
                setIsBackButtonDisabled(false);
                ctrl.abort();
                throw error;
            },
        });
    }

    function handleGettingCashMachineStatus(): void {
        console.log("EtatMachine api launched");
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => {
            controller.abort();
            setIsCashMachineNotOccupied(false);
            setIsCashMachineOccupiedModalOpened(true);
        }, 2000);
        const timestamp = new Date().getTime();

        fetch(`${middlewareApiUrl}/EtatMachine?timestamp=${timestamp}`, {
            signal,
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(
                    `Response of cash machine status ${JSON.stringify(
                        response
                    )}`
                );

                switch (response.code) {
                    case 2:
                        setIsCashMachineNotOccupied(true);
                        setIsCashMachineOccupiedModalOpened(false);
                        break;
                    default:
                        setIsCashMachineNotOccupied(false);
                        setIsCashMachineOccupiedModalOpened(true);
                        break;
                }
            })
            .catch((error) => {
                setIsCashMachineNotOccupied(false);
                setIsCashMachineOccupiedModalOpened(true);
                console.log(
                    `Error on getting the cash machine status, with the message: ${error.message}`
                );
            })
            .finally(() => {
                clearTimeout(timeoutId);
            });
    }

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            console.log({ amountDeposited });
            if (
                selectedRegulationModeValue === "changeMachine" &&
                amountDeposited === 0 &&
                isCashMachineNotOccupied
            ) {
                let timer: NodeJS.Timeout;
                console.log({ paymentStepCounter });

                if (paymentStepCounter >= 0) {
                    timer = setTimeout(
                        () => setPaymentStepCounter(paymentStepCounter + 1),
                        1000
                    );
                }

                if (
                    paymentStepCounter ===
                    ((ways.payment as PaymentPageType).regulationMode
                        .changeMachine.timeout as number)
                ) {
                    console.log("ChangeMachine timeout standbyMOdalOpened");
                    setIsAbortTransactionModalOpened(false);
                }

                return () => clearTimeout(timer);
            }
        }
    }, [
        paymentStepCounter,
        amountDeposited,
        selectedRegulationModeValue,
        regulationModesModalKey,
        isCashMachineNotOccupied,
    ]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            if (isWithdrawalAmountApiLaunched === true) {
                const ctrl = new AbortController();

                console.log(
                    "The change machine status api is started on withdrawal the deposited amount",
                    { withdrawalAmount }
                );

                fetchEventSource(`${middlewareApiUrl}/StatusCashMachine`, {
                    signal: ctrl.signal,
                    onmessage(event) {
                        console.log(
                            `The change machine status api event:${JSON.stringify(
                                event
                            )}`
                        );

                        switch (event.event) {
                            case "InventoryResponse": {
                                console.log(
                                    "We receive InventoryResponse event witch mean that the change machine is ready and remove the control from change machine status api"
                                );

                                setIsInventoryResponse(true);
                                ctrl.abort();
                                break;
                            }
                            case "EventCash":
                            case "eventError":
                            case "Error":
                            case "IncompleteTransaction":
                            case "PowerControlResponse":
                            case "SpecialDeviceError": {
                                console.log(JSON.stringify(event.data));
                                break;
                            }
                        }
                    },
                    onclose() {
                        console.log(
                            "Connection close in StatusCashMachine api when it started on withdrawal of the deposited amount"
                        );
                        ctrl.abort();
                    },
                    onerror(error) {
                        console.log(
                            `Error while getting the change machine status, with the message: ${error.message}`
                        );
                        ctrl.abort();
                    },
                });
            }
        }
    }, [isWithdrawalAmountApiLaunched]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            if (isInventoryResponse === true) {
                console.log("withdrawalAmount on InventoryResponse", {
                    withdrawalAmount,
                });
                if (withdrawalAmount > 0) {
                    handleNavigationOnWithdrawalAmount();
                } else {
                    console.log(
                        `Since the change machine cannot refund the deposited amount, a voucher will be created with the deposited amount of ${totalAmountDeposited}`
                    );
                    setVoucherAmount(totalAmountDeposited);
                    setIsWithdrawalProblemModalOpened(true);
                    setIsRegulationModesModalOpened(false);
                }

                setIsChangeMachineRenderModalOpened(false);
                setAmountDeposited(0);
                setLeftToPayAfterCashMachineValidation(0);
                setIsPaymentRefused(false);
                setIsTransactionCanceled(false);
                // setPaymentModes(
                //     isClickAndCollect ? clickAndCollectPaymentMode : []
                // );
            }
        }
    }, [isInventoryResponse]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            console.log(
                JSON.stringify({
                    isChangeMachineReady: isChangeMachineReady,
                    isPaymentRefused: isPaymentRefused,
                    isTransactionCanceled: isTransactionCanceled,
                    totalAmountDeposited: totalAmountDeposited,
                    isCashMachineTransactionCanceled:
                        isCashMachineTransactionCanceled,
                })
            );

            if (isChangeMachineReady) {
                if (isPaymentRefused) {
                    if (isTransactionCanceled) {
                        handleNavigationAfterTransactionCancelling();
                    } else {
                        console.log(
                            "After the payment was refused, another payment options modal opened.",
                            JSON.stringify({
                                selectedRegulationModeValue,
                                isAnotherPaymentModeButtonClickedOnCBPaymentMode,
                            })
                        );
                        if (selectedRegulationModeValue === "creditCard") {
                            if (
                                isAnotherPaymentModeButtonClickedOnCBPaymentMode
                            ) {
                                setIsRegulationModesModalOpened(true);
                            } else {
                                setIsTpeNotAvailable(true);
                            }
                        } else {
                            setIsRegulationModesModalOpened(true);
                        }
                    }
                }
            }
        }
    }, [
        isPaymentRefused,
        isTransactionCanceled,
        isEventStreamProblem,
        isChangeMachineReady,
        isAnotherPaymentModeButtonClickedOnCBPaymentMode,
    ]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            if (
                selectedRegulationModeValue === "changeMachine" &&
                isEventStreamProblem
            ) {
                console.log(
                    "Setting is change machine ready to true if there is a event stream problem ,so we don't have inventory response"
                );
                setIsChangeMachineReady(true);
            }
        }
    }, [
        isEventStreamProblem,
        isChangeMachineReady,
        selectedRegulationModeValue,
    ]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            console.log(
                "Another payment modes modal opening",
                JSON.stringify({
                    isChangeMachineReady,
                    isCashTransactionSuccessfullyValidated,
                    isTransactionCanceled,
                })
            );
            if (
                isChangeMachineReady &&
                isCashTransactionSuccessfullyValidated
            ) {
                if (isTransactionCanceled === false) {
                    console.log(
                        "Another payment modes modal opened after cash machine validation!!!!"
                    );
                    setIsRegulationModesModalOpened(true);
                } else {
                    setLeftToPay(orderTotalPrice);
                }
            }
        }
    }, [
        isChangeMachineReady,
        isCashTransactionSuccessfullyValidated,
        isPaymentRefused,
    ]);

    React.useEffect(() => {
        if (
            !["atTheCounter", "BDP"].includes(selectedRegulationModeValue) &&
            selectedRegulationModeValue !== undefined &&
            selectedRegulationModeValue !== ""
        ) {
            setIsLogoActive(
                (ways.payment as PaymentPageType).topBanner.logo.active
            );

            setIsTopBannerActive(
                (ways.payment as PaymentPageType).topBanner.active
            );
            setIsHeaderActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.active
            );

            setIsInformationMessageActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.informationMessage.active
            );

            setInformationMessageItems(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.informationMessage.languages
            );

            setIsActionButtonsActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (ways.payment as PaymentPageType).regulationMode[
                        selectedRegulationModeValue
                    ].actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setMainContentItems(
                (content[ways.payment?.name] as PaymentContentItemType).items
            );

            setIsTpaValinaIconActive(
                // eslint-disable-next-line
                (
                    (content.TPAValina as TPAValinaPageType)
                        .header as NewHeaderType
                ).icon!.active
            );
            setTpaValinaIconId(
                // eslint-disable-next-line
                (
                    (content.TPAValina as TPAValinaPageType)
                        .header as NewHeaderType
                ).icon!.id
            );
            setIsTpaValinaInformationMessageActive(
                (content.TPAValina as TPAValinaPageType).header
                    .informationMessage.active
            );
            setTpaValinaInformationMessageItems(
                (content.TPAValina as TPAValinaPageType).header
                    .informationMessage.languages
            );
            setIsTpaValinaActionButtonsActive(
                (content.TPAValina as TPAValinaPageType).actionsButtons.active
            );
            setTpaValinaActionButtons(
                (content.TPAValina as TPAValinaPageType).actionsButtons.items
            );
            setChosenTransition(
                transitions[(ways.payment as PaymentPageType).animation]
            );
        }
        setRegulationModesNumber(
            (content.meansOfPayment as ProjectContentItemType).items.filter(
                (item) => {
                    return item.active === true;
                }
            ).length
        );
    }, [regulationModesModalKey]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            if (selectedRegulationModeValue === "changeMachine") {
                console.log("handleGettingCashMachineStatus func useEffect");
                handleGettingCashMachineStatus();
            }
        }
    }, [regulationModesModalKey, selectedRegulationModeValue]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            if (isRegulationModesModalOpened === false) {
                if (isvalinaOpen) {
                    setIsTpaValinaModalOpened(true);
                } else {
                    if (selectedRegulationModeValue === "changeMachine") {
                        console.log(
                            "Case of changeMachine, inside the useEffect which call handleCommandPayment func",
                            { isCashMachineNotOccupied }
                        );
                        if (isCashMachineNotOccupied) {
                            console.log(
                                JSON.stringify({
                                    handleChangeMachineStatusApiCondition:
                                        isChangeMachineReady === false &&
                                        isCashMachineStatusStarted === false,
                                    isChangeMachineReady,
                                    isCashMachineStatusStarted,
                                })
                            );
                            if (
                                isChangeMachineReady === false &&
                                isCashMachineStatusStarted === false
                            ) {
                                console.log(
                                    "Change machine status function launched"
                                );
                                handleChangeMachineStatus();
                            }
                            console.log(
                                "verify if the cash machine status api started or no ?",
                                { isCashMachineStatusStarted }
                            );
                            if (isCashMachineStatusStarted) {
                                console.log(
                                    "payment with cash machine launched!!"
                                );

                                console.log(
                                    "is Cash Machine Status Started true"
                                );
                                handleCommandPayment();
                            }
                        }
                    } else {
                        console.log("call handleCommandPayment case of CB");
                        handleCommandPayment();
                    }
                }
            }
        } else if (TCPOS === posEditor) {
            validateTCPOSCommand();
        }
    }, [
        selectedRegulationModeValue,
        regulationModesModalKey,
        isBackWithoutCreditCardPaymentChoice,
        isCashMachineNotOccupied,
        isCashMachineStatusStarted,
    ]);
    return (
        <PreviewBackgroundWrapper>
            <PaymentContainerWrapper
                isTopBannerActive={isTopBannerActive}
                isHeaderActive={isHeaderActive}
                isActionButtonsActive={isActionButtonsActive}
                ref={(ref) => setContainerRef(ref)}
            >
                {containerRef ? (
                    isAbortTransactionModalOpened === true ? (
                        <AbortTransactionModalWrapper
                            containerRef={containerRef}
                        />
                    ) : null
                ) : null}

                {containerRef ? (
                    isChangeMachineRenderModalOpened === true ? (
                        <ChangeMachineRenderModalWrapper
                            containerRef={containerRef}
                        />
                    ) : null
                ) : null}

                {containerRef ? (
                    isRefundAnomalyModalOpened === true ? (
                        <RefundAnomalyModalWrapper
                            containerRef={containerRef}
                        />
                    ) : null
                ) : null}

                {containerRef ? (
                    isRegulationModesModalOpened === true ? (
                        <RegulationModesModalWrapper
                            containerRef={containerRef}
                        />
                    ) : null
                ) : null}

                {isTpaValinaModalOpened ? (
                    <TpaValinaModalWrapper
                        isTpaValinaModalOpened={isTpaValinaModalOpened}
                        setIsTpaValinaModalOpened={setIsTpaValinaModalOpened}
                    />
                ) : null}

                {isWithdrawalProblemModalOpened === true ? (
                    <WithdrawalProblemModalWrapper />
                ) : null}

                {isCashMachineOccupiedModalOpened === true ? (
                    <CashMachineOccupiedModal />
                ) : null}

                {isTpeNotAvailable === true ? <TpeAvailablityModal /> : null}

                <LogoWrapper />

                {isHeaderActive ? (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        {isInformationMessageActive ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span
                                    className=" two-lines-preview-text w-100"
                                    style={{ fontSize: "55px" }}
                                >
                                    {
                                        informationMessageItems[
                                            customerLanguage.name
                                        ].content
                                    }
                                </span>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <PaymentMainContentWrapper />

                {moneticConfig.type === "Nepting" &&
                moneticConfig.mode === ISNEPTING_TYPE_46_OR_45 ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "10%",
                        }}
                    >
                        <h1
                            style={{
                                textAlign: "center",
                                maxWidth: "90%",
                                marginInline: "auto",
                            }}
                        >
                            {t(
                                "Please proceed from your terminal to cancel your transaction."
                            )}
                        </h1>
                    </div>
                ) : isActionButtonsActive && !(TCPOS === posEditor) ? (
                    <ActionsButtonsWrapper
                        actionsButtonsItems={mainActiveActionsButtons}
                    />
                ) : null}
            </PaymentContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}

import useSWR from "swr";
import { useSnapshot } from "valtio";

import { setPosEditor, store } from "@store";

import { syncStore } from "@components/Synchronization/store";

const fetcher = (url: string) =>
    fetch(url)
        .then((response) => response.json())
        .catch((error) => {
            console.log(
                `Error while fetching data, with message: ${error.message}`
            );
            return error;
        });

export function useProject(url?: string): {
    project: ProjectType;
} {
    const { middlewareApiUrl } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    // get the data from the local `project` first
    // which is saved while the `synchronization`

    // catalog project visualization
    const franchise_uid = localStorage.getItem("viewADB-franchise_uid");
    const shop_uid = localStorage.getItem("viewADB-shop_uid");
    if (franchise_uid !== null && shop_uid !== null) {
        const urlCatalogue = `${process.env.REACT_APP_SETTINGS_APP_API}/settings/Kiosk_Catalogue?franchiseId=${franchise_uid}&shopId=${shop_uid}`;

        const { data } = useSWR([urlCatalogue], fetcher, {
            suspense: true,
            revalidateOnFocus: false,
        });

        if (data?.length > 0) {
            const project = data[0];
            return { project };
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectShop: ProjectType = localStorage.getItem("project") as any;

    if (projectShop !== null) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { project: JSON.parse(projectShop as any) };
    }

    const shopDetails = JSON.parse(
        localStorage.getItem("shopDetail") as string
    );

    if (shopDetails !== null) {
        const settingsUrl = `${middlewareApiUrl}/settings/consomation?franchise_uuid=${shopDetails?.franchise_uid}&shop_uuid=${shopDetails?.shop_uid}`;

        const {
            data: { kiosk },
        } = useSWR([settingsUrl], fetcher, {
            suspense: true,
            revalidateOnFocus: false,
        });
        if (kiosk.length > 0) {
            setPosEditor(kiosk[0].posEditor);
        }
        const shopApp = kiosk.filter((kiosk: Record<string, string>) => {
            return kiosk.name === selectedShopApp.name;
        });

        if (Array.isArray(shopApp) && shopApp.length > 0) {
            (window as RendererWindow).electronAPI?.getProjectEvent({
                data: JSON.stringify(shopApp[0].project, null, 4),
            });

            return { project: shopApp[0].project };
        }
    } else {
        const { data: project } = useSWR(["./project.json"], fetcher, {
            suspense: true,
            revalidateOnFocus: false,
        });

        return { project };
    }

    // @todo handle error case
    const { data: project } = useSWR([url as string], fetcher, {
        suspense: true,
        revalidateOnFocus: false,
    });

    return { project };
}

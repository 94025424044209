import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { useSalesModeModification } from "@hooks/useSalesModeModification";

import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import { CategoriesSwiperWrapper } from "@components/OrderTaking/Components/Categories/CategoriesSwiperWrapper";
import OrderTakingTopBanner from "@components/OrderTaking/Components/OrderTakingTopBanner";
import PrmBasket from "@components/OrderTaking/Components/Prm/PrmBasket";
import ProductWrapper from "@components/OrderTaking/Components/Products/ProductWrapper";
import { TagsSwiperWrapper } from "@components/OrderTaking/Components/Tags/TagsSwiperWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import CategoriesBreadCrumb from "./CategoriesBreadCrumb";
import "./prm-mode.css";

import "swiper/swiper.min.css";

export default function PrmOrderTakingScreen({
    isSelectedCategoryActive = false,
    imageSrc,
    title,
    nestedCategories,
}) {
    useSalesModeModification();

    const { user, customerLanguage, isPrm } = useSnapshot(kioskStore);
    const {
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        isOrderTakingUserAccountActive,
        userAccountMessage,
        isBackgroundActive,
    } = useSelector((state) => state.settingSlice.generalSetting, shallowEqual);

    const { isUserAuthenticated } = useSnapshot(kioskStore);

    return (
        <section>
            <OrderTakingBackgroundWrapper
                filename={imageSrc}
                isBackgroundActive={isBackgroundActive}
                isPrm={isPrm}
            >
                <div className="order-taking-prm-container order-taking-prm-layout">
                    <header className="order-taking-prm-header">
                        <div className="d-flex flex-column h-100">
                            <PrmBackgroundWrapper />
                            {isOrderTakingTopBannerActive ? (
                                <OrderTakingTopBanner />
                            ) : null}
                            <div className="d-flex flex-column py-1">
                                {isOrderTakingUserAccountActive &&
                                isUserAuthenticated ? (
                                    <div className="d-flex flex-column ">
                                        <div
                                            className="prm-authenticated-user-font"
                                            style={{
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            {
                                                userAccountMessage.languages[
                                                    customerLanguage.name
                                                ].content
                                            }{" "}
                                            {"   "}
                                            <span className="font-weight-bold">
                                                {user.firstName === ""
                                                    ? user.lastName
                                                    : user.firstName}
                                            </span>{" "}
                                        </div>
                                        <hr className="separation-line mx-1" />
                                    </div>
                                ) : null}

                                {nestedCategories.length > 1 && (
                                    <div className="d-flex flex-column h-100">
                                        <div>
                                            <CategoriesBreadCrumb />
                                        </div>
                                    </div>
                                )}
                                <hr className="separation-line mx-1" />
                            </div>
                        </div>
                    </header>

                    <nav id="prm-categories" className="border-right">
                        <CategoriesSwiperWrapper />
                    </nav>

                    <section id="prm-products">
                        {(nestedCategories.length > 1 ||
                            isSelectedCategoryActive) && (
                            <>
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                        font: " normal normal 600 30px/38px Segoe UI",
                                    }}
                                >
                                    {title}
                                </div>
                            </>
                        )}
                        <hr className="separation-line mb-2" />

                        {isOrderTakingTagsActive ? <TagsSwiperWrapper /> : null}

                        <ProductWrapper />
                    </section>

                    <footer
                        id="prm-footer"
                        style={{
                            backgroundColor: "transparent",
                            maxHeight: "90px",
                        }}
                    >
                        <PrmBasket />
                    </footer>
                </div>
            </OrderTakingBackgroundWrapper>
        </section>
    );
}

PrmOrderTakingScreen.propTypes = {
    isSelectedCategoryActive: PropTypes.boolean,
    imageSrc: PropTypes.string,
    title: PropTypes.string,
    nestedCategories: PropTypes.object,
};
